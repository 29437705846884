function rapportController(AppSettings, $parse, $rootScope, $scope, WS, $window, $compile, NgMap, $sce, $timeout, $anchorScroll, $translate) {
  'ngInject';
  const vm = this;

  vm.decimal = AppSettings.decimal || 3;
  vm.stat_assenceur = {};
  vm.infouser = {};
  vm.filter = {};
  vm.filterdate = {};

  vm.search47 = {};
  vm.search47.user_code = '';
  vm.search47.rows = '';
  vm.search47.date_debut = '';
  vm.search47.date_fin = '';


  vm.search44 = {};
  vm.search44.gamme_code = '';
  vm.search44.famille_code = '';
  vm.search44.sousfamille_code = '';
  vm.search44.marque_code = '';
  vm.search44.produit_code = '';
  vm.search44.user_code = '';
  vm.search44.date_debut = '';
  vm.search44.date_fin = '';
  vm.search44.client_code = '';


  vm.search54 = {};
  vm.search54.categoriecommercial_code = '';
  vm.search54.activite_code = '';
  vm.search54.specialite = '';
  vm.search54.gouvernorat_code = '';
  vm.search54.delegation_code = '';
  vm.search54.region_code = '';
  vm.search54.zone_code = '';
  vm.search54.routing_code = '';
  vm.search54.user_code = '';
  vm.search54.date_debut = '';
  vm.search54.date_fin = '';


  vm.search55f1 = {};
  vm.search55f1.type = 'bl';
  vm.search55f1.categoriecommercial_code = '';
  vm.search55f1.activite_code = '';
  vm.search55f1.user_code = '';
  vm.search55f1.date_debut = '';
  vm.search55f1.date_fin = '';
  vm.search55f1.marque_codes = '';

  vm.search55f2 = {};
  vm.search55f2.type = 'bl';
  vm.search55f2.categoriecommercial_code = '';
  vm.search55f2.activite_code = '';
  vm.search55f2.user_code = '';
  vm.search55f2.date_debut = '';
  vm.search55f2.date_fin = '';
  vm.search55f2.marque_codes = '';

  vm.search56 = {}
  vm.search56.soussociete_codes = [];
  vm.search56.date_debut = '';
  vm.search56.date_fin = '';

  vm.search57 = {}
  vm.search57.type = '';
  vm.search57.user_code = '';
  vm.search57.date_debut = '';
  vm.search57.date_fin = '';


  vm.filter.user_code = '';
  vm.filter.day = '';
  vm.currentDate = moment().format("DD/MM/YYYY");
  vm.vente_by_month = {};
  vm.types_vente = ['carton', 'quantite', 'chiffre', 'poids']
  vm.vente_by_month.year = moment().format("YYYY");
  vm.getVenteEncoursByCommercial;
  vm.per_page = "10";
  vm.sf_promo_gratuite = {};
  vm.loading_request = false;
  vm.sf_promo_gratuite.date_debut = moment().format("YYYY-MM") + '-01';
  vm.sf_promo_gratuite.date_fin = moment().format("YYYY-MM-DD");

  vm.icons_charts = ['fa fa-area-chart', "fa fa-pie-chart", "fa fa-bar-chart", "fa fa-line-chart"];
  vm.tournee_activites = [];
  vm.total_tournees = [];
  vm.colors = [
    "#696969", "#bada55", "#ff0000", "#ff80ed", "#407294", "#cbcba9", "#5ac18e", "#065535", "#f7347a", "#794044",
    "#008080", "#ffd700", "#ff7373", "#ffa500", "#40e0d0", "#0000ff", "#003366", "#800000", "#800080", "#8a2be2",
    "#00ff00", "#20b2aa", "#66cdaa", "#4ca3dd", "#ff6666", "#ff7f50", "#468499", "#ff00ff", "#008000", "#088da5",
    "#daa520", "#6897bb", "#000080", "#1ABC9C", "#696969", "#bada55", "#ff0000", "#ff80ed", "#407294", "#cbcba9",
    "#008080", "#ffd700", "#ff7373", "#ffa500", "#40e0d0", "#0000ff", "#003366", "#800000", "#800080", "#8a2be2",
    "#00ff00", "#20b2aa", "#66cdaa", "#4ca3dd", "#ff6666", "#ff7f50", "#468499", "#ff00ff", "#008000", "#088da5",
    "#daa520", "#6897bb", "#000080", "#1ABC9C", "#5ac18e", "#065535", "#f7347a", "#794044", "#1ABC9C", "#d5beea",
    "#cd0065", "#87105f", "#cb1890", "#fba494", "#f9a799", "#2d7a8a", "#ffc0cb", "#dd93d6", "#7429ba", "#cbd7fb",
    "#7c5649", "#575f2d", "#6c4b40", "#8e7543", "#4c352d", "#677b8a", "#776b5e", "#233f5c", "#23282a", "#c74b1e",
    "#513032", "#464f54", "#73738e", "#ffc0cb", "#e9eebb", "#eebbe9", "#cbd7fb", "#fba494", "#dd93d6", "#eebbe9",
    "#a7cad0", "#787883", "#235c5c", "#fc7d84",
  ];
  vm.month_names = ["",
    $translate.instant("Janvier"), $translate.instant("Février"), $translate.instant("Mars"),
    $translate.instant("Avril"), $translate.instant("Mai"), $translate.instant("Juin"),
    $translate.instant("Juillet"), $translate.instant("Août"), $translate.instant("Septembre"),
    $translate.instant("Octobre"), $translate.instant("Novembre"), $translate.instant("Décembre"),
  ];

  // jQuery(function ($) {
  //     var id = 'top10';
  //     var btn_id = 'li-'+id
  //     //accordion
  //     //tab
  //         $('#'+btn_id).click();
  //         $('.active').removeClass('active');
  //         $('#'+id).addClass('active');
  //         $('#'+btn_id).addClass('active');
  //         $("html, body").animate({ scrollTop: $('#'+id).offset().top }, 1000);
  // });

  vm.filter50 = {};

  WS.getScache('pays').then(function (response) {
    vm.getpays = response.data;
    vm.center = vm.getpays.latitude + ', ' + vm.getpays.longitude;
    $scope.$apply();
  }, function (error) {
    console.log(error);
  });

  vm.keymaps = AppSettings.keymaps;
  vm.jours = [];
  for (var i = 1; i < 32; i++) {
    var i = (i < 10) ? '0' + i : i;
    vm.jours.push(i);
  }

  vm.mapForRapporttournee = function () {
    $timeout(function () {
      NgMap.getMap({ id: 'mapforrapporttournee' }).then(function (response) {
        google.maps.event.trigger(response, 'resize');
        response.setCenter({ lat: Number(vm.getpays.latitude), lng: Number(vm.getpays.longitude) });
      });
    }, 7000);

  };

  vm.getCommandes = function () {
    WS.get('bl/byGouvernorat/commande')
      .then(function (response) {
        if (response.status == 200) {
          vm.bl_byGouvernorat = response.data;
          $scope.$apply();
        } else { console.log('response status is not 200 line 98 dashboard.js controller'); }
      })
      .then(null, function (error) {
        console.log(error);
      });

  }
  vm.getGouvernorats = function () {
    WS.get('gouvernorats')
      .then(function (response) {
        if (response.status == 200) {
          vm.gouvernorats = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getChiffreParRegionParMois = function (code, liste, region) {

    if (!liste) return 0;

    for (var i = 0; i < liste.length; i++) {
      if (liste[i]['month'] == code && liste[i]['region'] == region) {
        return liste[i];
      }

    }

  }

  vm.getTotalVente = function (list, code) {
    if (!list) return 0;
    var total = 0;
    for (var i = 0; i < list.length; i++) {
      if (list[i]['user_code'] == code) {
        total = total + Number(list[i]['net_a_payer']);
      }
    }
    return total;
  }
  vm.getTotalCredit = function (list) {
    if (!list) return 0;
    var total = 0;
    for (var i = 0; i < list.length; i++) {
      total = total + Number(list[i]['encours']);
    }
    return total;
  }

  vm.getTotalReglement = function (list, type) {
    if (!list) return 0;
    var total = 0;
    for (var i = 0; i < list.length; i++) {
      if (list[i][type]) {
        var value = list[i][type].replace(",", ".")
        total = total + Number(value);
      } else {
        console.log('not number')
      }
    }
    return total;
  }



  vm.getNombreVisiteParClient = function (code, liste) {


    if (!liste) return;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i]['mois'] == code) {
        return liste[i];
      }

    }
  }

  vm.getAnalyseByDelegations = function (type, gouvernorat, date_debut, date_fin) {
    WS.get('entetecommercial/byDelegation/' + type + '/' + gouvernorat + '/' + date_debut + '/' + date_fin)
      .then(function (response) {
        if (response.status == 200) {
          vm.byDelegation = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getAnalyseByGouvernorats = function (type, date_debut, date_fin) {
    WS.get('entetecommercial/analyseByGouvernorat/' + type + '/' + date_debut + '/' + date_fin)
      .then(function (response) {
        if (response.status == 200) {
          vm.bl_byGouvernorat = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };



  vm.getAnalyseByZone = function (type, date_debut, date_fin) {
    WS.get('entetecommercial/analyseByZone/' + type + '/' + date_debut + '/' + date_fin)
      .then(function (response) {
        if (response.status == 200) {
          vm.bl_chiffreByZone = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getByRegionByMonth = function () {
    WS.get('bl/byRegionByMonth/bl')
      .then(function (response) {
        if (response.status == 200) {
          vm.regionbymonth = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getBynbrcientDnParRegion = function () {
    WS.get('rapports/rapportnbrclientdnparregion')
      .then(function (response) {
        if (response.status == 200) {
          vm.rapportnbrclientdnparregion = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getCommandeByRegionByMonth = function () {
    WS.get('bl/byRegionByMonth/commande')
      .then(function (response) {
        if (response.status == 200) {
          vm.regionbymonth = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }
  vm.selectUsers = function () {
    WS.get('users/selectUsers', vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.selectusers = response.data;
          vm.selectusersfiltred = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectActivites = () => {
    WS.get('activites/selectActivites')
      .then(function (response) {
        vm.activites = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.specialite = '';
  vm.selectSpecialites = () => {
    WS.get('specialites')
      .then(function (response) {
        vm.specialites = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectRegions = () => {
    WS.get('regions/selectRegions')
      .then(function (response) {
        vm.regions = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };
  vm.selectGouvernorats = () => {
    WS.get('gouvernorats/selectGouvernorats')
      .then(function (response) {
        vm.gouvernorats = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectZones = () => {
    WS.get('zones/selectZones')
      .then(function (response) {
        vm.zones = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectDelegations = function () {
    WS.get('delegations/selectDelegations')
      .then(function (response) {
        if (response.status == 200) {
          vm.delegations = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };



  vm.getGouvernorats = function () {
    WS.get('gouvernorats', vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.gouvernorats = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getcommercial = function () {
    WS.get('users/commprev', vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.commercial = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getCashvanPrevendeur = function () {
    WS.get('users/cashvanprevendeur', vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.commercial = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.selectCashvanAndLiv = () => {
    WS.get('users/selectCashvanAndLiv')
      .then(function (response) {
        if (response.status == 200) {
          vm.commercial = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.getComWidthLivzero = function () {
    WS.get('users/getComWidthLivzero')
      .then(function (response) {
        if (response.status == 200) {
          vm.commercial = response.data;
          vm.selectusers = response.data;
          console.log('**********************************');
          console.log(vm.selectusers);
          console.log('**********************************');
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }



  vm.getcentermaps = function () {
    WS.getScache('pays').then(function (response) {
      vm.getpays = response.data;
      vm.center = vm.getpays.latitude + ', ' + vm.getpays.longitude;
      vm.zoom = AppSettings.zoomalgerie;
      $scope.$apply();
    }, function (error) {
      console.log(error);
    });
  };

  vm.getClientInactifs = function (page = 1, per_page = 10) {

    WS.getData('clients/FindClientsinactifs',
      {
        page, per_page,
        periode: vm.filter.date,
        type: vm.filter.operation,
        routing: vm.filter.routing,
        categorie: vm.filter.categorie,
        annee: vm.filter.annee,
        gouvernorat: vm.filter.gouvernorat

      })
      .then(function (response) {
        if (response.status == 200) {
          vm.inactifs = response.data.data;
          vm.current_page = response.data.current_page;
          vm.previous = vm.current_page - 1;
          vm.next = vm.current_page + 1;
          vm.from = response.data.form;
          vm.last_page = response.data.last_page;
          vm.next_page_url = response.data.next_page_url;
          vm.per_page = Number(response.data.per_page);
          vm.prev_page_url = response.data.prev_page_url;
          vm.to = response.data.to;
          vm.total = response.data.total;
          vm.paginate = [];
          for (var i = 1; i <= vm.last_page; i++) {
            vm.paginate.push(i);
          }

          // calculate center map
          var center_lat = 0;
          var center_lng = 0;
          var total_pos = 0;
          _.each(vm.inactifs, element => {
            if (check_lat_lon(element.latitude, element.longitude)) {
              total_pos++;
              center_lat += Number(element.latitude);
              center_lng += Number(element.longitude);

            }
          });

          if (total_pos > 0) {
            center_lat = center_lat / total_pos;
            center_lng = center_lng / total_pos;

            /**
     * MAPS
     */
            vm.selectmapsclients = angular.copy(vm.inactifs);
            vm.centerroutes = angular.copy(center_lat + ', ' + center_lng);
            vm.zoomRouting = 10;
            $('#container-Client').empty();
            $('#container-Client').html('<div style="width: 100%; height: 600px" id="mapContainerClients"></div>');

            var mymap = L.map('mapContainerClients').setView([center_lat, center_lng], vm.zoomRouting);


            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
              foo: 'bar',
              attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
            })
              .addTo(mymap);


            vm.selectmapsclients.forEach(function (element, index) {
              // Creates a red marker with the coffee icon
              var redMarker = L.ExtraMarkers.icon({
                icon: 'fa-home',
                markerColor: 'blue',
                shape: 'circle', //'circle', 'square', 'star', or 'penta'
                prefix: 'fa'
              });

              var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
              var info = $compile(`
                                         <div id="bodyContent" style="width=300px;">
                                              <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                <b> | </b>
                                                <a ui-sref="app.clients.historiques({code : ${element.code}})" target="_blank">
                                                  Historique
                                                </a>
                                              </p>
                                              <b>Client</b> : ${element.nomclient}</a></br>
                                              <b>Magasin</b> : ${element.magasin}</a></br>
                                              <b>Code</b> : ${element.code}</a></br>
                                              <b>Adresse</b> : ${element.adresse_facturation}</br>
                                              <b>Route</b> : ${element.routing_code}</br>
                                              <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
              var popup = L.popup({
                maxWidth: 600,
                minWidth: 200
              }).setContent(info[0])
              marker.bindPopup(popup);

            });
          }

          $scope.$apply();
          // $('.table_inactifs').DataTable({
          //     "language": DatatableLang
          // });
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.exportclientsinactifs = function (periode, type, routing, categorie, gouvernorat, annee) {

    WS.get('clients/inactifs/' + periode + '/' + type + '/' + routing + '/' + categorie + '/' + gouvernorat + '/' + annee).then(
      function (response) {
        var count = response.data.length;

        var filename = 'Clients Inactifs depuis-' + periode;
        // var filename = response.data[count-1].type+'-'+response.data[count-1].date_visite;

        var file = {
          sheetid: 'Clients-Inactifs depuis-' + periode,
          headers: true,
          caption: {
            title: 'Clients-Inactifs depuis-' + periode,
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'code', title: 'Code' },
            { columnid: 'nomclient', title: 'Nom' },
            { columnid: 'magasin', title: 'Magasin' },
            { columnid: 'adresse_facturation', title: 'Adresse ' },
            { columnid: 'mobile', title: 'Telephone ' },
            { columnid: 'routing_code', title: 'Routing' },
            { columnid: 'categorie', title: 'Categorie' },
            { columnid: 'gouvernorat', title: 'Gouvernorat' },
            { columnid: 'annee', title: 'Anne' },
            { columnid: 'longitude', title: 'Longitude' },
            { columnid: 'latitude', title: 'Latitude' },
            { columnid: 'date_creation', title: 'Date creation' },
            { columnid: 'last_visite', title: 'Derniere visite' },
            { columnid: 'total_visite', title: 'Total visite' },
            { columnid: 'total_ca', title: 'CA' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
      function (error) {
        console.log(error);
      }
    );
  };



  vm.exportMerchFruit = function (date_debut, date_fin, commercial) {

    WS.get('merchandisings/ByDate/' + date_debut + '/' + date_fin + '/' + commercial).then(
      function (response) {
        var filename = 'Rapport merch depuis-' + date_debut;
        var file = {
          sheetid: 'Rapport-merch depuis-' + date_debut,
          headers: true,
          caption: {
            title: 'Rapport-merch depuis-' + date_debut,
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'user_code', title: 'Code de Utilisateur' },
            { columnid: 'user', title: 'Nom Utilisateur' },
            { columnid: 'client_code', title: 'Code du Client' },
            { columnid: 'client', title: 'Nom du Client' },
            { columnid: 'rayon_bebe', title: 'Disponibilité du Rayon Bébé' },
            { columnid: 'rayon_dietetique', title: 'Disponibilité du Rayon Diététique' },
            { columnid: 'rayon_frais', title: 'Disponibilité du Rayon Frais' },
            { columnid: 'article_non_disponible', title: 'Article Non Disponible' },
            { columnid: 'saveur_non_disponible', title: 'Saveur Non Disponible' },
            { columnid: 'prix_non_affiche', title: 'Prix Non Affiché' },
            { columnid: 'article_non_visible', title: 'Article Non Visible' },
            { columnid: 'ras', title: 'Rien à Signaler (RAS)' },
            { columnid: 'autres', title: 'Autres Informations' },
            { columnid: 'rotation', title: 'Rotation des Stocks' },
            { columnid: 'prix_affiche', title: 'Prix Affiché' },
            { columnid: 'num_lot', title: 'Numéro de Lot' },
            { columnid: 'nbr_piece', title: 'Nombre de Pièces' },
            { columnid: 'nbr_facing', title: 'Nombre de Facing' },
            { columnid: 'date', title: 'Date' }


          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
      function (error) {
        console.log(error);
      }
    );
  };


  vm.ck_lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
  vm.ck_lon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

  function check_lat_lon(lat, lon) {
    var validLat = vm.ck_lat.test(lat);
    var validLon = vm.ck_lon.test(lon);
    if (validLat && validLon) {
      return true;
    } else {
      return false;
    }
  }


  vm.getcommercialByCode = function (code) {
    var url = 'users/query/code="';
    url += code + '"';

    WS.get(url, vm.access)

      .then(function (response) {
        if (response.status == 200) {
          vm.commerciallist = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getAttributeValue = function (code, liste, attribute, value) {
    if (!liste) return;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] == code && liste[i]['client_code'] == value) {
        return liste[i];
      }
    }
  }

  vm.getAttributeValueforRapVent = function (code, liste, attribute, value) {
    //console.log(liste);
    if (!liste) return;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] == code && liste[i]['code'] == value) {
        return liste[i];
      }
    }
  }


  vm.getAttributeValueforDN = function (code, liste, attribute, value) {
    if (!liste) return;

    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] == code && liste[i]['code'] == value) {
        return liste[i];
      }
    }
  }

  vm.getTotalClient = function (liste, value) {
    var tot = 0;
    if (!liste) return tot;

    for (var i = 0; i < liste.length; i++) {

      if (liste[i]['code'] == value) {
        tot = parseInt(liste[i]['nbrclient']) + parseInt(tot);
      }

    }
    return tot;
  }

  vm.getAttributeOneValue = function (code, liste, attribute) {
    if (!liste) return;

    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] == code) {
        return liste[i];
      }
    }
  }

  vm.getAttributeOneValuedate = function (code, liste, attribute, date) {
    if (!liste) return;

    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] == code && liste[i]['date'] == date) {
        return liste[i];
      }
    }
  }


  vm.getZones = function () {
    WS.get('zones')
      .then(function (response) {
        if (response.status == 200) {
          vm.zones = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getSecteur = function () {
    WS.get('secteurs')
      .then(function (response) {
        if (response.status == 200) {
          vm.secteurs = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getRegions = function () {
    WS.get('regions')
      .then(function (response) {
        if (response.status == 200) {
          vm.regions = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.getGammes = function () {
    WS.get('gammes')
      .then(function (response) {
        vm.gammes = response.data;
        vm.selectgammes = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getMarque = function () {
    WS.get('marques')
      .then(function (response) {
        vm.marques = response.data;
        vm.selectmarques = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getFournisseurs = function () {
    WS.get('fournisseurs')
      .then(function (response) {
        vm.fournisseurs = response.data;
        vm.selectfournisseurs = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getAllproduits = function () {
    WS.get('produits/names')
      .then(function (response) {
        vm.produitsnames = response.data;
        vm.selectproduitsnames = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getFamilles = function () {
    WS.get('familles')
      .then(function (response) {
        vm.familles = response.data;
        vm.selectfamilles = response.data;

        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getSousfamilles = function () {
    WS.get('sousfamilles')
      .then(function (response) {
        vm.sousfamilles = response.data;
        vm.selectsousfamilles = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };



  vm.getRapportVenteclient = function (details, entete) {

    var url = 'rapports/venteclient' + '/' + entete + '/' + details.date_debut + '/' + details.date_fin + '/' + details.user_code + '/' + details.type;
    WS.get(url, vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.rapportventeclient = response.data;
          var resdate = [];
          var j = 0;
          angular.forEach(response.data, function (element, index) {
            if (resdate.length >= 0) {
              angular.forEach(resdate, function (elementcheck, indexcheck) {
                if (element.date == elementcheck.date && index != indexcheck) {
                  j++;
                }
              });

              if (j == 0) {
                resdate.push({ date: element.date });
              } else {
                j = 0;
              }
            }


          });
          vm.resdate = resdate;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }



  vm.getClientsFiltred = function () {

    var url = 'clients/secteur';

    url += '/' + vm.secteur;

    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.clients = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getClientsFiltredCommercial = function (code) {
    var url = 'clients/commercial';
    url += '/' + code;
    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.clients = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.GetRapportVentesoufamilleparclient = function (details, type) {
    var data = {
      type,// bl or commande
      date_debut: details.date_debut,
      date_fin: details.date_fin,
      commercial: details.commercial,
      categorie: details.categorie,
      cloture: details.cloture,
      value: details.type //qte or quantite or chiffre
    }
    //var url = 'rapports/rapportventesousfamille' + '/' + type + '/' + details.date_debut + '/' + details.date_fin + '/' + details.commercial + '/' + details.type;
    var url = 'rapports/rapportventesousfamille';
    WS.getData(url, data)
      .then(function (response) {
        if (response.status == 200) {
          vm.rapportventesousfamille = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.getSuiviVenteClientParType = function (details) {
    var url = 'rapports/getVenteParClientParType' + '/' + details.date_debut + '/' + details.date_fin + '/' + details.route + '/' + details.choix + '/' + details.fieldvalue;
    WS.get(url, vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.suivi_venteparclientpartype = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getRapportVentesanscommercial = function (details) {
    var url = 'rapports/rapportvente' + '/' + details.entete + '/' + details.date_debut + '/' + details.date_fin + '/' + details.choix + '/' + details.fieldvalue;
    WS.get(url, vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.rapportventesanscommercial = response.data;
          vm.typeofv = details.type;
          vm.choixofv = details.choix;
          console.log("vm.rapportventesanscommercial", vm.rapportventesanscommercial);

          /**
           * total by users
           */
          var users_total = _.groupBy(vm.rapportventesanscommercial, function (obj) { return obj.user_code; });

          var res_total_users = {};
          _.each(users_total, (element, index) => {
            var total = _.reduce(element, function (memo, num) { return memo + num.total; }, 0);
            var qte = _.reduce(element, function (memo, num) { return memo + num.qte; }, 0);
            var carton = _.reduce(element, function (memo, num) { return memo + num.carton; }, 0);
            res_total_users[index] = { total, qte, carton };
          });
          vm.res_total_users = res_total_users;
          console.log('res_total_users', res_total_users);

          /**
           * total by categories
           */

          var categories_total = _.groupBy(vm.rapportventesanscommercial, function (obj) { return obj.code; });

          var res_total_categories = {};
          _.each(categories_total, (element, index) => {
            var total = _.reduce(element, function (memo, num) { return memo + num.total; }, 0);
            var qte = _.reduce(element, function (memo, num) { return memo + num.qte; }, 0);
            var carton = _.reduce(element, function (memo, num) { return memo + num.carton; }, 0);
            res_total_categories[index] = { total, qte, carton };
          });
          vm.res_total_categories = res_total_categories;
          console.log('res_total_categories', res_total_categories);
          $scope.$apply();

        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };



  vm.getBeneficeParPeriode = function (suivi_cls) {
    WS.getData('rapports/getbeneficeParPeriode', suivi_cls)
      .then(response => {
        vm.beneficeParPeriode = response.data;

        vm.choixType = suivi_cls.choix;
        var action_user = response.data;
        var action_categorie = response.data;

        vm.beneficebyuser = _.groupBy(action_user, function (value) { return value.user_code; });
        vm.beneficebycategorie = _.groupBy(action_categorie, function (value) { return value.code; });

        /**
         * group by user
         */
        var totaluser = [];
        _.each(vm.beneficebyuser, (element, index) => {
          var obj = {};
          obj.user_code = element[0].user_code;
          obj.beneficetotal = _.reduce(element, function (memo, num) { return memo + num.benefice; }, 0);
          totaluser.push(obj);
        });

        vm.totalbeneficebyuser = totaluser;

        /**
         * groupBy categorie (quelque soit : (gamme, famille , sousfamille, produits, marque)
         */

        var totalcategorie = [];
        _.each(vm.beneficebycategorie, (element, index) => {

          var obj = {};
          obj.categorie = element[0].code;
          obj.beneficetotal = _.reduce(element, function (memo, num) { return memo + num.benefice; }, 0);

          totalcategorie.push(obj);
        });

        vm.totalbeneficebycategorie = totalcategorie;


        $scope.$apply();
      });
  };

  vm.table2excel = function (tableId) {
    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;
    var title = "rapport_par_periode-" + moment().format('YYYY-MM-DD');

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);

  };

  vm.exporttableHtml = function (tableId, title = null) {

    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;
    var title = title ? title : 'Rapport';

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);

  };


  vm.exportMultipleTables = function (tables, title = 'Rapport') {
    const XLSX = require('xlsx');

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Iterate over the tables array
    tables.forEach((tableId, index) => {
      const table = document.querySelector(tableId);
      if (table) {
        // Convert table to a worksheet
        const worksheet = XLSX.utils.table_to_sheet(table);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, `Sheet${index + 1}`);
      } else {
        console.warn(`Table with ID ${tableId} not found`);
      }
    });

    // Export the workbook
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary data to a Blob
    const blob = new Blob([s2ab(excelData)], { type: 'application/octet-stream' });

    // Download the file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${title}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Helper function to convert string to ArrayBuffer
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
  };


  vm.exportAllTableHtml58 = (groupes, prefix, title) => {

    var array = []
    groupes.map(group => {
      array.push(prefix + group);
    })
    vm.exportMultipleTables(array, title)

  }


  vm.GetDnGammeVendeur = function (details, type) {
    var url = 'rapports/dngammevendeur' + '/' + type + '/' + details.date_debut + '/' + details.date_fin + '/' + details.choix;
    WS.get(url, vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.dngammevendeur = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  /*$(document).on('click', '.pin', function() {
          $(this).siblings().removeClass('selected');
          $(this).addClass('selected');
          var id =  $(this).find('.pin').data('id') ;
          $('tbody tr').removeClass('selected');
          $('tr[data-id='+ id +']').addClass('selected');

          $('html, body').animate({
              scrollTop: $('tr[data-id='+ id +']').offset().top - 100
          }, 1000);
      });*/

  vm.findClientByMaps = function (id) {

    angular.element(document.getElementById('client-' + id)).addClass('markerGreen');
    $timeout(function () {
      angular.element(document.getElementById('client-' + id)).removeClass('markerGreen');
      $timeout(function () {
        angular.element(document.getElementById('client-' + id)).addClass('markerGreen');
        $timeout(function () {
          angular.element(document.getElementById('client-' + id)).removeClass('markerGreen');
          $('#client-' + id).scrollTop();
        }, 300);
      }, 300);
    }, 200);
  }

  vm.selektMarker = function (id) {
    angular.element($('.pin-' + id)).addClass('markerRed');
    $timeout(function () {
      angular.element($('.pin-' + id)).removeClass('markerRed');
      $timeout(function () {
        angular.element($('.pin-' + id)).addClass('markerRed');
        $timeout(function () {
          angular.element($('.pin-' + id)).removeClass('markerRed');
        }, 300);
      }, 300);
    }, 200);
  }



  vm.getrapportvisites = function (code, date) {
    WS.getScache('visites/byCommercial' + '/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.rapportvisites = response.data.visites;
          vm.centerrapvisite = response.data.center.center;
          vm.centerrapmaps = response.data.center;
          vm.kilometragerap = response.data.kilometrage;
          /**
           * maps openstreetmaps
           */
          $('#maps_rapport_tournee').empty();
          $('#maps_rapport_tournee').append('<div style="width: 100%; height: 580px" id="maps_rapport_tournee_panel"></div>');




          var mymap = L.map('maps_rapport_tournee_panel',
            {
              center: new L.LatLng(vm.centerrapmaps.latitude, vm.centerrapmaps.longitude),
              zoom: 8,
              fullscreenControl: true,
              fullscreenControlOptions: {
                position: 'topright'
              }
            }

          ).setView([vm.centerrapmaps.latitude, vm.centerrapmaps.longitude], 12);
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
          }).addTo(mymap);
          // Creates a red marker with the coffee icon
          var markerClNonVisiteIcon = L.ExtraMarkers.icon({
            icon: 'fa-home',
            markerColor: 'red',
            shape: 'circle', //'circle', 'square', 'star', or 'penta'
            prefix: 'fa'
          });
          var MarkerClientVisiteIcon = L.ExtraMarkers.icon({
            icon: 'fa-home',
            markerColor: 'blue',
            shape: 'circle', //'circle', 'square', 'star', or 'penta'
            prefix: 'fa'
          });

          var GreenMarkerClientVisiteIcon = L.ExtraMarkers.icon({
            icon: 'fa-home',
            markerColor: 'green',
            shape: 'circle', //'circle', 'square', 'star', or 'penta'
            prefix: 'fa'
          });

          _.each(vm.rapportvisites, (element, index) => {
            // position visite

            var markerClientVisite = L.marker([element.latitude, element.longitude], { icon: MarkerClientVisiteIcon }).addTo(mymap);
            // markerClientVisite.bindTooltip(element.code, { permanent: true, direction: 'top' });

            var infoClientVisite = $compile(`
                                                 <div id="bodyContent" style="width=400px;">
                                                      <div class="text-center">
                                                        <a ui-sref="app.clients.historiques({code : '${element.codeClient}'})" target="_blank">
                                                          Historique
                                                        </a>
                                                      </div>
                                                      <div><b>Code</b> : ${element.codeClient}</a></div>
                                                      <div><b>Client</b> : ${element.nomclient}</a></div>
                                                      <div><b>Magasin</b> : ${element.magasin}</a></div>
                                                      <div><b>Temps de visite</b> : ${element.difference}</div>
                                                      <div><b>Distance de visite</b> : ${element.distance}</div>
                                                      <div><b>Commentaire: </b> : ${element.commentaire}</a></div>
                                                </div>
                                                `)($scope);
            var popupClientVisite = L.popup({
              maxWidth: 600,
              minWidth: 300
            }).setContent(infoClientVisite[0])
            markerClientVisite.bindPopup(popupClientVisite);


          });

          $scope.$watchCollection(
            "vm.tournees_non_traite",
            function (newValue, oldValue) {
              _.each(newValue, (element, index) => {
                // position client non visite
                var markerClNonVisite = L.marker([element.latitude, element.longitude], { icon: markerClNonVisiteIcon }).addTo(mymap);
                var infoNonVisite = $compile(`
                                                         <div id="bodyContent" style="width=400px;">
                                                              <div class="text-center">
                                                                <a ui-sref="app.clients.historiques({code : '${element.codeClient}'})" target="_blank">
                                                                  Historique
                                                                </a>
                                                              </div>
                                                              <div><b>Code</b> : ${element.client_code}</a></div>
                                                              <div><b>Client</b> : ${element.nomclient}</a></div>
                                                              <div><b>Magasin</b> : ${element.magasin}</a></div>
                                                        </div>
                                                        `)($scope);
                var popupNonVisite = L.popup({
                  maxWidth: 600,
                  minWidth: 300
                }).setContent(infoNonVisite[0])
                markerClNonVisite.bindPopup(popupNonVisite);
              });

            }
          );

          $scope.$watchCollection(
            "vm.clients",
            function (newValue, oldValue) {
              _.each(newValue, (element, index) => {
                // position client non visite
                var markerlisteclients = L.marker([element.latitude, element.longitude], { icon: GreenMarkerClientVisiteIcon }).addTo(mymap);
                var infoclients = $compile(`
                                                         <div id="bodyContent" style="width=400px;">
                                                              <div class="text-center">
                                                                <a ui-sref="app.clients.historiques({code : '${element.code}'})" target="_blank">
                                                                  Historique
                                                                </a>
                                                              </div>
                                                               <div><b>Client</b> : ${element.code}</a></div>
                                                              <div><b>Client</b> : ${element.nom}</a></div>
                                                              <div><b>Magasin</b> : ${element.magasin}</a></div>
                                                        </div>
                                                        `)($scope);
                var popupListe = L.popup({
                  maxWidth: 600,
                  minWidth: 300
                }).setContent(infoclients[0])
                markerlisteclients.bindPopup(popupListe);
              });

            }
          );

          setTimeout(function () {
            mymap.invalidateSize();
          }, 80);
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.exportgetrapportvisites = function (code, date) {

    WS.get('visites/exportbyCommercial' + '/' + code + '/' + date).then(
      function (response) {
        var count = response.data.length;

        //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
        var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

        var file = {
          sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          caption: {
            title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'vcode', title: 'Numero' },
            { columnid: 'net_a_payer', title: 'Montant' },
            { columnid: 'nomclient', title: 'Client' },
            { columnid: 'heure_debut', title: 'Heure ' },
            { columnid: 'user', title: 'Commercial' },
            { columnid: 'date_visite', title: 'Date' },
            { columnid: 'type', title: 'type' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };
        response.data.forEach(function (element, index) {
          response.data[index].net_a_payer = response.data[index].net_a_payer.toString().replace('.', ',');
        });
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
      function (error) {
        console.log(error);
      }
    );
  };



  vm.export_rapport_42 = function (tableId, title = 'Rapport suivi Comercial par client') {

    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);

  };

  vm.printPanel = function (panel) {

    var mywindow = window.open('', 'PRINT');

    mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                    <style>
                                        .noprint{display: none;}.text-center{text-align:center}
                                        th{border:1px solid #ddd!important;}
                                        td{border:1px solid #ddd!important;}
                                    </style>
                                `);
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById(panel).innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  }


  vm.getValueByCodeByListe = function (code, liste) {


    if (!liste) return 0;
    for (var i = 0; i < liste.length; i++) {
      if (liste[i]['client_code'] == code) {
        return liste[i];
      }
    }
    return 0;
  }





  vm.exportAllVisiteByCommercial = function (code, date) {

    WS.get('visites/exportAllbyCommercial' + '/' + code + '/' + date).then(
      function (response) {
        var count = response.data.length;

        //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
        var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

        var file = {
          sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          caption: {
            title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'vcode', title: 'Numero' },
            { columnid: 'net_a_payer', title: 'Montant' },
            { columnid: 'nomclient', title: 'Client' },
            { columnid: 'adresse_facturation', title: 'Adresse' },
            { columnid: 'heure_debut', title: 'Heure ' },
            { columnid: 'user', title: 'Commercial' },
            { columnid: 'date_visite', title: 'Date' },
            { columnid: 'type', title: 'type' },
            { columnid: 'cause', title: 'Cause' },
            { columnid: 'commentaire', title: 'Commentaire' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };

        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  vm.exportAllVisiteByCommercialMois = function (code, date) {

    WS.get('visites/exportAllbyCommercialMois' + '/' + code + '/' + date).then(
      function (response) {
        var count = response.data.length;

        //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
        var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

        var file = {
          sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          caption: {
            title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'vcode', title: 'Numero' },
            { columnid: 'net_a_payer', title: 'Montant' },
            { columnid: 'nomclient', title: 'Client' },
            { columnid: 'adresse_facturation', title: 'Adresse' },
            { columnid: 'heure_debut', title: 'Heure debut' },
            { columnid: 'heure_fin', title: 'Heure fin' },
            { columnid: 'difference', title: 'Difference ' },
            { columnid: 'user', title: 'Commercial' },
            { columnid: 'date_visite', title: 'Date' },
            { columnid: 'type', title: 'type' },
            { columnid: 'cause', title: 'Cause' },
            { columnid: 'commentaire', title: 'Commentaire' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };

        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
      function (error) {
        console.log(error);
      }
    );
  };


  vm.exportAllbyMois = function (date) {

    WS.get('visites/exportAllbyMois' + '/' + date).then(
      function (response) {
        var count = response.data.length;

        //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
        var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

        var file = {
          sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          caption: {
            title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'vcode', title: 'Numero' },
            { columnid: 'net_a_payer', title: 'Montant' },
            { columnid: 'client_code', title: 'client_code' },
            { columnid: 'nomclient', title: 'Client' },
            { columnid: 'adresse_facturation', title: 'Adresse' },
            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'user_code', title: 'code Commercial' },
            { columnid: 'heure_debut', title: 'Heure debut' },
            { columnid: 'heure_fin', title: 'Heure fin' },
            { columnid: 'difference', title: 'Difference ' },
            { columnid: 'user', title: 'Commercial' },
            { columnid: 'date_visite', title: 'Date' },
            { columnid: 'type', title: 'type' },
            { columnid: 'cause', title: 'Cause' },
            { columnid: 'commentaire', title: 'Commentaire' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };

        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
      function (error) {
        console.log(error);
      }
    );
  };


  vm.getmontantttc = function (code, date) {
    WS.getScache('entetecommercial/ttc' + '/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.montantttc = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getmontant_total_bl_FromEntete = function (code, date) {

    WS.getScache('bl/distinctmontant' + '/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.montant_total_ttcFromEntete = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getmontant_total_cmd_FromEntete = function (code, date) {

    WS.getScache('commandes/distinctmontant' + '/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.montant_total_cmd_ttcFromEntete = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getmontant_total_avoir_FromEntete = function (code, date) {

    WS.getScache('avoir/distinctmontant' + '/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.montant_total_avoir_ttcFromEntete = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getrecouvrementttc = function (code, date) {

    WS.getScache('paiements/ttc' + '/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.recouvrementttc = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };



  vm.getProduits = function () {
    WS.get('produits')
      .then(function (response) {
        if (response.status == 200) {
          vm.produits = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.selectProduits = function () {
    WS.get('produits/selectProduit')
      .then(function (response) {
        if (response.status == 200) {
          vm.produits = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectProduitGamme = function () {
    WS.get('produits/selectProduitGamme')
      .then(function (response) {
        if (response.status == 200) {
          vm.produits = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.getMoisBydate = function (date) {

    var mydate = new Date(date);

    var tab_mois = new Array("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre");
    vm.mois = tab_mois[mydate.getMonth()];

    return vm.mois;
  }

  vm.getCAByAttributeValue = function (code, liste, attribute, value) {

    if (!liste) return;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] == value && liste[i]['code'] == code) {
        //console.log('forclient=', liste[i]);

        return liste[i];
      }


    }
  }

  vm.getCAByAttributeValueNbClient = function (code, liste, attribute, value) {

    if (!liste) return ' 0 ';
    for (var i = 0; i < liste.length; i++) {
      if (liste[i][attribute] == value && liste[i]['code'] == code) {
        return liste[i];
      } else {
        return 0;
      }
    }
    return ' 0 ';
  }


  vm.getValue = function (code, liste, attribute, value) {
    if (!liste) return;
    for (var i = 0; i < liste.length; i++) {
      if (liste[i][attribute] == value) {
        return liste[i];
      }
    }
  };

  vm.getblbyCommercialcode = function (code, date, type) {
    WS.getScache('bl/byCommercialcode/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.blbyCommercialcode = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getCmdbyCommercialcode = function (code, date, type) {
    WS.get('commandes/byCommercialcode/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.cmdbyCommercialcode = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getRapportVenteProduitParTournee = function (code, date) {
    WS.getScache('rapports/rapportventeproduitpartournee/' + date + '/' + code)
      .then(function (response) {
        if (response.status == 200) {
          vm.rapportventeproduitpartournee = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getcmdbyCommercialcode = function (code, date) {
    WS.get('commandes/byCommercialcode' + '/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.blbyCommercialcode = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getKPIBydate = function (date) {
    WS.getScache('bl/getKPIBydate/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.bl_byCommercialDate = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.getKPICommandeBydate = function (date) {
    WS.getScache('commandes/getKPICommandeBydate/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.cmd_byCommercialDate = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  WS.get('bl/byCommercial/commande/year')
    .then(function (response) {
      if (response.status == 200) {
        vm.cmd_byCommercialYear = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });


  vm.getCreditByCommercialByDate = function (date) {
    WS.getScache('paiements/creditByCommercialByDate/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.creditByCommercialByDate = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };



  WS.get('paiements/getetatCredit/date')
    .then(function (response) {
      if (response.status == 200) {

        vm.credittoday = response.data;
        vm.creditTodayForAll = 0;

        _.each(response.data, e => {
          vm.creditTodayForAll += parseFloat(e.solde);
        })

        $scope.$apply();
      } else { }
    })


  WS.get('visites/byCommercial/count/month')
    .then(function (response) {
      if (response.status == 200) {
        vm.visites_byCommercialCountMonth = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });

  WS.get('clients/byCommercial/month')
    .then(function (response) {
      if (response.status == 200) {
        vm.clientsbyCommercialThisMonth = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });


  WS.get('paiements/getetatCredit/year')
    .then(function (response) {
      if (response.status == 200) {
        vm.creditthisyear = response.data;


        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });


  WS.get('commandes/byCommercial/count/month')
    .then(function (response) {
      if (response.status == 200) {
        vm.cmd_byCommercialCountMonth = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });
  WS.get('commandes/byCommercial/count/year')
    .then(function (response) {
      if (response.status == 200) {
        vm.cmd_byCommercialCountYear = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });
  WS.get('commandes/byCommercial/count/date')
    .then(function (response) {
      if (response.status == 200) {
        vm.cmd_byCommercialCountDate = response.data;

        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });


  WS.get('bl/byCommercialcount/month')
    .then(function (response) {
      if (response.status == 200) {
        vm.bl_byCommercialCountMonth = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });
  WS.get('bl/byCommercialcount/year')
    .then(function (response) {
      if (response.status == 200) {
        vm.bl_byCommercialCountYear = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });


  WS.get('visites/byCommercial/count/year')
    .then(function (response) {
      if (response.status == 200) {
        vm.visites_byCommercialCountYear = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });
  WS.get('clients/byCommercial/year')
    .then(function (response) {
      if (response.status == 200) {
        vm.clientsbyCommercialThisYear = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });
  WS.get('paiements/getetatCredit/month')
    .then(function (response) {
      if (response.status == 200) {
        vm.creditthismonth = response.data;

        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });

  WS.get('bl/byCommercial/commande/month')
    .then(function (response) {
      if (response.status == 200) {
        vm.cmd_byCommercialMonth = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });

  WS.get('bl/byCommercial/commande/year')
    .then(function (response) {
      if (response.status == 200) {
        vm.cmd_byCommercialYear = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });




  WS.get('bl/byCommercial/bl/month/withavoir')
    .then(function (response) {
      if (response.status == 200) {
        vm.bl_byCommercialMonth = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });






  WS.get('bl/byCommercial/bl/year/withavoir')
    .then(function (response) {
      if (response.status == 200) {
        vm.bl_byCommercialYear = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });





  /**/
  /*  WS.get('visites/byCommercial/count/date')
  .then(function(response) {
      if (response.status == 200) {
          vm.visites_byCommercialCountDate = response.data;

          $scope.$apply();
      } else {}
  })
  .then(null, function(error) {
      console.log(error);
  });*/



  vm.nb_visite_byCommercialByDate = function (date) {
    //  console.log('visites/byCommercialByDate/' + date + '/' + vm.date_fin);
    WS.getScache('visites/byCommercialByDate/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.visites_byCommercialCountDate = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.nb_visite_byCommercialByDateJournalier = function (date) {
    //  console.log('visites/byCommercialByDate/' + date + '/' + vm.date_fin);
    WS.get('visites/byCommercialCountByDateJournalier/' + date + '/' + vm.date_fin)
      .then(function (response) {
        if (response.status == 200) {
          vm.visites_byCommercialCountDate = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };



  vm.nb_bl_byCommercialByDate = function (date) {
    WS.getScache('bl/byCommercialcountByDate/' + date + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.bl_byCommercialCountDate = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });

  };

  vm.nb_commandes_byCommercialByDate = function (date) {
    WS.get('commandes/byCommercialcountByDate/' + date + '/' + vm.date_fin)
      .then(function (response) {
        if (response.status == 200) {
          vm.bl_byCommercialCountDate = response.data;
          vm.cmd_byCommercialCountDate = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });

  };




  vm.getGammeswithproduits = function () {
    WS.get('gammes/produits')
      .then(function (response) {
        if (response.status == 200) {
          vm.Gammeswithproduits = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getClientsbyCommercialdate = function (date) {
    WS.getScache('clients/byCommercial/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.clientsbyCommercialdate = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getRapportJournalier = function () {
    var url = 'rapports/journalier';
    url += '/' + vm.date + '/' + vm.date_fin + '/' + vm.type + '/' + vm.nature;
    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.journalier = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };
  vm.getclientsbyCommercialcount = function () {
    var url = 'clients/byCommercial/';
    url += vm.date + '/' + vm.date_fin;
    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.clientsbyCommercialToday = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.getRapportJournalierCommande = function () {
    var url = 'rapports/journalierCommande';
    url += '/' + vm.date + '/' + vm.type + '/' + vm.date_fin;
    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.journalier = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.getRapportJournalierSousFamille = function () {

    var url = 'rapports/journaliersousfamille';
    url += '/' + vm.date + '/' + vm.date_fin + '/' + vm.type + '/' + vm.nature;

    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.journalier = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.getChiffreByFamille = function () {

    WS.getData('rapports/famille', { famille_code: vm.c_famille_code, date_debut: vm.c_date_debut, date_fin: vm.c_date_fin, nature: vm.nature })
      .then(function (response) {
        if (response.status == 200) {
          vm.ventefamille = response.data;
          var interval = 0;
          var jour = 0;
          var mois = 0;
          var annee = 0;

          _.each(vm.ventefamille, element => {
            interval += (element.filter.total) ? element.filter.total : 0;
            jour += (element.jour.total) ? element.jour.total : 0;
            mois += (element.mois.total) ? element.mois.total : 0;
            annee += (element.annee.total) ? element.annee.total : 0;
          });
          vm.total_interval = interval;
          vm.total_jour = jour;
          vm.total_mois = mois;
          vm.total_annee = annee;

          console.log('vm.ventefamille', vm.ventefamille)
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.getventefamille = function (commercial, liste) {
    if (!liste) return;

    for (var i = 0; i < liste.length; i++) {
      if (liste[i]['code'] == commercial.trim()) {
        return liste[i];
      }
    }
  }



  vm.getTotalByProduitandCommercial = function (produit, code, liste) {
    if (!liste) return;

    for (var i = 0; i < liste.length; i++) {

      if (liste[i]['produit_code'] == produit.trim() &&
        liste[i]['user_code'] == code.trim()) {
        return liste[i];
      }
    }
  }

  vm.getTotalByProduitandCommercialSousfamille = function (sousfamille, code, liste) {
    if (!liste) return;

    for (var i = 0; i < liste.length; i++) {

      if (liste[i]['code'] == sousfamille.trim() &&
        liste[i]['user_code'] == code.trim()) {
        return liste[i];
      }
    }
  }

  WS.get('clients/byCommercial/today')
    .then(function (response) {
      if (response.status == 200) {
        vm.clientsbyCommercialToday = response.data;
        $scope.$apply();
      } else { }
    })
    .then(null, function (error) {
      console.log(error);
    });

  vm.getPaiementByCommercial = function (date) {
    WS.get('paiements/getEtatPaiementByCommercial' + '/' + date + '/' + vm.date_fin)
      .then(function (response) {
        if (response.status == 200) {
          vm.getEtatPaiementByCommercial = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.venteEncoursByCommercial = function (date) {
    WS.get('rapports/getAllDetailsByCommercial' + '/' + date + '/' + vm.date_fin)
      .then(function (response) {
        if (response.status == 200) {
          vm.getVenteEncoursByCommercial = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getByAttributeValue = function (code, liste, attribute, value) {

    if (!liste) return;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] == value && liste[i]['code'] == code) {

        return liste[i];
      }


    }
  };

  vm.getByAttributeValueBL = function (code, liste) {

    if (!liste) return;
    if (liste == null) return;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i]['code'] == code) {

        return liste[i];
      }


    }
  };

  vm.getByAttributeValueCMD = function (code, liste, attribute) {

    if (!liste) return;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i]['code'] == code) {
        return liste[i];
      }
    }
  }

  vm.getSommeByAttributeValue = function (liste, attribute, value, code) {
    if (!liste) return;
    var total = 0;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] == value) {
        total = parseFloat(total) + parseFloat(liste[i][code]);
      }


    }
    return total;
  }

  vm.getSommeByAttributeValuebl = function (liste, attribute) {


    if (!liste) return;
    var total = 0;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] != 'null' && liste[i][attribute] != null) {
        total = parseFloat(total) + parseFloat(liste[i][attribute]);
      }


    }
    return total;
  }
  vm.getSommeByAttributeValueCredit = function (liste, attribute) {


    if (!liste) return;
    var total = 0;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] != 'null' && liste[i][attribute] != null) {
        total = parseFloat(total) + parseFloat(liste[i][attribute]);
      }


    }

    return total;
  }

  vm.getSommeByAttributeValuecmd = function (liste, attribute) {
    if (!liste) return;
    var total = 0;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] != 'null' && liste[i][attribute] != null) {
        total = parseFloat(total) + parseFloat(liste[i][attribute]);
      }


    }
    return total;
  }

  vm.getSommeBLByAttributeValue = function (liste, attribute, value, code) {
    if (!liste) return;
    var total = 0;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][attribute] == value) {
        total = parseFloat(total) + parseFloat(liste[i][code]);
      }

    }
    return total;
  }



  vm.getSommeProduit = function (code, liste, type) {
    if (!liste) return;

    var total = 0;
    for (var i = 0; i < liste.length; i++) {

      if (liste[i][type] == code) {
        total = parseFloat(total) + parseFloat(liste[i]['total']);
      }



    }
    return total
  }

  vm.getSommeChiffre = function (code, liste) {
    if (!liste) return;
    var total = 0;
    for (var i = 0; i < liste.length; i++) {
      if (liste[i]['user_code'] == code) {
        total = parseFloat(total) + parseFloat(liste[i]['total']);
      }
    }
    return total;
  }



  vm.getAllSommeChiffre = function (liste, attribute) {
    if (!liste) return;
    var total = 0;
    for (var i = 0; i < liste.length; i++) {


      total = parseFloat(total) + parseFloat(liste[i][attribute]);



    }
    return total
  }
  vm.getAllCreditThisMonth = function (code) {

    WS.getScache('paiements/getetatCreditbycommercial/' + code)
      .then(function (response) {
        if (response.status == 200) {
          vm.AllCreditThisMonth = new Array(31).fill(0);

          response.data.forEach(function (e, index) {
            var jour = parseInt(e.date.substr(8, 2));

            vm.AllCreditThisMonth[jour - 1] += parseFloat(Math.abs(e.solde));
          });

          $scope.$apply();
        } else { }
      })
  }

  vm.getAnalysebyCommercialcode = function (code) {

    WS.getScache('entetecommercial/analysesBycommercial' + '/' + code)
      .then(function (response) {
        if (response.status == 200) {





          vm.ventes_net = new Array(31).fill(0);
          vm.commandes_net = new Array(31).fill(0);
          response.data.forEach(function (e, index) {
            var jour = parseInt(e.date.substr(8, 2));
            var year = parseInt(e.date.substr(0, 4));

            var mois = parseInt(e.date.substr(5, 2));
            if (mois == (new Date()).getMonth() + 1) {

              if (e.type == 'bl') {


                vm.ventes_net[jour - 1] += parseFloat(e.net_a_payer);

              }
              if (e.type == 'commande') {
                vm.commandes_net[jour - 1] += parseFloat(e.net_a_payer);

              }

            }

          });

          vm.lineData = {
            labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
            series: [
              vm.ventes_net,

              vm.AllCreditThisMonth,

              vm.commandes_net
            ]

          };

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });

  }
  // line chart


  vm.lineOptions = {
    height: '200px',
    axisX: {
      labelInterpolationFnc: function (value) {
        return value;
      }
    }
  };

  vm.dngammevendeurGraph = function (type) {

    WS.get('rapports/dngammevendeur/graph/' + type)
      .then(function (response) {
        var resultat = [];
        angular.forEach(response.data, function (element, index) {
          var chartData = [];
          var j = 0;
          chartData.push({
            "date": new Date(element.date),
            "value": element.nbrclient,
            "volume": element.quantite
          });
          angular.forEach(response.data, function (elementCompare, indexCompare) {
            if (elementCompare.libelle == element.libelle && index != indexCompare) {
              chartData.push({
                "date": new Date(elementCompare.date),
                "value": elementCompare.nbrclient,
                "volume": elementCompare.quantite
              });
            };
          });

          if (resultat.length >= 0) {
            angular.forEach(resultat, function (elementResult, indexResult) {
              var elm = element.libelle.replace(/\s+/g, '-');
              var elmRes = elementResult.title.replace(/\s+/g, '-');
              if (elm == elmRes) {
                j++;
              }
            });
          }
          if (j == 0) {
            resultat.push({
              "title": element.libelle,
              "fieldMappings": [{
                "fromField": "value",
                "toField": "value"
              }, {
                "fromField": "volume",
                "toField": "volume"
              }],
              "dataProvider": chartData,
              "categoryField": "date"
            });
          }
        });
        var chart = AmCharts.makeChart("dngammevendeurGraph", {
          "type": "stock",
          "theme": "light",
          "dataSets": resultat,

          "panels": [{
            "showCategoryAxis": false,
            "title": "Value",
            "percentHeight": 70,
            "stockGraphs": [{
              "id": "g1",
              "valueField": "value",
              "comparable": true,
              "compareField": "value",
              "balloonText": "[[title]]:<b>[[value]]</b>",
              "compareGraphBalloonText": "[[title]]:<b>[[value]]</b>"
            }],
            "stockLegend": {
              "periodValueTextComparing": "[[percents.value.close]]%",
              "periodValueTextRegular": "[[value.close]]"
            }
          }, {
            "title": "Volume",
            "percentHeight": 30,
            "stockGraphs": [{
              "valueField": "volume",
              "type": "column",
              "showBalloon": false,
              "fillAlphas": 1
            }],
            "stockLegend": {
              "periodValueTextRegular": "[[value.close]]"
            }
          }],

          "chartScrollbarSettings": {
            "graph": "g1"
          },

          "chartCursorSettings": {
            "valueBalloonsEnabled": true,
            "fullWidth": true,
            "cursorAlpha": 0.1,
            "valueLineBalloonEnabled": true,
            "valueLineEnabled": true,
            "valueLineAlpha": 0.5
          },

          "periodSelector": {
            "position": "left",
            "periods": [{
              "period": "MM",
              "selected": true,
              "count": 1,
              "label": "1 month"
            }, {
              "period": "YYYY",
              "count": 1,
              "label": "1 year"
            }, {
              "period": "YTD",
              "label": "YTD"
            }, {
              "period": "MAX",
              "label": "MAX"
            }]
          },

          "dataSetSelector": {
            "position": "left"
          },

          "export": {
            "enabled": true
          }
        });
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.dncommercialclientGraph = function () {

    WS.get('rapports/dncommercialclientGraph')
      .then(function (response) {

        var uniqcommercial = [];
        //remove duplicate item
        angular.forEach(response.data, function (elementuniq, keyuniq) {
          var cc = 0;
          if (uniqcommercial.length >= 0) {
            angular.forEach(uniqcommercial, function (elementuniqCom, keyuniqCom) {
              if (elementuniq.commercial == elementuniqCom) {
                cc++;
              }
            });
          }
          if (cc == 0) {
            var filtered = elementuniq.commercial;
            uniqcommercial.push(filtered);
          }
        });

        var config = [];
        angular.forEach(uniqcommercial, function (elem, key) {
          config.push({
            "balloonText": "Vendeur :" + elem + " in [[category]]: [[value]] %",
            "bullet": "round",
            "hidden": false,
            "title": elem,
            "valueField": elem,
            "fillAlphas": 0
          });
        });


        var resultat = [];
        var j = 0;
        angular.forEach(response.data, function (element, key) {
          if (resultat.length >= 0) {
            angular.forEach(resultat, function (elementCheck, keyCheck) {
              if (elementCheck.date == element.date) {
                j++;
              }
            });
          }

          if (j == 0) {
            var item = {};
            var commercial = element.commercial;
            item.date = element.date;
            item[commercial] = element.pcnbrclient;
            angular.forEach(response.data, function (elementCompare, keyCompare) {
              if (element.date == elementCompare.date && element.commercial != elementCompare.commercial) {
                var commercial = elementCompare.commercial;
                item[commercial] = elementCompare.pcnbrclient;
              }
            });
            resultat.push(item);
          }
          j = 0;

        });
        // sort resultat by date
        resultat.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });


        vm.dateforth = resultat;
        vm.uniqcommercial = uniqcommercial;
        vm.countclientfortab = response.data;
        /**
        [{
                                date: "2017-01",
                                BEN_ALI_SABEUR: "1.01"
                            }, {
                                date: "2017-11",
                                GUITOUNI_KAMEL: "7.89",
                                BEN_ALI_SABEUR: "2.02"
                            }]
        */

        var chart = AmCharts.makeChart("dncommercialclientGraph", {
          "type": "serial",
          "theme": "light",
          "legend": {
            "useGraphSettings": true
          },
          "dataProvider": resultat,
          "valueAxes": [{
            "integersOnly": true,
            "maximum": 100,
            "minimum": 1,
            "reversed": false,
            "axisAlpha": 0,
            "dashLength": 5,
            "gridCount": 5,
            "position": "left",
            // "title": "Taux de Couverture client par vendeur (%)",
            "title": $translate.instant('Taux de Couverture client par vendeur (%)')
          }],
          "startDuration": 0.5,
          "graphs": config,
          "chartCursor": {
            "cursorAlpha": 0,
            "zoomable": false
          },
          "categoryField": "date",
          "categoryAxis": {
            "gridPosition": "start",
            "axisAlpha": 0,
            "fillAlpha": 0.05,
            "fillColor": "#000000",
            "gridAlpha": 0,
            "position": "top"
          },
          "export": {
            "enabled": true,
            "position": "bottom-right"
          }
        });

        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });

  };

  vm.differncetime = function (firsttime, lasttime) {
    var val1 = moment(firsttime, "HH:mm:ss");
    var val2 = moment(lasttime, "HH:mm:ss");
    //console.log(val1,val2);

    var hDiff = val2.diff(val1, 'hours');
    var mDiff = val2.diff(val1, 'minutes');
    var sDiff = val2.diff(val1, 'seconds');

    var secondDuration = sDiff % 60;
    var minuteDuration = mDiff % 60;
    //var minuteDuration = Math.floor(sDiff % 60);
    var hourDuration = Math.floor(mDiff / 60);


    return hourDuration + ':' + minuteDuration + ':' + secondDuration;

    /* var fromDate = moment.utc('2013-03-02 01:30:00');
     var duration = moment.duration({'hour':2, 'minute':70});

     var toDate = moment(fromDate);
     toDate.add(duration);
     console.log(fromDate.format('mmmm DD-MMM-YYYY hh:mm a'));
     console.log(toDate.format('mmmm DD-MMM-YYYY hh:mm a'));
     var hourDiff = toDate.diff(fromDate,'hours');
     var minuteDiff = toDate.diff(fromDate,'minutes');
     var hourDuration = Math.floor(minuteDiff/60);
     var minuteDuration = minuteDiff % 60;

     console.log(hourDuration);
     console.log(minuteDuration);*/
  };

  vm.timework = function (visites) {
    if (visites.length > 0) {
      var time = moment('00:00:00', 'HH:mm:ss');
      visites.forEach(function (element, index) {

        var duration = moment.duration({
          seconds: moment(element.difference, "HH:mm:ss").format('ss'),
          minutes: moment(element.difference, "HH:mm:ss").format('mm'),
          hours: moment(element.difference, "HH:mm:ss").format('HH')
        });
        time.add(duration);
      });
      return time.format('HH:mm:ss');
    }
  };

  /****************************** vente DN par Produits *****************************/

  vm.venteParProduit = function () {
    var vars = '';
    if (vm.filterprod != undefined || vm.filterprod != null) {

      vars += '?';
      if (vm.filterprod.produit) {
        vars += 'produit=' + vm.filterprod.produit + '&';
      }

    } else {
      vars = '';
    }
    WS.get('rapports/venteParProduit' + vars)
      .then(function (response) {
        //vm.venteparproduitregion = response.data;
        var tabs = [];
        if (response.data.length > 0) {
          response.data.forEach((element, index) => {
            var i = 0;
            if (tabs.length > 0) {
              tabs.forEach((eltab, indextab) => {
                if (element.produit == eltab.produit) {
                  eltab[element.month] = element.count;
                  i = i + 1;
                  tabs[indextab] = eltab;
                }
              });
              if (i == 0) {
                var newObject = {};
                newObject["produit"] = element.produit;
                newObject[element.month] = element.count;
                tabs.push(newObject);
              }
            } else {
              var newObject = {};
              newObject["produit"] = element.produit;
              newObject[element.month] = element.count;
              tabs.push(newObject);
            }
          });
        }
        vm.venteparproduit = tabs;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
    /*WS.get('rapports/venteParProduit?page='+page)
        .then(function(response) {
            vm.venteparproduit = response.data;
            $scope.$apply();
        })
        .then(null, function(error) {
            console.log(error);
        });*/
  };

  vm.venteParProduitRegion = function () {
    var vars = '';
    if (vm.filter != undefined || vm.filter != null) {

      vars += '?';
      if (vm.filter.produit) {
        vars += 'produit=' + vm.filter.produit + '&';
      }
      if (vm.filter.region) {
        vars += 'region=' + vm.filter.region + '&';
      }

    } else {
      vars = '';
    }
    WS.get('rapports/venteParProduitRegion' + vars)
      .then(function (response) {
        //vm.venteparproduitregion = response.data;
        var tabs = [];
        if (response.data.length > 0) {
          response.data.forEach((element, index) => {
            var i = 0;
            if (tabs.length > 0) {
              tabs.forEach((eltab, indextab) => {
                if (element.produit == eltab.produit && element.region == eltab.region) {
                  eltab[element.month] = element.count;
                  i = i + 1;
                  tabs[indextab] = eltab;
                }
              });
              if (i == 0) {
                var newObject = {};
                newObject["produit"] = element.produit;
                newObject["region"] = element.region;
                newObject[element.month] = element.count;
                tabs.push(newObject);
              }
            } else {
              var newObject = {};
              newObject["produit"] = element.produit;
              newObject["region"] = element.region;
              newObject[element.month] = element.count;
              tabs.push(newObject);
            }
          });
        }
        vm.venteparproduitregion = tabs;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });


    /*
    WS.get('rapports/venteParProduitRegion')
        .then(function(response) {
            vm.venteparproduitregion = response.data;

            $scope.$apply();
        })
        .then(null, function(error) {
            console.log(error);
        });
    */
  };

  vm.first = function () {
    var chart = AmCharts.makeChart("chartdiv", {
      "type": "pie",
      "theme": "light",
      "dataProvider": [{
        "country": "Lithuania",
        "litres": 501.9
      }, {
        "country": "Czech Republic",
        "litres": 301.9
      }, {
        "country": "Belgium",
        "litres": 60
      }, {
        "country": "The Netherlands",
        "litres": 50
      }],
      "valueField": "litres",
      "titleField": "country",
      "balloon": {
        "fixedPosition": true
      },
      "export": {
        "enabled": true
      }
    });
  }
  /****************************** vente DN par Produits *****************************/


  /**
   * Rapport commercial par mois
   */

  vm.filter = {};
  vm.filter.year = moment().format('YYYY');
  vm.filter.month = moment().format('MM');
  //vm.filter.day = moment().format('DD');

  vm.filterByCommercialMonth = function (filter = null) {
    if (filter) {
      vm.filter = filter;
    }
    // var url = 'rapports/filterByCommercialMonth';
    // if (vm.filter != null) {
    //     url += '/' + vm.filter.year + '/' + vm.filter.month;
    //     if (vm.filter.day && vm.filter.day != "") {
    //         url = url + '/' + vm.filter.day;
    //     } else {
    //         url += `/null`;
    //     }
    //     if (vm.filter.user_code && vm.filter.user_code != "") {
    //         url = url + '/' + vm.filter.user_code;
    //     }
    // }

    WS.getData('rapports/filterByCommercialMonth', vm.filter).then(function (response) {
      vm.kpis = response.data.table;
      vm.kpismonth = response.data.month;
      vm.kpisyear = response.data.year;
      vm.kpisday = response.data.day;

      // vm.sumVente = 0;
      // vm.sumCredit = 0;
      // vm.sumRecouvrement = 0;
      // vm.sumRecette = 0;
      // vm.sumNbbl = 0;
      // vm.sumNbVisite = 0;
      // vm.sumNbavoir = 0;
      // vm.nbClientVisite = 0;
      // vm.totalNbrClientInRoute = 0;
      // vm.nbClientNonVisite = 0;
      // vm.sumTauxSuc = 0;
      // vm.sumNbClient = 0;
      // vm.sumnbclsbl = 0;
      // for (var i = 0; i < vm.kpis.length; i++) {
      //     vm.sumVente += Number(vm.kpis[i].vente);
      //     vm.sumCredit += Number(vm.kpis[i].credit);
      //     vm.sumCommande += Number(vm.kpis[i].commande);
      //     vm.sumNbbl += Number(vm.kpis[i].nbbl);
      //     vm.sumNbavoir += Number(vm.kpis[i].nbavoir);
      //     vm.sumNbcommande += Number(vm.kpis[i].nbcommande);
      //     vm.sumNbVisite += Number(vm.kpis[i].nbVisite);
      //     //  vm.sumTauxSuc += Number(vm.kpis[i].nbbl) * 100 / Number(vm.kpis[i].nbVisite);
      //     vm.sumNbClient += Number(vm.kpis[i].nbClient);
      //     vm.nbClientVisite += Number(vm.kpis[i].nbClientVisite);
      //     vm.nbClientNonVisite += Number(vm.kpis[i].nbClientNonVisite);
      //     vm.totalNbrClientInRoute += Number(vm.kpis[i].totalNbrClientInRoute);
      //     vm.sumnbclsbl += Number(vm.kpis[i].nbclsBl);
      //     vm.sumRecette += Number(vm.kpis[i].recette);
      //     vm.sumRecouvrement += Number(vm.kpis[i].recouvrement);



      //     // call pie
      //     vm.piekpis(vm.filter.year, vm.filter.month, vm.filter.day, vm.kpis[i].code)
      // }


      $scope.$apply();
    }, function (error) {
      console.log(error);
    });
  };

  vm.filterByCommercialMonthGlobal = function () {
    // if (vm.filter != null) {
    //     var url = 'rapports/filterByCommercialMonth/' + vm.filter.year + '/' + vm.filter.month;
    //     if (vm.filter.day) {
    //         url += `/${vm.filter.day}`;
    //     }
    // } else {
    //     var url = 'rapports/filterByCommercialMonth';
    // }
    const data = {
      year: vm.filter.year,
      month: vm.filter.month,
      day: vm.filter.day,
      date_debut: vm.filter.date_debut,
      date_fin: vm.filter.date_fin,
      type_filter: vm.filter.type_filter,
    }

    WS.getData('rapports/filterByCommercialMonth', data).then(function (response) {
      vm.kpisglobal = response.data.table;
      vm.sumVente = 0;
      vm.sumCredit = 0;
      vm.sumRecouvrement = 0;
      vm.sumRecette = 0;
      vm.sumNbbl = 0;
      vm.sumNbVisite = 0;
      vm.sumNbavoir = 0;
      vm.nbClientVisite = 0;
      vm.totalNbrClientInRoute = 0;
      vm.nbClientNonVisite = 0;
      vm.sumTauxSuc = 0;
      vm.sumNbClient = 0;
      vm.sumnbclsbl = 0;
      for (var i = 0; i < vm.kpisglobal.length; i++) {
        vm.sumVente += Number(vm.kpisglobal[i].vente);
        vm.sumCredit += Number(vm.kpisglobal[i].credit);
        vm.sumCommande += Number(vm.kpisglobal[i].commande);
        vm.sumNbbl += Number(vm.kpisglobal[i].nbbl);
        vm.sumNbavoir += Number(vm.kpisglobal[i].nbavoir);
        vm.sumNbcommande += Number(vm.kpisglobal[i].nbcommande);
        vm.sumNbVisite += Number(vm.kpisglobal[i].nbVisite);
        //  vm.sumTauxSuc += Number(vm.kpisglobal[i].nbbl) * 100 / Number(vm.kpisglobal[i].nbVisite);
        vm.sumNbClient += Number(vm.kpisglobal[i].nbClient);
        vm.nbClientVisite += Number(vm.kpisglobal[i].nbClientVisite);
        vm.nbClientNonVisite += Number(vm.kpisglobal[i].nbClientNonVisite);
        vm.totalNbrClientInRoute += Number(vm.kpisglobal[i].totalNbrClientInRoute);
        vm.sumnbclsbl += Number(vm.kpisglobal[i].nbclsBl);
        vm.sumRecette += Number(vm.kpisglobal[i].recette);
        vm.sumRecouvrement += Number(vm.kpisglobal[i].recouvrement);



        // call pie
        vm.piekpis(vm.filter.year, vm.filter.month, vm.filter.day, vm.kpisglobal[i].code)
      }
      $scope.$apply();
    }, function (error) {
      console.log(error);
    });
  };

  vm.piekpis = function (year, month, day, user_code) {
    //year, month, day, user_code
    // if (vm.filter.day == undefined || vm.filter.day == '') {
    //     var day = 'null';
    // } else {
    //     var day = vm.filter.day;

    // }
    // var url = 'rapports/piekpisforuser/' + year + '/' + month + '/' + day + '/' + user_code;
    WS.getData('rapports/piekpisforuser', vm.filter)
      .then(function (response) {
        var chart;
        var legend;
        var selected;

        var types = response.data;

        console.log('types', types)
        function generateChartData() {
          var chartData = [];
          for (var i = 0; i < types.length; i++) {
            if (i == selected) {
              for (var x = 0; x < types[i].subs.length; x++) {
                chartData.push({
                  type: types[i].subs[x].type,
                  percent: types[i].subs[x].percent.toFixed(3),
                  color: types[i].color,
                  pulled: true
                });
              }
            } else {
              chartData.push({
                type: types[i].type,
                percent: types[i].percent.toFixed(3),
                color: types[i].color,
                id: i
              });
            }
          }
          return chartData;
        }
        var id = "piekpi-" + month + "-" + user_code;

        function getNumberSeparators() {

          // default
          var res = {
            "decimal": ".",
            "thousand": ""
          };

          // convert a number formatted according to locale
          var str = parseFloat(1234.56).toLocaleString();

          // if the resulting number does not contain previous number
          // (i.e. in some Arabic formats), return defaults
          if (!str.match("1"))
            return res;

          // get decimal and thousand separators
          res.decimal = str.replace(/.*4(.*)5.*/, "$1");
          res.thousand = str.replace(/.*1(.*)2.*/, "$1");

          // return results
          return res;
        }

        /**
         * Get locale settings
         */
        var separators = getNumberSeparators();
        AmCharts.makeChart(id, {
          "type": "pie",
          "theme": "light",
          "decimalSeparator": separators.decimal,
          "thousandsSeparator": separators.thousand,
          "dataProvider": generateChartData(),
          //"labelText": "[[title]]: [[value]]",
          //"balloonText": "[[title]]: [[value]]",
          "titleField": "type",
          "valueField": "percent",
          "outlineColor": "#FFFFFF",
          "outlineAlpha": 0.8,
          "outlineThickness": 2,
          "colorField": "color",
          "pulledField": "pulled",
          "listeners": [{
            "event": "clickSlice",
            "method": function (event) {
              var chart = event.chart;
              if (event.dataItem.dataContext.id != undefined) {
                selected = event.dataItem.dataContext.id;
              } else {
                selected = undefined;
              }
              chart.dataProvider = generateChartData();
              chart.validateData();
            }
          }],
          "innerRadius": "65%",
          "labelsEnabled": false,
          "autoMargins": false,
          "marginTop": 0,
          "marginBottom": 0,
          "marginLeft": 0,
          "marginRight": 0,
          "pullOutRadius": 0,
          "labelRadius": 0,
          "legend": {
            "position": "bottom",
            "marginRight": 0,
            "align": "left",
            "equalWidths": true,
            "valueWidth": 70,
            "horizontalGap": 5,
            "marginLeft": 0,
            "marginBottom": 0,
            "marginTop": 0,
            "autoMargins": false
          },
          "export": {
            "enabled": true
          }
        });
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.piekpisforAllusers = function () {
    // if (vm.filter.year != null && vm.filter.month != null) {
    //     var url = 'rapports/piekpisforAllusers/' + vm.filter.year + '/' + vm.filter.month;
    //     if (vm.filter.day) {
    //         url += `/${vm.filter.day}`;
    //     }
    // } else {
    //     var url = 'rapports/piekpisforAllusers';
    // }

    const data = {
      year: vm.filter.year,
      month: vm.filter.month,
      day: vm.filter.day,
      date_debut: vm.filter.date_debut,
      date_fin: vm.filter.date_fin,
      type_filter: vm.filter.type_filter,
    }

    WS.getData('rapports/piekpisforAllusers', data)
      .then(function (response) {
        var chart;
        var legend;
        var selected;

        var types = response.data;

        function generateChartData() {
          var chartData = [];
          for (var i = 0; i < types.length; i++) {
            if (i == selected) {
              for (var x = 0; x < types[i].subs.length; x++) {
                chartData.push({
                  type: types[i].subs[x].type,
                  percent: types[i].subs[x].percent.toFixed(3),
                  color: types[i].color,
                  pulled: true
                });
              }
            } else {
              chartData.push({
                type: types[i].type,
                percent: types[i].percent.toFixed(3),
                color: types[i].color,
                id: i
              });
            }
          }
          return chartData;
        }
        var id = "piekpisforAllusers";

        function getNumberSeparators() {

          // default
          var res = {
            "decimal": ".",
            "thousand": ""
          };

          // convert a number formatted according to locale
          var str = parseFloat(1234.56).toLocaleString();

          // if the resulting number does not contain previous number
          // (i.e. in some Arabic formats), return defaults
          if (!str.match("1"))
            return res;

          // get decimal and thousand separators
          res.decimal = str.replace(/.*4(.*)5.*/, "$1");
          res.thousand = str.replace(/.*1(.*)2.*/, "$1");

          // return results
          return res;
        }

        /**
         * Get locale settings
         */
        var separators = getNumberSeparators();
        AmCharts.makeChart(id, {
          "type": "pie",
          "theme": "light",
          "decimalSeparator": separators.decimal,
          "thousandsSeparator": separators.thousand,
          "dataProvider": generateChartData(),
          //"labelText": "[[title]]: [[value]]",
          //"balloonText": "[[title]]: [[value]]",
          "titleField": "type",
          "valueField": "percent",
          "outlineColor": "#FFFFFF",
          "outlineAlpha": 0.8,
          "outlineThickness": 2,
          "colorField": "color",
          "pulledField": "pulled",
          "listeners": [{
            "event": "clickSlice",
            "method": function (event) {
              var chart = event.chart;
              if (event.dataItem.dataContext.id != undefined) {
                selected = event.dataItem.dataContext.id;
              } else {
                selected = undefined;
              }
              chart.dataProvider = generateChartData();
              chart.validateData();
            }
          }],
          "innerRadius": "65%",
          "labelsEnabled": false,
          "autoMargins": false,
          "marginTop": 0,
          "marginBottom": 0,
          "marginLeft": 0,
          "marginRight": 0,
          "pullOutRadius": 0,
          "labelRadius": 0,
          "legend": {
            "position": "bottom",
            "marginRight": 0,
            "align": "left",
            "equalWidths": true,
            "valueWidth": 70,
            "horizontalGap": 5,
            "marginLeft": 0,
            "marginBottom": 0,
            "marginTop": 0,
            "autoMargins": false
          },
          "export": {
            "enabled": true
          }
        });
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  /**
   * fin Rapport commercial par mois
   */


  vm.exportTableHtmlR47 = function (tableId, title = '') {



    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;
    title += vm.search47.user_code + '-' + vm.search47.type + '-' + vm.search47.rows + '_||_' + vm.search47.date_debut + '__' + vm.search47.date_fin;

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);


  };

  vm.exportTableHtmlR23 = function (tableId, title = 'Rapport', date_debut = '', date_fin = '', commercial = '', route = '', gouvernorat = '') {



    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;

    download_link.download = title + '__' + date_debut + '__' + date_fin + '__' + commercial + '__' + route + '__' + gouvernorat + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);


  };
  vm.exportTableHtml = function (tableId, title = 'Rapport') {

    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    var download_link = document.createElement('a');
    download_link.href = href;

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);


  };

  vm.export_rapport_03 = function (tableId, title = 'Rapport_N3_') {



    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;
    //var title = "Rapport DN par Produit-";
    title += vm.filter.year + '-' + vm.filter.month + '-' + vm.filter.day + '_||_' + vm.filter.user_code;

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);



  };

  vm.export_rapport_13_14 = function (tableId, title = 'Rapport') {



    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;
    //var title = "Rapport DN par Produit-";
    // title += vm.filter.year + '-' + vm.filter.month + '-' + vm.filter.day + '_||_' + vm.filter.user_code;

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);



  };

  vm.analyseTournee = function (date = null) {
    var url = (date == null) ? 'entetecommercial/calcul/analyseTournee' : 'entetecommercial/calcul/analyseTournee/' + date;
    WS.get(url)
      .then(function (response) {
        vm.analysetournees = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.tauxTournee = function (date = null) {
    WS.get('entetecommercial/calcul/tauxTournee/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.tauxtournees = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  // vm.traitementTourne = function (user_code, tauxtournees) {
  //     if (!tauxtournees) return;
  //     for (var i = 0; i < tauxtournees.length; i++) {
  //         if (tauxtournees[i].route.user_code == user_code) {
  //             if (tauxtournees[i].tournees.length > 0) {
  //                 return tauxtournees[i].tournees[0].visite * 100 / tauxtournees[i].tournees[0].clients;
  //             }
  //         }
  //     }
  //     return 0;
  // };

  vm.traitementTourne = function (user_code, tauxtournees) {
    if (!tauxtournees) return 0;
    for (var i = 0; i < tauxtournees.length; i++) {
      if (tauxtournees[i].route.user_code == user_code) {
        if (tauxtournees[i].tournees.length > 0) {
          return tauxtournees[i].tournees[0].visite * 100 / tauxtournees[i].tournees[0].clients;
        } else {
          return 0;
        }
      }
    }
  };


  vm.getAnalyseFamilleParGamme = function (date_debut, date_fin, code) {
    WS.get('rapports/analyseFamilleParGamme/' + date_debut + '/' + date_fin + '/' + code)
      .then(function (response) {
        if (response.status == 200) {
          vm.analyseFamilleParGamme = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.exportrapportparperiode = function (date_debut, date_fin, type, code, nature) {
    WS.get('rapports/exportRapportPeriodique' + '/' + date_debut + '/' + date_fin + '/' + type + '/' + code + '/' + nature).then(
      function (response) {
        var count = response.data.length;

        //var filename = 'Rapport-Tournee-'+moment().format("DD-MM-YYYY_HH:mm:ss");
        var filename = 'Rapport-Periodique-' + moment().format("DD-MM-YYYY HH:mm:ss");

        var file = {
          sheetid: 'Rapport-Periodique-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          caption: {
            title: 'Rapport-Periodique-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'cashvan', title: 'Commercial' },
            { columnid: 'client', title: 'Client' },
            { columnid: 'adresse_facturation', title: 'Adresse' },
            { columnid: 'code', title: 'code ' },
            { columnid: 'code_a_barre', title: 'code_a_barre' },
            { columnid: 'date_visite', title: 'Date' },
            { columnid: 'type', title: 'type' },
            { columnid: 'visite_code', title: 'Code Visite' },
            { columnid: 'vente', title: type }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };

        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  vm.filterfieldnames = function (choix, fieldvalue) {
    //if(fieldvalue == undefined) return;
    if (fieldvalue == undefined) {
      switch (choix) {
        case 'marque':
          vm.getMarque();
          break;
        case 'fournisseur':
          vm.getFournisseurs();
          break;
        case 'gamme':
          vm.getGammes();
          break;
        case 'famille':
          vm.getFamilles();
          break;
        case 'sousfamille':
          vm.getSousfamilles();
          break;
        case 'produit':
          vm.getAllproduits();
          break;
      }

      return;
    }
    WS.get('produits/selectProdByChoix/' + choix + '_code/' + fieldvalue).then(
      (response) => {
        vm[choix + 's'] = response.data;
        $scope.$apply();
      },
      (error) => {
        console.log(error);
      })
  }


  vm.filterVenteCommercial = function (fc_date_debut, fc_date_fin, type) {

    WS.get('rapports/filterVenteCommercial/' + fc_date_debut + "/" + fc_date_fin + "/" + type)
      .then(function (response) {
        if (response.status == 200) {
          vm.fc_vente_commercial = response.data;
          vm.totalvente = response.data.totalvente;
          vm.totalventeCommercial = response.data.totalventeParComm;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.filterSuiviCommercial = function (fc_user_code, fc_date_debut, fc_date_fin, type) {
    WS.get('rapports/filterSuiviCommercial/' + fc_user_code + "/" + fc_date_debut + "/" + fc_date_fin + "/" + type)
      .then(function (response) {
        if (response.status == 200) {
          vm.fc_suivi_commercial = response.data.entete;
          vm.totvente = response.data.totvente;
          vm.totvisite = response.data.totvisite;
          vm.totnbr_bl = response.data.totnbr_bl;
          vm.totnbr_bl_paye = response.data.totnbr_bl_paye;
          vm.totnbr_bl_impaye = response.data.totnbr_bl_impaye;
          vm.totnbr_avoir = response.data.totnbr_avoir;
          vm.totespece_cheque = response.data.totespece_cheque;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.filterSuiviProspect = function (fc_user_code, fc_date_debut, fc_date_fin) {
    const data = {
      date_debut: fc_date_debut,
      date_fin: fc_date_fin,
      user_code: fc_user_code,
    }
    WS.getData('rapports/filterSuiviProspect', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.suivi_prospects = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  // vm.getListPrimeCacheTerme = function (fc_user_code, fc_date_debut, fc_date_fin) {
  //     vm.loading_request = true;
  //     const data = {
  //         date_debut: fc_date_debut,
  //         date_fin: fc_date_fin,
  //         user_code: fc_user_code,
  //     }
  //     WS.getData('rapports/getListPrimeCacheTerme', data)
  //         .then(function (response) {
  //             if (response.status == 200) {
  //                 vm.paiements = response.data.paiements;
  //                 vm.primes = response.data.lignes;
  //                 console.log(vm.paiements, vm.primes)
  //                 vm.tbodys = ``

  //                 vm.total_users = {};
  //                 vm.total_users_familles = {};
  //                 _.each(vm.primes, (user, commercial_code) => {


  //                     vm.total_users[commercial_code] = _.groupBy(user, 'famille_code')

  //                     _.each(vm.total_users[commercial_code], (prods, famille_code) => {
  //                         vm.total_users[commercial_code][famille_code] = _.groupBy(prods, 'produit_code')

  //                         _.each(vm.total_users[commercial_code][famille_code], (prod, indexProduit) => {

  //                             var total_quantite = 0;
  //                             var total_poids = 0;
  //                             var total_prime = 0;
  //                             var famille = '';
  //                             var produit_code = '';
  //                             var produit = '';
  //                             var commercial = '';

  //                             _.each(prod, (ligne, indLigne) => {
  //                                 famille = ligne.famille;

  //                                 produit_code = ligne.produit_code;
  //                                 produit = ligne.produit;

  //                                 commercial = ligne.commercial;

  //                                 total_quantite += ligne.quantite;
  //                                 total_poids += ligne.poids;
  //                                 if (vm.paiements[ligne.code]) {
  //                                     total_prime += vm.paiements[ligne.code].credit > 0 ? ligne.quantite * ligne.prime_terme : ligne.quantite * ligne.prime_cache;
  //                                 }
  //                             })

  //                             vm.total_users[commercial_code][famille_code][indexProduit] = {
  //                                 total_quantite,
  //                                 total_poids,
  //                                 total_prime,
  //                                 famille,
  //                                 famille_code,
  //                                 produit_code,
  //                                 produit,
  //                                 commercial_code,
  //                                 commercial
  //                             }


  //                         })


  //                     })

  //                 })
  //                 console.log(vm.total_users)
  //                 /**
  //                  * get total_users
  //                  */
  //                 _.each(vm.total_users, (user, indexUser) => {
  //                     var commercial_code = '';
  //                     var commercial = '';
  //                     var total_users_quantite = 0;
  //                     var total_users_poids = 0;
  //                     var total_users_prime = 0;
  //                     _.each(user, (famille, indexfamille) => {

  //                         commercial_code = '';
  //                         commercial = '';
  //                         var famille_name = '';
  //                         var total_famille_quantite = 0;
  //                         var total_famille_poids = 0;
  //                         var total_famille_prime = 0;
  //                         _.each(famille, (prods, indexProduit) => {

  //                             commercial_code = prods.commercial_code;
  //                             commercial = prods.commercial;
  //                             famille_name = prods.famille;
  //                             //total familles
  //                             total_famille_quantite += prods.total_quantite;
  //                             total_famille_poids += prods.total_poids;
  //                             total_famille_prime += prods.total_prime;

  //                             // total users
  //                             total_users_quantite += prods.total_quantite;
  //                             total_users_poids += prods.total_poids;
  //                             total_users_prime += prods.total_prime;

  //                             vm.tbodys += `<tr>
  //                                 <td class="text-center">${prods.commercial_code}</td>
  //                                 <td class="text-center">${prods.commercial}</td>
  //                                 <td class="text-center">${prods.famille}</td>
  //                                 <td class="text-center">${prods.produit_code}</td>
  //                                 <td class="text-center">${prods.produit}</td>
  //                                 <td class="text-center">${prods.total_quantite}</td>
  //                                 <td class="text-center">${prods.total_poids}</td>
  //                                 <td class="text-center">${prods.total_prime}</td>
  //                                 </tr>`;
  //                         })

  //                         /**
  //                          * total familles
  //                          */
  //                         vm.tbodys += `<tr>
  //                         <td class="text-center"><b>${commercial_code}</b></td>
  //                         <td class="text-center"><b>${commercial}</b></td>
  //                         <td class="text-center succ-blue"><b>Total : ${famille_name}</b></td>
  //                         <td class="text-center succ-blue"></td>
  //                         <td class="text-center succ-blue"></td>
  //                         <td class="text-center succ-blue"><b>${total_famille_quantite}</b></td>
  //                         <td class="text-center succ-blue"><b>${total_famille_poids}</b></td>
  //                         <td class="text-center succ-blue"><b>${total_famille_prime}</b></td>
  //                         </tr>`;
  //                     })

  //                     /**
  //                      * total commercials
  //                      */
  //                     vm.tbodys += `<tr>
  //                     <td class="text-center bg-yellow" colspan="2"><b>Total</b></td>
  //                     <td class="text-center bg-yellow"></td>
  //                     <td class="text-center bg-yellow"></td>
  //                     <td class="text-center bg-yellow"></td>
  //                     <td class="text-center bg-yellow"><b>${total_users_quantite}</b></td>
  //                     <td class="text-center bg-yellow"><b>${total_users_poids}</b></td>
  //                     <td class="text-center bg-yellow"><b>${total_users_prime}</b></td>
  //                     </tr>`;

  //                 })
  //                 vm.loading_request = false;

  //                 vm.tbodys = vm.trustHtml(vm.tbodys);
  //                 $scope.$apply();
  //             } else { }
  //             vm.loading_request = false;

  //         })
  //         .then(null, function (error) {
  //             vm.loading_request = false;

  //             console.log(error);
  //         });
  // };


  vm.getListPrimeCacheTerme = function (fc_user_code, fc_date_debut, fc_date_fin, fc_type = 'paiements') {
    vm.loading_request = true;
    const data = {
      date_debut: fc_date_debut,
      date_fin: fc_date_fin,
      user_code: fc_user_code,
      type: fc_type
    }
    WS.getData('rapports/getListPrimeCacheTerme', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.paiements = response.data.paiements;
          vm.primes = response.data.lignes;
          console.log(vm.paiements, vm.primes)
          vm.tbodys = ``

          vm.total_users = {};
          vm.total_users_familles = {};
          _.each(vm.primes, (user, commercial_code) => {


            vm.total_users[commercial_code] = _.groupBy(user, 'famille_code')

            _.each(vm.total_users[commercial_code], (prods, famille_code) => {
              vm.total_users[commercial_code][famille_code] = _.groupBy(prods, 'produit_code')

              _.each(vm.total_users[commercial_code][famille_code], (prod, indexProduit) => {

                var total_quantite_cache = 0;
                var total_quantite_terme = 0;
                var total_poids_cache = 0;
                var total_poids_terme = 0;
                var total_prime_cache = 0;
                var total_prime_terme = 0;
                var famille = '';
                var produit_code = '';
                var produit = '';
                var commercial = '';

                _.each(prod, (ligne, indLigne) => {
                  famille = ligne.famille;

                  produit_code = ligne.produit_code;
                  produit = ligne.produit;

                  commercial = ligne.commercial;

                  //console.log('------------->----------->', 'ligne', ligne)
                  if (fc_type == 'specialite') {
                    if (ligne.cacheTerme == 'terme') {
                      total_quantite_terme += ligne.quantite;
                      total_poids_terme += ligne.poids;
                      total_prime_terme += ligne.quantite * ligne.prime_terme;
                    } else {
                      total_quantite_cache += ligne.quantite;
                      total_poids_cache += ligne.poids;
                      total_prime_cache += ligne.quantite * ligne.prime_cache
                    }
                  } else {
                    if (vm.paiements[ligne.code]) {
                      if (vm.paiements[ligne.code].credit > 0) {
                        total_quantite_terme += ligne.quantite;
                        total_poids_terme += ligne.poids;
                        total_prime_terme += ligne.quantite * ligne.prime_terme;
                      } else {
                        total_quantite_cache += ligne.quantite;
                        total_poids_cache += ligne.poids;
                        total_prime_cache += ligne.quantite * ligne.prime_cache
                      }
                    }
                  }

                })

                vm.total_users[commercial_code][famille_code][indexProduit] = {
                  total_quantite_cache, total_quantite_terme,
                  total_poids_terme, total_poids_cache,
                  total_prime_terme, total_prime_cache,
                  famille,
                  famille_code,
                  produit_code,
                  produit,
                  commercial_code,
                  commercial
                }


              })


            })

          })
          console.log(vm.total_users)
          /**
           * get total_users
           */
          _.each(vm.total_users, (user, indexUser) => {
            var commercial_code = '';
            var commercial = '';
            var total_users_quantite_cache = 0;
            var total_users_quantite_terme = 0;
            var total_users_poids_cache = 0;
            var total_users_poids_terme = 0;
            var total_users_prime_cache = 0;
            var total_users_prime_terme = 0;

            _.each(user, (famille, indexfamille) => {

              commercial_code = '';
              commercial = '';
              var famille_name = '';
              var total_famille_quantite_cache = 0;
              var total_famille_quantite_terme = 0;
              var total_famille_poids_cache = 0;
              var total_famille_poids_terme = 0;
              var total_famille_prime_cache = 0;
              var total_famille_prime_terme = 0;

              _.each(famille, (prods, indexProduit) => {

                commercial_code = prods.commercial_code;
                commercial = prods.commercial;
                famille_name = prods.famille;
                //total familles
                total_famille_quantite_cache += prods.total_quantite_cache;
                total_famille_quantite_terme += prods.total_quantite_terme;
                total_famille_poids_cache += prods.total_poids_cache;
                total_famille_poids_terme += prods.total_poids_terme;
                total_famille_prime_cache += prods.total_prime_cache;
                total_famille_prime_terme += prods.total_prime_terme;

                // total users
                total_users_quantite_cache += prods.total_quantite_cache;
                total_users_quantite_terme += prods.total_quantite_terme;
                total_users_poids_cache += prods.total_poids_cache;
                total_users_poids_terme += prods.total_poids_terme;
                total_users_prime_cache += prods.total_prime_cache;
                total_users_prime_terme += prods.total_prime_terme;

                vm.tbodys += `<tr>
                                    <td class="text-center">${prods.commercial_code}</td>
                                    <td class="text-center">${prods.commercial}</td>
                                    <td class="text-center">${prods.famille}</td>
                                    <td class="text-center">${prods.produit_code}</td>
                                    <td class="text-center">${prods.produit}</td>
                                    <td class="text-center">${prods.total_quantite_cache}</td>
                                    <td class="text-center">${prods.total_quantite_terme}</td>
                                    <td class="text-center">${prods.total_poids_cache}</td>
                                    <td class="text-center">${prods.total_poids_terme}</td>
                                    <td class="text-center">${prods.total_prime_cache}</td>
                                    <td class="text-center">${prods.total_prime_terme}</td>
                                    </tr>`;
              })

              /**
               * total familles
               */
              vm.tbodys += `<tr>
                            <td class="text-center"><b>${commercial_code}</b></td>
                            <td class="text-center"><b>${commercial}</b></td>
                            <td class="text-center succ-blue"><b>Total : ${famille_name}</b></td>
                            <td class="text-center succ-blue"></td>
                            <td class="text-center succ-blue"></td>
                            <td class="text-center succ-blue"><b>${total_famille_quantite_cache}</b></td>
                            <td class="text-center succ-blue"><b>${total_famille_quantite_terme}</b></td>
                            <td class="text-center succ-blue"><b>${total_famille_poids_cache}</b></td>
                            <td class="text-center succ-blue"><b>${total_famille_poids_terme}</b></td>
                            <td class="text-center succ-blue"><b>${total_famille_prime_cache}</b></td>
                            <td class="text-center succ-blue"><b>${total_famille_prime_terme}</b></td>
                            </tr>`;
            })

            /**
             * total commercials
             */
            vm.tbodys += `<tr>
                        <td class="text-center bg-yellow" colspan="2"><b>Total</b></td>
                        <td class="text-center bg-yellow"></td>
                        <td class="text-center bg-yellow"></td>
                        <td class="text-center bg-yellow"></td>
                        <td class="text-center bg-yellow"><b>${total_users_quantite_cache}</b></td>
                        <td class="text-center bg-yellow"><b>${total_users_quantite_terme}</b></td>
                        <td class="text-center bg-yellow"><b>${total_users_poids_cache}</b></td>
                        <td class="text-center bg-yellow"><b>${total_users_poids_terme}</b></td>
                        <td class="text-center bg-yellow"><b>${total_users_prime_cache}</b></td>
                        <td class="text-center bg-yellow"><b>${total_users_prime_terme}</b></td>
                        </tr>`;

          })
          vm.loading_request = false;

          vm.tbodys = vm.trustHtml(vm.tbodys);
          $scope.$apply();
        } else { }
        vm.loading_request = false;

      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
      });
  };

  /**
   * export to csv
   */


  vm.table2CSV = function (tableId) {
    $(tableId).tableToCSV();
  };

  vm.stat_chargements = (date_debut = null, date_fin = null, commercial = null, colisage = 'colis_non') => {
    WS.get('rapports/stat_chargements/' + date_debut + "/" + date_fin + "/" + commercial + "/" + colisage)
      .then(function (response) {
        if (response.status == 200) {
          vm.chargements_stats = response.data;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getChargementByUsers = () => {
    WS.getData('rapports/getChargementByUsers', {
      date_debut: vm.date,
      date_fin: vm.date_fin,
    })
      .then(function (response) {
        if (response.status == 200) {
          vm.list_chargements = response.data;
          console.log('vm.list_chargements', vm.list_chargements)
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.totalventeBlVisite = (date_debut = null, date_fin = null, commercial = null, type_entete = 'bl') => {
    WS.get('rapports/totalventeBlVisite/' + date_debut + "/" + date_fin + "/" + commercial + "/" + type_entete)
      .then(function (response) {
        if (response.status == 200) {
          vm.totalventeblvisite = response.data;
          //console.log('totalvente', vm.totalventeblvisite);
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.top10Vente = (date_debut = null, date_fin = null, commercial = null, type = null, type_entete = 'bl') => {
    vm.typeoftop = (type) ? type : 'g';
    WS.get('rapports/top10Vente/' + date_debut + "/" + date_fin + "/" + commercial + "/" + type + "/" + type_entete)
      .then(function (response) {
        if (response.status == 200) {
          vm.top10vente = response.data;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.listClientByType = (date_debut = null, date_fin = null, commercial = null, type = null) => {
    var type = (type) ? type : 'g';
    var data = {
      date_debut,
      date_fin,
      commercial,
      type,
      activite_codes: vm.clsblbytype_activite_codes,
      categorie_codes: vm.clsblbytype_categorie_codes
    };
    //console.log(data);
    WS.post('rapports/listClientByType', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.listClientsByType = response.data.cls;
          vm.th_types = response.data.th_types;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getetatcreditByCommercial = function (date_debut = null, date_fin = null, commercial = null, route = null, gouvernorat = null) {
    var url = 'rapports/getCreditByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + route + "/" + gouvernorat;

    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.etatcreditByCommercial = response.data;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.exportetatcreditByCommercial = function (date_debut = null, date_fin = null, commercial = null, route = null, gouvernorat = null) {
    var url = 'rapports/getCreditByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + route + "/" + gouvernorat;

    WS.get(url).then(function (response) {
      var filename = "Credit By commercial-" + date_debut + '-' + date_fin;

      var file = {
        sheetid: "Credit By commercial-" + date_debut + '-' + date_fin,
        headers: true,
        caption: {
          title: "Credit By commercial-" + date_debut + '-' + date_fin,
          style: 'font-size: 50px; color:blue;'
        },
        column: {
          style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
          { columnid: 'code', title: 'Code' },
          { columnid: 'code_a_barre', title: 'Code Barre' },
          { columnid: 'nom', title: 'Nom' },
          { columnid: 'prenom', title: 'Prenom' },
          { columnid: 'routing_code', title: 'Routing' },
          { columnid: 'adresse_facturation', title: 'Adresse' },
          { columnid: 'zone', title: 'Zone' },
          { columnid: 'mobile', title: 'Mobile' },
          { columnid: 'date_visite', title: 'Date Visite' },
          { columnid: 'commentaire', title: 'Commentaire' },
          { columnid: 'date_credit', title: 'Date Credit' },
          { columnid: 'encours', title: 'Credit' },
        ],
        row: {
          style: function (sheet, row, rowidx) {
            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
          }
        }
      };
      alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
    }, function (error) {
      console.log(error);
    });
  };


  vm.getlistcreditByCommercial = function (date_debut = null, date_fin = null, commercial = null, gouvernorat = null) {
    var url = 'rapports/getListCreditByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + gouvernorat;

    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.listcreditByCommercial = response.data;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.exportlistcreditByCommercial = function (date_debut = null, date_fin = null, commercial = null, gouvernorat = null) {
    var url = 'rapports/getListCreditByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + gouvernorat;
    WS.get(url).then(function (response) {
      var filename = "List Credit By commercial-" + date_debut + '-' + date_fin;

      var file = {
        sheetid: " List Credit By commercial-" + date_debut + '-' + date_fin,
        headers: true,
        caption: {
          title: "Credit By commercial-" + date_debut + '-' + date_fin,
          style: 'font-size: 50px; color:blue;'
        },
        column: {
          style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
          { columnid: 'code', title: 'Code' },
          { columnid: 'code_a_barre', title: 'Code Barre' },
          { columnid: 'nom', title: 'Nom' },
          { columnid: 'prenom', title: 'Prenom' },
          { columnid: 'routing_code', title: 'Routing' },
          { columnid: 'adresse_facturation', title: 'Adresse' },
          { columnid: 'mobile', title: 'Mobile' },
          { columnid: 'user', title: 'Commercial' },
          { columnid: 'code_bl', title: 'BL ' },
          { columnid: 'montant', title: 'Montant' },
          { columnid: 'credit', title: 'Credit' },
          { columnid: 'date', title: 'Date ' },
          { columnid: 'date_echeance_credit', title: 'Date Credit' },
        ],
        row: {
          style: function (sheet, row, rowidx) {
            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
          }
        }
      };
      alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
    }, function (error) {
      console.log(error);
    });
  };








  vm.exportreglementByCommercial = function (date_debut, date_fin, commercial = null, cloture = '0') {
    var url = 'rapports/getAllRegelementByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + cloture;
    WS.getData(url).then(function (response) {
      var filename = "Reglement By commercial-" + date_debut + '-' + date_fin;

      var file = {
        sheetid: "Reglement By commercial-" + date_debut + '-' + date_fin,
        headers: true,
        /* caption: {
            title: "Reglement By commercial-" + date_debut + '-' + date_fin,
            style: 'font-size: 50px; color:blue;'
        }, */
        column: {
          style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
          { columnid: 'code', title: 'Code' },
          { columnid: 'code_a_barre', title: 'Code Barre' },
          { columnid: 'client', title: 'Client' },
          { columnid: 'adresse_facturation', title: 'Adresse' },
          { columnid: 'commercial', title: 'Commercial' },
          { columnid: 'montant', title: 'Montant' },
          { columnid: 'espece', title: 'Espece' },
          { columnid: 'cheque', title: 'Cheque' },
          { columnid: 'traite', title: 'Traite' },
          { columnid: 'credit', title: 'Credit' },
          { columnid: 'rs', title: 'Retenue' },
          { columnid: 'montant_avoir', title: 'Avoir' },
          { columnid: 'recouvrement', title: 'Recouvrement' }
        ],
        row: {
          style: function (sheet, row, rowidx) {
            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
          }
        }
      };
      alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [file, response.data]);
    }, function (error) {
      console.log(error);
    });
  };





  vm.getgratuiteByCommercial = function (date_debut, date_fin, commercial = null) {
    var url = 'rapports/getgratuiteByCommercial/' + date_debut + "/" + date_fin + "/" + commercial;
    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.gratuiteByCommercial = response.data;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getMargeparVendeur = function (date_debut, date_fin, commercial) {
    var url = 'rapports/getMargeParVendeur/' + date_debut + "/" + date_fin + "/" + commercial;
    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.margeparvendeur = response.data;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.exportMargeparVendeur = function (date_debut, date_fin, commercial) {
    var url = 'rapports/getMargeParVendeur/' + date_debut + "/" + date_fin + "/" + commercial;
    WS.get(url).then(function (response) {
      var filename = "Marge par commercial-" + commercial + '-' + date_debut + '-' + date_fin;

      var file = {
        sheetid: "Marge par commercial-" + commercial + '-' + date_debut + '-' + date_fin,
        headers: true,
        caption: {
          title: "Marge par commercial-" + commercial + '-' + date_debut + '-' + date_fin,
          style: 'font-size: 50px; color:blue;'
        },
        column: {
          style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
          { columnid: 'code', title: 'Code' },
          { columnid: 'libelle', title: 'Produit' },
          { columnid: 'user_code', title: 'Commercial code' },
          { columnid: 'vente_net', title: 'Vente net' },
          { columnid: 'vente_net_caisse', title: 'Vnete net / caisse' },
          { columnid: 'commission', title: 'Commission' },
          { columnid: 'marge', title: 'Marge' },
        ],
        row: {
          style: function (sheet, row, rowidx) {
            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
          }
        }
      };
      alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
    }, function (error) {
      console.log(error);
    });
  };

  vm.getvisiteByCommercial = function (date_debut, date_fin, commercial = null) {
    var url = 'rapports/getAllVisiteByCommercial/' + date_debut + "/" + date_fin + "/" + commercial;
    WS.get(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.visiteByCommercial = response.data;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.exportvisiteByCommercial = function (date_debut, date_fin, commercial = null) {
    var url = 'rapports/getAllVisiteByCommercial/' + date_debut + "/" + date_fin + "/" + commercial;
    WS.get(url).then(function (response) {
      var filename = "Visite By commercial-" + date_debut + '-' + date_fin;

      var file = {
        sheetid: "Visite By commercial-" + date_debut + '-' + date_fin,
        headers: true,
        caption: {
          title: "Visite By commercial-" + date_debut + '-' + date_fin,
          style: 'font-size: 50px; color:blue;'
        },
        column: {
          style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
          { columnid: 'code', title: 'Code' },
          { columnid: 'code_a_barre', title: 'Code Barre' },
          { columnid: 'client', title: 'Client' },
          { columnid: 'adresse_facturation', title: 'Adresse' },
          { columnid: 'commercial', title: 'Commercial' },
          { columnid: 'cause', title: 'Cause' },
          { columnid: 'commentaire', title: 'Commentaire' },
          { columnid: 'date_visite', title: 'Dernier visite' },
          { columnid: 'date_prochaineVisite', title: 'Prochaine visite' }
        ],
        row: {
          style: function (sheet, row, rowidx) {
            return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
          }
        }
      };
      alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
    }, function (error) {
      console.log(error);
    });
  };



  vm.exportsuiviCommercialClient = function (suivi_cls) {
    WS.getData('rapports/suiviCommercialClient', suivi_cls)
      .then(response => {
        var filename = 'Suivi Commercial Client-' + moment().format("DD-MM-YYYY_HH:mm:ss");

        var file = {
          sheetid: 'Suivi Commercial Client--' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          caption: {
            title: 'Suivi Commercial Client--' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'code', title: 'Code' },
            { columnid: 'client', title: 'Client' },
            { columnid: 'magasin', title: 'Magasin' },
            { columnid: 'adresse_facturation', title: 'Adresse' },
            { columnid: 'mobile', title: 'Mobile' },
            { columnid: 'routing_code', title: 'Routing' },
            { columnid: 'categorie', title: 'Categorie' },
            { columnid: 'gouvernorate', title: 'Gouvernorat' },
            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'nbr_bl', title: 'NB/BL' },
            { columnid: 'chiffre_net_bl', title: ' CA  BL' },
            { columnid: 'nbr_commande', title: 'NB/CMD ' },
            { columnid: 'chiffre_net_cmd', title: 'CA Commande' },
            { columnid: 'nbr_visite', title: 'NB/Visite' },
            { columnid: 'nbr_avoir', title: 'NB/Avoir' },
            { columnid: 'nbr_recouvrement', title: 'NB/Recouvrement' },
            { columnid: 'total_visite', title: 'Total Visite' },
            { columnid: 'last_visite', title: 'Date Dernier Visite' },
            { columnid: 'encours', title: 'Encours' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
        function (error) {
          console.log(error);
        }
      );
  };


  vm.pieGammeFamilleByZone = (date_debut, date_fin, zone_code) => {
    var user_code = null;
    var url = `rapports/pieGammeFamilleByZone/${date_debut}/${date_fin}/${zone_code}`;
    WS.getScache(url)
      .then(function (response) {
        var chart;
        var legend;
        var selected;

        var types = response.data;

        function generateChartData() {
          var chartData = [];
          for (var i = 0; i < types.length; i++) {
            if (i == selected) {
              for (var x = 0; x < types[i].subs.length; x++) {
                chartData.push({
                  type: types[i].subs[x].type,
                  percent: types[i].subs[x].percent.toFixed(3),
                  color: types[i].color,
                  pulled: true
                });
              }
            } else {
              chartData.push({
                type: types[i].type,
                percent: types[i].percent.toFixed(3),
                color: types[i].color,
                id: i
              });
            }
          }
          vm.chartdatalengthHeight = chartData.length;
          $scope.$apply();
          return chartData;
        }

        function getNumberSeparators() {

          // default
          var res = {
            "decimal": ".",
            "thousand": ""
          };

          // convert a number formatted according to locale
          var str = parseFloat(1234.56).toLocaleString();

          // if the resulting number does not contain previous number
          // (i.e. in some Arabic formats), return defaults
          if (!str.match("1"))
            return res;

          // get decimal and thousand separators
          res.decimal = str.replace(/.*4(.*)5.*/, "$1");
          res.thousand = str.replace(/.*1(.*)2.*/, "$1");

          // return results
          return res;
        }

        /**
         * Get locale settings
         */
        var separators = getNumberSeparators();
        AmCharts.makeChart('pieGammeFamilleByZone', {
          "type": "pie",
          "theme": "light",
          "decimalSeparator": separators.decimal,
          "thousandsSeparator": separators.thousand,
          "dataProvider": generateChartData(),
          //"labelText": "[[title]]: [[value]]",
          //"balloonText": "[[title]]: [[value]]",
          "titleField": "type",
          "valueField": "percent",
          "outlineColor": "#FFFFFF",
          "outlineAlpha": 0.8,
          "outlineThickness": 2,
          "colorField": "color",
          "pulledField": "pulled",
          "listeners": [{
            "event": "clickSlice",
            "method": function (event) {
              var chart = event.chart;
              if (event.dataItem.dataContext.id != undefined) {
                selected = event.dataItem.dataContext.id;
              } else {
                selected = undefined;
              }
              chart.dataProvider = generateChartData();
              chart.validateData();
            }
          }],
          "innerRadius": "65%",
          "labelsEnabled": false,
          "autoMargins": false,
          "marginTop": 0,
          "marginBottom": 0,
          "marginLeft": 0,
          "marginRight": 0,
          "pullOutRadius": 0,
          "labelRadius": 0,
          "legend": {
            "position": "bottom",
            "marginRight": 0,
            "align": "left",
            "equalWidths": true,
            "valueWidth": 70,
            "horizontalGap": 5,
            "marginLeft": 0,
            "marginBottom": 0,
            "marginTop": 0,
            "autoMargins": false
          },
          "export": {
            "enabled": true
          }
        });
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.month = moment().format('YYYY-MM');

  vm.kpi_dd = function () {
    _.mixin({
      capitalize: function (string) {
        return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
      }
    });
    var monthname = moment(vm.month).format('MMMM');
    vm.monthname = _(monthname).capitalize();
    vm.yearname = moment(vm.month).format('YYYY');
    vm.startOfMonth = moment(vm.month).startOf('month').format('YYYY-MM-DD');
    vm.endOfMonth = moment(vm.month).endOf('month').format('DD');
    vm.alldays = [];
    var countOutDim = 0;
    for (var i = 1; i <= Number(vm.endOfMonth); i++) {
      i = (i <= 9) ? '0' + i : i;
      vm.alldays.push({
        day: i,
        name: moment(vm.month + '-' + i).format('ddd')
      });
      if (moment(vm.month + '-' + i).format('dddd') != 'dimanche') {
        countOutDim++;
      }

    }
    vm.countOutDim = countOutDim;

    $(".datepicker-dashb").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.month = date;
        $scope.$apply();
        //console.log(vm.month);
      }
    });

    //console.log(vm.month, vm.kpi_commercial);
  };

  vm.kpi_objectifs = function () {
    WS.getScache('rapports/ObejectifsByCommercialsMonth' + `?commercial=${vm.kpi_commercial}&type_depot=${vm.kpi_type_depot}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
      vm.tableobjectifs = [];
      response.data.forEach(function (element, index) {
        var date_debut = element.date_debut;
        var date_fin = element.date_fin;
        var days_objs = [];
        var nbr_jour_travaille = 0;
        for (var i = 1; i <= Number(vm.endOfMonth); i++) {
          i = (i <= 9) ? '0' + i : i;
          var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
          var day_obj = moment(vm.month + '-' + i).format('dddd');
          if (
            (compareDate.isBetween(element.date_debut, element.date_fin) ||
              compareDate.isSame(element.date_debut) ||
              compareDate.isSame(element.date_fin)) && day_obj != 'dimanche') {


            days_objs.push({
              chiffre: element.chiffre,
              value: element.obj_visite,
              day: day_obj,
              date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
            });
            nbr_jour_travaille++;

          } else {
            days_objs.push({
              chiffre: 0,
              value: 0,
              day: day_obj,
              date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
            });
          }
        }
        vm.tableobjectifs.push({
          code: element.code,
          nom: element.nom,
          prenom: element.prenom,
          days_objs: days_objs,
          total: days_objs.reduce((s, f) => s + f.value, 0),
          totalchiffre: days_objs.reduce((s, f) => s + f.chiffre, 0),
          nbr_jour_travaille: nbr_jour_travaille
        });
      });

      var tabtotal = [];
      var tabtotalchiffre = [];
      vm.tableobjectifs.forEach(function (element, index) {
        element.days_objs.forEach(function (obj, ind) {
          tabtotal[ind] = (tabtotal[ind]) ? tabtotal[ind] + obj.value : obj.value;
          tabtotalchiffre[ind] = (tabtotalchiffre[ind]) ? tabtotalchiffre[ind] + obj.chiffre : obj.chiffre;
        });
      });

      vm.tabtotal = tabtotal;


      vm.tabtotalchiffre = tabtotalchiffre;

      vm.totalend = tabtotal.reduce((s, f) => s + f, 0);
      vm.totalendchiffre = tabtotalchiffre.reduce((s, f) => s + f, 0);

      vm.total_nbr_jour_trav = vm.tableobjectifs.reduce((s, f) => s + f.nbr_jour_travaille, 0);
      $scope.$apply();
    },
      function (error) {
        console.log('error');
      });
  };

  vm.kpi_visites = function () {

    WS.getScache('rapports/kpi_visites' + `?commercial=${vm.kpi_commercial}&type_depot=${vm.kpi_type_depot}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
      var tabvisites = [];
      response.data.users.forEach(function (user, indUser) {
        var tabusers = [];
        var nbr_jour_travaille = 0;
        response.data.visites.forEach(function (visite, indVisite) {
          if (user.code == visite.user_code) {
            tabusers.push({
              date_visite: visite.date_visite,
              nbr_visite: visite.nbr_visite,
              day: moment(visite.date_visite).format('dddd')
            });
            nbr_jour_travaille++;
          }
        });

        //tabusers
        //traitement with kpi_dd global
        for (var i = 1; i <= Number(vm.endOfMonth); i++) {
          i = (i <= 9) ? '0' + i : i;
          var date_trait = vm.month + '-' + i;
          var j = 0;
          tabusers.forEach(function (element, index) {
            if (element.date_visite == date_trait) {
              j++;
            }
          });
          if (j == 0) {
            tabusers.push({
              date_visite: date_trait,
              nbr_visite: 0,
              day: moment(date_trait).format('dddd')
            });
          }
        }

        tabusers = _.sortBy(tabusers, function (node) {
          return (new Date(node.date_visite).getTime());
        });
        tabvisites.push({
          code: user.code,
          nom: user.nom,
          prenom: user.prenom,
          visites: tabusers,
          total: tabusers.reduce((s, f) => s + f.nbr_visite, 0),
          nbr_jour_travaille: nbr_jour_travaille
        });
      });
      vm.tabvisites = tabvisites;
      var totalendObjs = 0;
      vm.tabvisites.forEach(function (visite, indexVis) {
        vm.tableobjectifs.forEach(function (objectif, indexObj) {
          if (visite.code == objectif.code) {
            var filteredItems = _.filter(visite.visites, function (item, i) {
              return item.nbr_visite != 0;
            });

            var dates = _.pluck(filteredItems, 'date_visite');

            var totobjres = 0;

            _.each(objectif.days_objs, function (el, index) {
              //console.log(_.contains(dates, el.date_visite), dates, el.date_visite);
              if (_.contains(dates, el.date_visite) == true) {
                totobjres += Number(el.value);
              }
            });

            vm.tabvisites[indexVis].totalObjRealise = totobjres;
            totalendObjs += totobjres;
          }
        });
      });

      console.log('-_-_-_-_-_-_-__-_-__-_-_-_-_-_-_-_-_-_--__-');
      console.log('tabvisites', vm.tabvisites);
      console.log('-_-_-_-_-_-_-__-_-__-_-_-_-_-_-_-_-_-_--__-');

      var tabtotalVisite = [];
      vm.tabvisites.forEach(function (element, index) {
        element.visites.forEach(function (obj, ind) {
          tabtotalVisite[ind] = (tabtotalVisite[ind]) ? tabtotalVisite[ind] + obj.nbr_visite : obj.nbr_visite;
        });
      });
      vm.tabtotalVisite = tabtotalVisite;
      vm.totalendVisite = tabtotalVisite.reduce((s, f) => s + f, 0);
      vm.totalendVisite = tabtotalVisite.reduce((s, f) => s + f, 0);

      vm.total_nbr_jour_visite = vm.tabvisites.reduce((s, f) => s + f.nbr_jour_travaille, 0);
      vm.totalendObjs = totalendObjs;

      $scope.$apply();
    },
      function (error) {
        console.log('error');
      });
  };

  vm.kpi_ventes = function (type = '') {
    WS.getScache(
      'rapports/kpi_ventes' + `?commercial=${vm.kpi_commercial}&type=${type}&type_depot=${vm.kpi_type_depot}&month=${vm.month}&gamme_code=${vm.kpi_gamme}&produit_code=${vm.kpi_produit}`
    ).then(function (response) {
      var tabventes = [];
      response.data.users.forEach(function (user, indUser) {
        var tabusers = [];
        var nbr_jour_travaille = 0;
        response.data.ventes.forEach(function (vente, indvente) {
          if (user.code == vente.user_code) {
            tabusers.push({
              date_vente: vente.date_vente,
              nbr_vente: vente.nbr_vente,
              day: moment(vente.date_vente).format('dddd')
            });
            nbr_jour_travaille++;
          }
        });

        //tabusers
        //traitement with kpi_dd global
        for (var i = 1; i <= Number(vm.endOfMonth); i++) {
          i = (i <= 9) ? '0' + i : i;
          var date_trait = vm.month + '-' + i;
          var j = 0;
          tabusers.forEach(function (element, index) {
            if (element.date_vente == date_trait) {
              j++;
            }
          });
          if (j == 0) {
            tabusers.push({
              date_vente: date_trait,
              nbr_vente: 0,
              day: moment(date_trait).format('dddd')
            });
          }
        }

        tabusers = _.sortBy(tabusers, function (node) {
          return (new Date(node.date_vente).getTime());
        });
        tabventes.push({
          code: user.code,
          nom: user.nom,
          prenom: user.prenom,
          ventes: tabusers,
          total: tabusers.reduce((s, f) => s + f.nbr_vente, 0),
          nbr_jour_travaille: nbr_jour_travaille
        });
      });
      vm.tabventes = tabventes;

      var tabtotalvente = [];
      vm.tabventes.forEach(function (element, index) {
        element.ventes.forEach(function (obj, ind) {
          if (tabtotalvente[ind]) {
            tabtotalvente[ind].nbr_vente = (tabtotalvente[ind].nbr_vente) ? tabtotalvente[ind].nbr_vente + obj.nbr_vente : obj.nbr_vente;
          } else {
            tabtotalvente.push({ nbr_vente: obj.nbr_vente, date_vente: obj.date_vente });

          }
        });
      });
      vm.tabtotalvente = tabtotalvente;
      vm.totalendvente = tabtotalvente.reduce((s, f) => s + f.nbr_vente, 0);

      vm.total_nbr_jour_vente = vm.tabventes.reduce((s, f) => s + f.nbr_jour_travaille, 0);
      // console.log('tabtotalvente', tabtotalvente);
      // console.log('totalendvente', vm.totalendvente);

      $scope.$apply();
    },
      function (error) {
        console.log('error');
      });
  };

  vm.kpi_total_ttc = function (type = '') {
    WS.getScache('rapports/kpi_total_ttc' + `?commercial=${vm.kpi_commercial}&type=${type}&type_depot=${vm.kpi_type_depot}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
      var tabttc = [];
      response.data.users.forEach(function (user, indUser) {
        var tabusers = [];
        var nbr_jour_travaille = 0;
        response.data.ventes.forEach(function (vente, indvente) {
          if (user.code == vente.user_code) {
            tabusers.push({
              date_vente: vente.date_vente,
              total_ttc: parseFloat(vente.total_ttc),
              day: moment(vente.date_vente).format('dddd')
            });
            nbr_jour_travaille++;
          }
        });
        //tabusers
        //traitement with kpi_dd global
        for (var i = 1; i <= Number(vm.endOfMonth); i++) {
          i = (i <= 9) ? '0' + i : i;
          var date_trait = vm.month + '-' + i;
          var j = 0;
          tabusers.forEach(function (element, index) {
            if (element.date_vente == date_trait) {
              j++;
            }
          });
          if (j == 0) {
            tabusers.push({
              date_vente: date_trait,
              total_ttc: 0,
              day: moment(date_trait).format('dddd')
            });
          }
        }

        tabusers = _.sortBy(tabusers, function (node) {
          return (new Date(node.date_vente).getTime());
        });
        var total = tabusers.reduce((s, f) => s + f.total_ttc, 0);
        tabttc.push({
          code: user.code,
          nom: user.nom,
          prenom: user.prenom,
          ventes: tabusers,
          total: parseFloat(total).toFixed(0),
          nbr_jour_travaille: nbr_jour_travaille
        });
      });
      vm.tabttc = tabttc;

      var tabtotalttc = [];
      vm.tabttc.forEach(function (element, index) {
        element.ventes.forEach(function (obj, ind) {
          tabtotalttc[ind] = (tabtotalttc[ind]) ? parseFloat(tabtotalttc[ind]) + obj.total_ttc : obj.total_ttc;
          tabtotalttc[ind] = parseFloat(tabtotalttc[ind]).toFixed(0);
        });
      });
      vm.tabtotalttc = tabtotalttc;

      var totalendttc = tabtotalttc.reduce((s, f) => parseFloat(s) + parseFloat(f), 0);
      vm.totalendttc = parseFloat(totalendttc).toFixed(0);

      vm.total_nbr_jour_ttc = vm.tabttc.reduce((s, f) => s + f.nbr_jour_travaille, 0);

      //vm.tabtotalttcPercent //ttc percent
      /**
       * debut percent vente ttc
       */
      var tabtotalttcPercent = [];
      vm.tabttc.forEach(function (element, index) {
        element.ventes.forEach(function (obj, ind) {

          vm.tabtotalttc.forEach(function (e_total, i_total) {
            if (i_total == ind) {
              if (e_total > 0) {
                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + (obj.total_ttc * 100 / e_total) : (obj.total_ttc * 100 / e_total);
                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
              } else {
                tabtotalttcPercent[ind] = (tabtotalttcPercent[ind]) ? parseFloat(tabtotalttcPercent[ind]) + 0 : 0;
                tabtotalttcPercent[ind] = parseFloat(tabtotalttcPercent[ind]).toFixed(0);
              }
            }
          });


        });
      });
      vm.tabtotalttcPercent = tabtotalttcPercent;
      var totalendttcPercent = tabtotalttcPercent.reduce((s, f) => parseFloat(s) + parseFloat(f), 0) / tabtotalttcPercent.length;
      vm.totalendttcPercent = parseFloat(totalendttcPercent).toFixed(0);

      /**
       * fin percent vente ttc
       */


      $scope.$apply();
    },
      function (error) {
        console.log('error');
      });
  };

  vm.kpiChartLinettc = function (type = '') {
    WS.getScache('rapports/kpiChartLinettc' + `?commercial=${vm.kpi_commercial}&month=${type}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
      var tabusers = [];
      response.data.ventes.forEach(function (vente, indvente) {
        tabusers.push({
          date_vente: vente.date_vente,
          total_ttc: vente.total_ttc,
          day: moment(vente.date_vente).format('DD ddd')
        });

      });

      //tabusers
      //traitement with kpi_dd global
      for (var i = 1; i <= Number(vm.endOfMonth); i++) {
        i = (i <= 9) ? '0' + i : i;
        var date_trait = vm.month + '-' + i;
        var j = 0;
        tabusers.forEach(function (element, index) {
          if (element.date_vente == date_trait) {
            j++;
          }
        });
        if (j == 0) {
          tabusers.push({
            date_vente: date_trait,
            total_ttc: 0,
            day: moment(date_trait).format('DD ddd')
          });
        }
      }

      tabusers = _.sortBy(tabusers, function (node) {
        return (new Date(node.date_vente).getTime());
      });


      /**
       * traitement d'objectif
       */
      vm.traitementobjectif = [];
      response.data.objectifs.forEach(function (element, index) {
        var date_debut = element.date_debut;
        var date_fin = element.date_fin;
        var days_objs = [];
        for (var i = 1; i <= Number(vm.endOfMonth); i++) {
          i = (i <= 9) ? '0' + i : i;
          var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
          var day_obj = moment(vm.month + '-' + i).format('dddd');
          if (
            (compareDate.isBetween(element.date_debut, element.date_fin) ||
              compareDate.isSame(element.date_debut) ||
              compareDate.isSame(element.date_fin)) && day_obj != 'dimanche') {

            days_objs.push({
              chiffre: element.chiffre,
              date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
            });
          } else {
            days_objs.push({
              chiffre: 0,
              date_visite: moment(vm.month + '-' + i).format('YYYY-MM-DD')
            });
          }
        }
        vm.traitementobjectif.push({
          days_objs: days_objs
        });
      });

      var tabtotalchiffre = [];
      vm.traitementobjectif.forEach(function (element, index) {
        element.days_objs.forEach(function (obj, ind) {
          tabtotalchiffre[ind] = (tabtotalchiffre[ind]) ? tabtotalchiffre[ind] + obj.chiffre : obj.chiffre;
        });
      });
      vm.totalendchiffre = tabtotalchiffre.reduce((s, f) => s + f, 0);

      vm.moyennetotalchiffre = vm.totalendchiffre / vm.tabtotalchiffre.length;
      /**
       * fin du traitement
       */

      tabusers.forEach(function (element, index) {
        tabtotalchiffre.forEach(function (totalchiffre, indexchiffre) {
          if (index == indexchiffre) {
            tabusers[index].objectif = totalchiffre;
          }
        });
      });
      vm.chartlinettc = tabusers;
      /*console.log('vm.chartlinettc', vm.chartlinettc);
      console.log('vm.tabtotalchiffre', tabtotalchiffre);*/

      var chart = AmCharts.makeChart("chartlinettc", {
        "type": "serial",
        "addClassNames": true,
        "theme": "light",
        "autoMargins": true,
        "balloon": {
          "adjustBorderColor": false,
          "horizontalPadding": 10,
          "verticalPadding": 8,
          "color": "#ffffff"
        },

        "dataProvider": vm.chartlinettc,
        "valueAxes": [{
          "axisAlpha": 0,
          "position": "left",
          "guides": [{
            "fillAlpha": 0.1,
            "label": vm.moyennetotalchiffre.toFixed(3),
            "value": vm.moyennetotalchiffre,
            "toValue": vm.moyennetotalchiffre,
            "lineColor": "#ff0000",
            "lineAlpha": 2,
            "fillAlpha": 0.2,
            "fillColor": "#FFCC00",
            "inside": false
          }]

        }],
        "startDuration": 1,
        "graphs": [{
          "alphaField": "alpha",
          "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          "fillAlphas": 1,
          "title": "CA (TTC)",
          "type": "column",
          "valueField": "total_ttc",
          "dashLengthField": "dashLengthColumn"
        }, {
          "id": "graph2",
          "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          "bullet": "round",
          "lineThickness": 3,
          "bulletSize": 7,
          "bulletBorderAlpha": 1,
          "bulletColor": "#FFFFFF",
          "useLineColorForBulletBorder": true,
          "bulletBorderThickness": 3,
          "fillAlphas": 0,
          "lineAlpha": 1,
          "title": "Objectif",
          "valueField": "objectif",
          "dashLengthField": "dashLengthLine"
        }],
        "categoryField": "day",
        "categoryAxis": {
          /*"gridPosition": "start",
          "axisAlpha": 0,
          "tickLength": 0*/

          "gridPosition": "start",
          "axisAlpha": 0,
          "fillAlpha": 0.01, //default 0.05
          "fillColor": "#000000",
          "gridAlpha": 0,
          "position": "bottom"
        },
        "export": {
          "enabled": true
        }
      });

      $scope.$apply();
    },
      function (error) {
        console.log('error');
      });
  };

  vm.kpiChartLineTauxSuccess = function (type = '') {
    WS.getScache('rapports/kpiChartLineTauxSuccess' + `?type=${type}&commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {
      var data = [];
      response.data.visite.forEach(function (visite, indexVis) {

        response.data.vente.forEach(function (vente, indexVen) {
          if (visite.date_visite == vente.date_visite) {
            var obj = {};
            obj = {
              date_visite: vente.date_visite,
              day: moment(vente.date_visite).format('DD ddd'),
              taux: parseFloat(vente.nbr_vente * 100 / visite.nbr_total).toFixed(0)
            }
            data.push(obj);
          }
        });
      });

      //traitement with kpi_dd global
      for (var i = 1; i <= Number(vm.endOfMonth); i++) {
        i = (i <= 9) ? '0' + i : i;
        var date_trait = vm.month + '-' + i;
        var j = 0;
        data.forEach(function (element, index) {
          if (element.date_visite == date_trait) {
            j++;
          }
        });
        if (j == 0) {
          data.push({
            date_visite: date_trait,
            day: moment(date_trait).format('DD ddd'),
            taux: 0
          });
        }
      }
      vm.totalendtaux_success = data.reduce((s, f) => parseFloat(s) + parseFloat(f.taux), 0);

      vm.moyennetaux_success = vm.totalendtaux_success / data.length;
      //console.log(vm.totalendtaux_success, vm.moyennetaux_success );
      var data = _.sortBy(data, 'date_visite');

      var chart = AmCharts.makeChart("kpiChartLineTauxSuccess", {
        "type": "serial",
        "addClassNames": true,
        "theme": "light",
        "autoMargins": true,
        "balloon": {
          "adjustBorderColor": false,
          "horizontalPadding": 10,
          "verticalPadding": 8,
          "color": "#ffffff"
        },

        "dataProvider": data,
        "valueAxes": [{
          "max": 100,
          "maximum": 100,
          "axisAlpha": 0,
          "position": "left",
          "guides": [{
            "fillAlpha": 0.1,
            "label": vm.moyennetaux_success.toFixed(2),
            "value": vm.moyennetaux_success,
            "toValue": vm.moyennetaux_success,
            "lineColor": "#ff0000",
            "lineAlpha": 2,
            "fillAlpha": 0.2,
            "fillColor": "#FFCC00",
            "inside": false
          }]

        }],
        "startDuration": 1,
        "graphs": [{
          "alphaField": "alpha",
          "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          "fillAlphas": 1,
          "title": "Taux de succès (%)",
          "type": "column",
          "valueField": "taux",
          "dashLengthField": "dashLengthColumn"
        }, {
          "id": "graph2",
          "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          "bullet": "round",
          "lineThickness": 3,
          "bulletSize": 7,
          "bulletBorderAlpha": 1,
          "bulletColor": "#FFFFFF",
          "useLineColorForBulletBorder": true,
          "bulletBorderThickness": 3,
          "fillAlphas": 0,
          "lineAlpha": 1,
          "title": "Objectif",
          "valueField": "objectif",
          "dashLengthField": "dashLengthLine"
        }],
        "categoryField": "day",
        "categoryAxis": {
          /*"gridPosition": "start",
          "axisAlpha": 0,
          "tickLength": 0*/

          "gridPosition": "start",
          "axisAlpha": 0,
          "fillAlpha": 0.01, //default 0.05
          "fillColor": "#000000",
          "gridAlpha": 0,
          "position": "bottom"
        },
        "export": {
          "enabled": true
        }
      });
      $scope.$apply();
    },
      function (error) {
        console.log('error');
      });
  };

  vm.kpiChartLineTauxVisites = function () {
    WS.getScache('rapports/kpiChartLineTauxVisites' + `?commercial=${vm.kpi_commercial}&month=${vm.month}&produit_code=${vm.kpi_produit}&gamme_code=${vm.kpi_gamme}`).then(function (response) {

      var objectifs = [];

      response.data.objectifs.forEach(function (element, index) {
        var date_debut = element.date_debut;
        var date_fin = element.date_fin;
        for (var i = 1; i <= Number(vm.endOfMonth); i++) {
          i = (i <= 9) ? '0' + i : i;
          var compareDate = moment(vm.month + '-' + i, "YYYY-MM-DD");
          var date_trait = moment(vm.month + '-' + i).format("YYYY-MM-DD");
          var is_dim = moment(vm.month + '-' + i).format('dddd');
          var day_obj = moment(vm.month + '-' + i).format('DD ddd');
          var j = 0;
          if (
            (compareDate.isBetween(element.date_debut, element.date_fin) ||
              compareDate.isSame(element.date_debut) ||
              compareDate.isSame(element.date_fin)) && is_dim != 'dimanche') {
            objectifs.forEach(function (objectif, indexObj) {
              if (objectif.date_visite == date_trait) {
                j++;
                objectifs[indexObj].taux = parseFloat(objectif.taux) + element.obj_visite;
              }
            });
            if (j == 0) {
              objectifs.push({
                taux: element.obj_visite,
                date_visite: date_trait,
                day: day_obj
              });
            }

          } else {

            objectifs.forEach(function (objectif, indexObj) {
              if (objectif.date_visite == date_trait) {
                j++;
              }
            });
            if (j == 0) {
              objectifs.push({
                taux: 0,
                date_visite: date_trait,
                day: day_obj
              });
            }

          }
        }

      });

      objectifs.forEach(function (element, index) {
        var i = 0;
        response.data.visite.forEach(function (visite, indexVis) {
          if (element.date_visite == visite.date_visite) {
            if (element.taux == 0 || visite.nbr_total == 0) {
              var taux = 0;
            } else {
              var taux = visite.nbr_total * 100 / element.taux;
              if (taux > 100) {
                taux = 100;
              }
            }
            objectifs[index].taux = taux.toFixed(0);
            i++;
          }
        });
        if (i == 0) {
          objectifs[index].taux = 0;
        }
      });
      vm.totalendtaux_visite = objectifs.reduce((s, f) => parseFloat(s) + parseFloat(f.taux), 0);

      vm.moyennetaux_visite = vm.totalendtaux_visite / objectifs.length;
      //console.log(vm.totalendtaux_success, vm.moyennetaux_success );
      var chart = AmCharts.makeChart("kpiChartLineTauxVisites", {
        "type": "serial",
        "addClassNames": true,
        "theme": "light",
        "autoMargins": true,
        "balloon": {
          "adjustBorderColor": false,
          "horizontalPadding": 10,
          "verticalPadding": 8,
          "color": "#ffffff"
        },

        "dataProvider": objectifs,
        "valueAxes": [{
          "max": 100,
          "maximum": 100,
          "axisAlpha": 0,
          "position": "left",
          "guides": [{
            "fillAlpha": 0.1,
            "label": vm.moyennetaux_visite.toFixed(2),
            "value": vm.moyennetaux_visite,
            "toValue": vm.moyennetaux_visite,
            "lineColor": "#ff0000",
            "lineAlpha": 2,
            "fillAlpha": 0.2,
            "fillColor": "#FFCC00",
            "inside": false
          }]

        }],
        "startDuration": 1,
        "graphs": [{
          "alphaField": "alpha",
          "balloonText": "<span style='font-size:12px;'>[[title]] de [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
          "fillAlphas": 1,
          "title": "Taux de Visite (%)",
          "type": "column",
          "valueField": "taux",
          "dashLengthField": "dashLengthColumn"
        }],
        "categoryField": "day",
        "categoryAxis": {
          /*"gridPosition": "start",
          "axisAlpha": 0,
          "tickLength": 0*/

          "gridPosition": "start",
          "axisAlpha": 0,
          "fillAlpha": 0.01, //default 0.05
          "fillColor": "#000000",
          "gridAlpha": 0,
          "position": "bottom"
        },
        "export": {
          "enabled": true
        }
      });
      $scope.$apply();
    },
      function (error) {
        console.log('error');
      });
  };

  vm.getVenteStatbyUser = function (user_code, date_vente) {
    //alert(user_code + ' ' +date_vente);
  };

  vm.getTotalVenteStat = function (date_vente) {
    //alert(date_vente);
  };


  /**
   *  ******************************************************* suiviCommercialMois ***************************************************************
   */

  $rootScope.$on('$translateChangeSuccess', function () {
    vm.allmonths = [
      '',
      $translate.instant('Janvier'), $translate.instant('Fevrier'),
      $translate.instant('Mars'), $translate.instant('Avril'),
      $translate.instant('Mai'), $translate.instant('Juin'),
      $translate.instant('Juillet'), $translate.instant('Aout'),
      $translate.instant('Septembre'), $translate.instant('Octobre'),
      $translate.instant('Novembre'), $translate.instant('Decembre'),
    ];

  });
  vm.initSuiviComMois = function () {
    vm.suivi = {};
    vm.suivi.commercial = '';
    vm.suivi.date_debut = moment().format('YYYY') + '-' + '01';
    vm.suivi.date_fin = moment().format('YYYY') + '-' + '12';
    vm.allmonths = [
      '',
      $translate.instant('Janvier'), $translate.instant('Fevrier'),
      $translate.instant('Mars'), $translate.instant('Avril'),
      $translate.instant('Mai'), $translate.instant('Juin'),
      $translate.instant('Juillet'), $translate.instant('Aout'),
      $translate.instant('Septembre'), $translate.instant('Octobre'),
      $translate.instant('Novembre'), $translate.instant('Decembre'),
    ];
    $(".datepicker-dashb_suiv_debut").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.suivi.date_debut = date;
        $scope.$apply();
        //console.log(vm.month);
      }
    });

    $(".datepicker-dashb_suiv_fin").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.suivi.date_fin = date;
        $scope.$apply();
        //console.log(vm.month);
      }
    });
  }

  vm.suiviCommericalParMois = function (suivi) {
    //console.log(suivi);
    vm.suivi_filter_month = [];
    WS.getData('rapports/suiviCommericalParMois', suivi)
      .then(response => {
        //console.log(response.data);
        vm.res_suivi = response.data;
        vm.linechartssuivi = [];
        response.data.forEach((element, index) => {
          vm.suivi_filter_month.push(vm.allmonths[element.month] + ' - ' + element.year);
          vm.linechartssuivi.push({
            month: vm.allmonths[element.month] + ' - ' + element.year,
            commande: element.chiffre_net_cmd,
            bl: element.chiffre_net_bl,
          });
        });
        //console.log(vm.linechartssuivi);
        AmCharts.makeChart("linechartssuivi", {
          "type": "serial",
          "theme": "light",
          "decimalSeparator": ".",
          "thousandsSeparator": "",
          "legend": {
            "useGraphSettings": true,
            "valueWidth": 100,
            //"width" : 350,
          },
          "dataProvider": vm.linechartssuivi,
          "valueAxes": [{
            "integersOnly": true,
            "reversed": false,
            "axisAlpha": 0,
            "dashLength": 1,
            "gridCount": 2,
            "position": "left"
            // "title": "Place taken"
          }],
          "startDuration": 0.5,
          "graphs": [{
            "balloonText": $translate.instant('commandes') + " [[category]]: [[value]]",
            "bullet": "round",
            "title": $translate.instant('commandes'),
            "valueField": "commande",
            "fillAlphas": 0
          }, {
            "balloonText": $translate.instant('bls') + " [[category]]: [[value]]",
            "bullet": "round",
            "title": $translate.instant('bls'),
            "valueField": "bl",
            "fillAlphas": 0
          }],
          "chartCursor": {
            "cursorAlpha": 0,
            "zoomable": false
          },
          "categoryField": "month",
          "categoryAxis": {
            "gridPosition": "start",
            "axisAlpha": 0,
            "fillAlpha": 0.05,
            "fillColor": "#000000",
            "gridAlpha": 0,
            "position": "bottom"
          },
          "export": {
            "enabled": true,
            "position": "bottom-right"
          }
        });
        $scope.$apply();
      }, error => {
        console.log(error);
      });
  };



  vm.getRapportVisitesParAnnee = function ($client, $year) {

    WS.get('visites/rapportVisiteParAnnee' + '/' + $client + '/' + $year)
      .then(function (response) {
        if (response.status == 200) {
          vm.rapportvisitesparmois = response.data.visite;
          vm.rapportblsparmois = response.data.bls;
          vm.rapportblparmois = response.data.total;
          vm.linechartssuivianne = [];

          var tab_mois = new Array("",
            "Janvier", "Fevrier", "Mars",
            "Avril", "Mai", "Juin",
            "Juillet", "Aout", "Septembre",
            "Octobre", "Novembre", "Decembre"
          );
          response.data.total.forEach((element, index) => {
            vm.linechartssuivianne.push({

              month: tab_mois[element.mois],
              // month : element.mois,
              //commande : element.chiffre_net_cmd,
              vente: element.total,
            });
          });
          //console.log(vm.linechartssuivianne);
          AmCharts.makeChart("linechartssuiviannee", {
            "type": "serial",
            "theme": "light",
            "decimalSeparator": ".",
            "thousandsSeparator": "",
            "legend": {
              "useGraphSettings": true,
              "valueWidth": 100,
              //"width" : 350,
            },
            "dataProvider": vm.linechartssuivianne,
            "valueAxes": [{
              "integersOnly": true,
              "reversed": false,
              "axisAlpha": 0,
              "dashLength": 1,
              "gridCount": 2,
              "position": "left"
              // "title": "Place taken"
            }],
            "startDuration": 0.5,
            "graphs": [{
              "balloonText": $translate.instant('Vente') + " [[category]]: [[value]]",
              "bullet": "round",
              "title": $translate.instant('Vente'),
              "valueField": "vente",
              "fillAlphas": 0
            }],
            "chartCursor": {
              "cursorAlpha": 0,
              "zoomable": false
            },
            "categoryField": "month",
            "categoryAxis": {
              "gridPosition": "start",
              "axisAlpha": 0,
              "fillAlpha": 0.05,
              "fillColor": "#000000",
              "gridAlpha": 0,
              "position": "bottom"
            },
            "export": {
              "enabled": true,
              "position": "bottom-right"
            }
          });
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.selectRoutes = function () {
    WS.get('routing/selectRouteWithDepotUser')
      .then(function (response) {
        vm.routings = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getcategories = function () {
    WS.get('categoriescommerciale')
      .then(function (response) {
        if (response.status == 200) {

          vm.categories = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.selectGouvernorats = () => {
    WS.get('gouvernorats/selectGouvernorats')
      .then(function (response) {
        vm.gouvernorats = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.suiviClientsByYear = function (suivi_cls) {
    WS.getData('rapports/suiviClientsByYear', suivi_cls)
      .then(response => {
        vm.selectedyears = [];
        var res_year = _.groupBy(response.data, 'year');
        vm.suivi_clsbyyear = _.groupBy(response.data, 'code');

        _.each(res_year, (element, index) => {
          vm.selectedyears.push(index);
        })
        $scope.$apply();
      });
  };

  vm.getListCheques = function (suivi_cls) {
    WS.getData('rapports/suivilistCheques', suivi_cls)
      .then(response => {
        vm.suivilistCheques = response.data;
        $scope.$apply();
      });
  };

  vm.exportSuiviCheque = function (suivi_cls) {
    WS.getData('rapports/suivilistCheques', suivi_cls)
      .then(response => {
        var filename = 'Suivi List Cheques-' + moment().format("DD-MM-YYYY_HH:mm:ss");

        var file = {
          sheetid: 'Suivi List Cheques--' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          caption: {
            title: 'Suivi List Cheques--' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'Commercial', title: 'Commercial' },
            { columnid: 'Client', title: 'Client' },
            { columnid: 'date', title: 'BL' },
            { columnid: 'code_bl', title: 'Mobile' },
            { columnid: 'recouvrement_code', title: 'Recouvrement' },
            { columnid: 'montant', title: 'Montant Total' },
            { columnid: 'cheque', title: 'Montant Cheque' },
            { columnid: 'num_cheque', title: 'Numero' },
            { columnid: 'banque_cheque', title: 'Banque' },
            { columnid: 'date_echeance_cheque', title: 'Echeance' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
        function (error) {
          console.log(error);
        }
      );
  };


  vm.getsuiviCommercialClient = function (suivi_cls) {
    WS.getData('rapports/suiviCommercialClient', suivi_cls)
      .then(response => {
        vm.suiviCommercialClient = response.data;
        $scope.$apply();
      });
  };


  vm.getcreditencours = function (filter) {
    WS.getData('rapports/creditEncoursParCommercialForAnalyse', filter)
      .then(response => {
        vm.creditencours = response.data;
        $scope.$apply();
      });
  };

  vm.getcreditencoursParClient = function (filter) {
    WS.getData('rapports/creditEncoursParClientForAnalyse', filter)
      .then(response => {
        vm.listcreditClient = response.data;
        $scope.$apply();
      });
  };


  // vm.exportcreditencourse = function (commercial) {
  //     WS.getData('rapports/creditEncoursParClientForAnalyse', commercial)
  //         .then(response => {
  //             var count = response.data.length;
  //             var filename = 'Credit Encours-' + moment().format("DD-MM-YYYY_HH:mm:ss");
  //             // var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

  //             var file = {
  //                 sheetid: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
  //                 headers: true,
  //                 caption: {
  //                     title: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
  //                     style: 'font-size: 50px; color:blue;'
  //                 },
  //                 column: {
  //                     style: 'font-size:20px;background:#d1d1d1;'
  //                 },
  //                 columns: [

  //                     { columnid: 'code', title: 'Code' },
  //                     { columnid: 'client', title: 'Client' },
  //                     { columnid: 'magasin', title: 'Magasin' },
  //                     { columnid: 'adresse_facturation', title: 'Adresse' },
  //                     { columnid: 'mobile', title: 'Mobile' },
  //                     { columnid: 'routing_code', title: 'Route' },
  //                     { columnid: 'encours', title: 'Encours' }

  //                 ],
  //                 row: {
  //                     style: function (sheet, row, rowidx) {
  //                         return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
  //                     }
  //                 }
  //             };
  //             response.data.forEach(function (element, index) {
  //                 response.data[index].net_a_payer = response.data[index].net_a_payer.toString().replace('.', ',');
  //             });
  //             alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
  //         },
  //             function (error) {
  //                 console.log(error);
  //             }
  //         );
  // };

  vm.exportcreditencoursParClient = function (filter) {
    WS.getData('rapports/creditEncoursParClientForAnalyse', filter)
      .then(response => {
        var count = response.data.length;
        var filename = 'Credit Encours-' + moment().format("DD-MM-YYYY_HH:mm:ss");
        // var filename = response.data[count - 1].type + '-' + response.data[count - 1].date_visite;

        var file = {
          sheetid: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          // caption: {
          //     title: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          //     style: 'font-size: 50px; color:blue;'
          // },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'code', title: 'Code' },
            { columnid: 'client', title: 'Client' },
            { columnid: 'magasin', title: 'Magasin' },
            { columnid: 'adresse_facturation', title: 'Adresse' },
            { columnid: 'mobile', title: 'Mobile' },
            { columnid: 'routing_code', title: 'Route' },
            { columnid: 'encours', title: 'Encours' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };
        // response.data.forEach(function (element, index) {
        //     response.data[index].net_a_payer = response.data[index].net_a_payer.toString().replace('.', ',');
        // });
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
        function (error) {
          console.log(error);
        }
      );
  };

  vm.exportcreditencours = function (filter) {
    WS.getData('rapports/creditEncoursParCommercialForAnalyse', filter)
      .then(response => {
        var filename = 'Credit Encours-' + moment().format("DD-MM-YYYY_HH:mm:ss");

        var file = {
          sheetid: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          // caption: {
          //     title: 'Credit Encours-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          //     style: 'font-size: 50px; color:blue;'
          // },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'code', title: 'Code' },
            { columnid: 'client', title: 'Client' },
            { columnid: 'magasin', title: 'Magasin' },
            { columnid: 'adresse_facturation', title: 'Adresse' },
            { columnid: 'mobile', title: 'Mobile' },
            { columnid: 'routing_code', title: 'Route' },
            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'encours', title: 'Encours' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
        function (error) {
          console.log(error);
        }
      );
  };



  vm.getLastvisiteDetails = function (client) {
    WS.get('clients/getLastVisite/' + client, vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.getLastVisite = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }




  /**
   * suivi commande par produits
   */

  vm.suiv_date_debut = moment().format('YYYY-MM-DD');
  vm.suiv_date_fin = moment().format('YYYY-MM-DD');
  vm.cmdByProduitCommercial = function () {
    var data = {
      date_debut: vm.suiv_date_debut,
      date_fin: vm.suiv_date_fin,
    };
    WS.getData('gestion_commandes/cmdByProduitCommercial', data).then(
      function (response) {
        console.log(response.data);
        vm.table_suivi = response.data;
        $scope.$apply();
      },
      function (error) {
        console.log('error : ->', error);
      }
    );
  };

  vm.exporttableHtmlSuiv = function (tableId) {
    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;
    var title = "Rapport-";
    title += vm.date_debut + '_||_' + vm.date_fin;

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);

  };

  vm.ExportcmdByProduitCommercial = function () {
    var url = `gestion_commandes/ExportcmdByProduitCommercial?date_debut=${vm.suiv_date_debut}&date_fin=${vm.suiv_date_fin}`;
    $window.location.href = AppSettings.apiUrl + url;
  };

  vm.getInfoCommercial = function (code) {
    vm.infouser = _.findWhere(vm.selectusers, { code: code });
    //console.log(vm.infouser);
  }

  vm.exportTableTExcelFile = function (tableId, title, check_type) {
    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;

    download_link.download = title + vm.infouser.user + '-' + check_type + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);

  };




  vm.getProdsByClients = function () {
    var data = {
      type: 'commande',
      date: vm.select_date,
      commercial: vm.select_commercial,
      gamme: vm.select_gamme,
      famille: vm.select_famille,
      sousfamille: vm.select_sousfamille,
    };
    WS.getData('rapports/getProdsByClients', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.listProd = response.data.listProd;
          vm.listClient = response.data.listClient;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getTournee = function (code, date = null) {
    WS.get('tournees/getRouteTourneeClientByCommercial/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.tournees = response.data.tournees;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.getClientNonTraiter = function (code, date) {
    WS.getScache('tournees/getClientNonTraiter/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.tournees_non_traite = response.data.tournees;
          //console.log(code, date, vm.tournees_non_traite);
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getSuiviReglementByCommercial = function (code, date) {
    WS.getScache('rapports/suiviReglementByCommercial/' + code + '/' + date)
      .then(function (response) {
        if (response.status == 200) {
          vm.suiviReglementByCommercial = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getListClientByCommercial = function (code) {
    WS.getScache('clients/getListClientByCommercial/' + code)
      .then(function (response) {
        if (response.status == 200) {
          vm.clients = response.data;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getclientIntourne = function (code, liste) {

    if (!liste) return false;
    for (var i = 0; i < liste.length; i++) {
      if (liste[i]['client_code'] == code) {
        return true;
      }
    }
  };


  vm.selectedUser = (user_code) => {
    vm.currentUser = _.find(vm.commercial, (memo) => memo.code == user_code);

    //console.log("currentUser" , vm.currentUser);
  }

  vm.ExportRapportTournee = function (tableId) {

    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;
    var title = 'Rapport-tournee ' + vm.currentUser.code + ' ' + vm.currentUser.nom + ' ' + vm.currentDate;

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);

  };


  /**
   * vente by month
   */

  $(document).ready(function () {
    $(".datepicker-year").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.vente_by_month.year = date;
        $scope.$apply();
      }
    });


    $(".stat_assenceur_day").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.stat_assenceur.date = date;
        $scope.$apply();
      }
    });

    $(".sf_promo_gr_debut").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.sf_promo_gratuite.date_debut = date;
        $scope.$apply();
      }
    });

    $(".sf_promo_gr_fin").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.sf_promo_gratuite.date_fin = date;
        $scope.$apply();
      }
    });


    $(".dates_debut").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.filterdate.date_debut = date;
        $scope.$apply();
      }
    });

    $(".dates_fin").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.filterdate.date_fin = date;
        $scope.$apply();
      }
    });
  })

  vm.findVenteByMonth = () => {

    vm.vente_by_month.client_code = vm.search44.client_code;
    WS.post('rapports/findVenteByMonth', vm.vente_by_month)
      .then(function (response) {
        console.log('response_data', response.data)
        vm.formated_ventes = _.groupBy(response.data, function (num) { return num.code });
        console.log('vm.formated_ventes', vm.formated_ventes)

        vm.total_months_prod = {};
        vm.total_months_prod_all = {};
        _.each(vm.formated_ventes, (element, code) => {
          vm.total_months_prod[code] = _.reduce(element, function (memo, num) { return memo + num.value; }, 0);
          vm.total_months_prod_all[code + '_carton'] = _.reduce(element, function (memo, num) { return memo + num.carton_value; }, 0);
          vm.total_months_prod_all[code + '_quantite'] = _.reduce(element, function (memo, num) { return memo + num.quantite_value; }, 0);
          vm.total_months_prod_all[code + '_chiffre'] = _.reduce(element, function (memo, num) { return memo + num.chiffre_value; }, 0);
          vm.total_months_prod_all[code + '_poids'] = _.reduce(element, function (memo, num) { return memo + num.poids_value; }, 0);
        });
        var months = _.pluck(response.data, 'month');
        vm.months_nambers = _.uniq(months);
        vm.total_months = {};
        vm.total_months_all = {};

        _.each(vm.months_nambers, element => {
          var tab_month = _.filter(response.data, function (num) { return num.month == element; });
          vm.total_months[element] = _.reduce(tab_month, function (memo, num) { return memo + num.value; }, 0);
          vm.total_months_all[element + '_carton'] = _.reduce(tab_month, function (memo, num) { return memo + num.carton_value; }, 0);
          vm.total_months_all[element + '_quantite'] = _.reduce(tab_month, function (memo, num) { return memo + num.quantite_value; }, 0);
          vm.total_months_all[element + '_chiffre'] = _.reduce(tab_month, function (memo, num) { return memo + num.chiffre_value; }, 0);
          vm.total_months_all[element + '_poids'] = _.reduce(tab_month, function (memo, num) { return memo + num.poids_value; }, 0);
        });

        vm.somme_from_all = _.reduce(vm.total_months_prod, function (memo, num) { return memo + num; }, 0);
        //vm.month_names
        // console.log(vm.formated_ventes, vm.months_nambers);
        $scope.$apply();
      })
      .then(null, function (error) {

      });
  }


  vm.calculateTotalofSF = (sousfamille_code) => {
    var res = 0;
    _.each(vm.rapportventesousfamille, element => {
      if (element.code == sousfamille_code) {
        res = res + element.value;
      }
    });
    return res;
  };

  vm.calculateTotalofClient = (client_code) => {
    var res = 0;
    _.each(vm.rapportventesousfamille, element => {
      if (element.client_code == client_code) {
        res = res + element.value;
      }
    });
    return res;
  };



  vm.findAssenceurs = function () {
    WS.getData('rapports/findAssenceurs', vm.stat_assenceur)
      .then(function (response) {
        if (response.status == 200) {
          vm.assenceurs = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.findDetailsAssenceur = function (assenceur_code) {
    vm.stat_assenceur.assenceur_code = assenceur_code;
    WS.getData('rapports/findDetailsAssenceur', vm.stat_assenceur)
      .then(function (response) {
        if (response.status == 200) {
          vm.details_assenceur = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getListClientsByUser = (user_code, filter) => {
    console.log(user_code, filter);
    var data = {
      user_code: user_code,
      year: filter.year,
      month: filter.month,
      day: filter.day
    }

    WS.getData('rapports/getListClientsByUser', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.listClientsVisite = response.data.visites;
          vm.listClientsNonVisite = response.data.nonVisites;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.exportClientsVisitee = function () {
    var user = (vm.filter.user_code) ? vm.filter.user_code : '';
    var day = (vm.filter.day) ? vm.filter.day : '';
    var filename = "Clients visités-" + user + '-'
      + vm.filter.year + '-'
      + vm.filter.month + '-'
      + day;

    var file = {
      sheetid: filename,
      headers: true,
      // caption: {
      //     title: filename,
      //     style: 'font-size: 50px; color:blue;'
      // },
      column: {
        style: 'font-size:20px;background:#d1d1d1;'
      },
      columns: [
        { columnid: 'code', title: 'Code' },
        { columnid: 'client', title: 'Client' },
        { columnid: 'mobile', title: 'Mobile' },
        { columnid: 'adresse', title: 'Adresse' },
        { columnid: 'credit', title: 'Crédit' },
        { columnid: 'ca_client', title: 'CA' },
        { columnid: 'last_visite', title: 'Dernier visite user' },
        { columnid: 'last_visite_client', title: 'Dernier visite client' }
      ],
      row: {
        style: function (sheet, row, rowidx) {
          return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
        }
      }
    };
    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.listClientsVisite]);
  }

  vm.exportClientsNonVisitee = function () {
    var user = (vm.filter.user_code) ? vm.filter.user_code : '';
    var day = (vm.filter.day) ? vm.filter.day : '';
    var filename = "Clients non visités-" + user + '-'
      + vm.filter.year + '-'
      + vm.filter.month + '-'
      + day;

    var file = {
      sheetid: filename,
      headers: true,
      // caption: {
      //     title: filename,
      //     style: 'font-size: 50px; color:blue;'
      // },
      column: {
        style: 'font-size:20px;background:#d1d1d1;'
      },
      columns: [
        { columnid: 'code', title: 'Code' },
        { columnid: 'client', title: 'Client' },
        { columnid: 'mobile', title: 'Mobile' },
        { columnid: 'adresse', title: 'Adresse' },
        { columnid: 'credit', title: 'Crédit' },
        { columnid: 'ca_client', title: 'CA' },
        { columnid: 'last_visite', title: 'Dernier visite user' },
        { columnid: 'last_visite_client', title: 'Dernier visite client' }
      ],
      row: {
        style: function (sheet, row, rowidx) {
          return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
        }
      }
    };
    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.listClientsNonVisite]);
  }

  vm.selectListClientByPaginate = (page = 1, per_page = 15) => {
    var data = {
      page,
      per_page,
      searchbox: vm.searchbox
    };
    WS.getData('clients/selectListClientByPaginate', data)
      .then((response) => {
        vm.list_clients = response.data.data;
        vm.current_page = response.data.current_page;
        vm.previous = vm.current_page - 1;
        vm.next = vm.current_page + 1;
        vm.from = response.data.form;
        vm.last_page = response.data.last_page;
        vm.next_page_url = response.data.next_page_url;
        vm.per_page = response.data.per_page;
        vm.prev_page_url = response.data.prev_page_url;
        vm.to = response.data.to;
        vm.total = response.data.total;
        vm.paginate = [];
        for (var i = 1; i <= vm.last_page; i++) {
          vm.paginate.push(i);
        }
        $scope.$apply();
      }, (error) => {

      })
  };
  vm.checkpaginate = function (current, page, lastpage) {
    if (page > current + 3 || page < current - 3) {
      return false;
    } else {
      return true;
    }
  }




  vm.sfPromoGratuite = () => {
    vm.loading_request = true;

    WS.getData('rapports/sfPromoGratuite', vm.sf_promo_gratuite)
      .then(function (response) {
        if (response.status == 200) {
          vm.sf_promo_res = response.data;
          vm.total_with_promo = 0;
          vm.total_without_promo = 0;
          vm.total_gratuite = 0;
          vm.total_ca_en_promotion = 0;
          vm.total_ca_hors_promotion = 0;
          _.each(response.data, element => {
            vm.total_with_promo += element.with_promo;
            vm.total_without_promo += element.without_promo;
            vm.total_gratuite += element.gratuite;
            vm.total_ca_en_promotion += element.ca_en_promotion;
            vm.total_ca_hors_promotion += element.ca_hors_promotion;
          });
          vm.loading_request = false;

          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };


  vm.getTourneeByInterval = () => {
    WS.getData('rapports/getTourneeByInterval', vm.filterdate)
      .then(function (response) {
        if (response.status == 200) {
          vm.stat_tournees = response.data;
          vm.total_bls = 0;
          vm.total_commandes = 0;
          _.each(vm.stat_tournees.bls, dates => {
            _.each(dates, bl => {
              vm.total_bls += bl.net_a_payer;
            })
          })

          _.each(vm.stat_tournees.commandes, dates => {
            _.each(dates, bl => {
              vm.total_commandes += bl.net_a_payer;
            })
          })
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.calculateMontant = (bls) => {
    var montant = 0;
    var in_tournee = 0;
    var out_tournee = 0;
    _.each(bls, element => {
      montant += element.net_a_payer;
      in_tournee += (element.in_tournee == '1') ? 1 : 0;
      out_tournee += (element.in_tournee == '0') ? 1 : 0;
    })

    return {
      montant,
      in_tournee,
      out_tournee

    };
  }

  vm.getReglement = () => {
    WS.getData('rapports/getReglement', vm.filterdate)
      .then(function (response) {
        if (response.status == 200) {
          vm.reglements = response.data;
          vm.total_reglement = 0;
          _.each(vm.reglements, element => {
            vm.total_reglement += element.montant;
          })
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getReclamations = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code,
      theme: 'RECLAMATION'
    }
    WS.getData('rapports/getNotesByTheme', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.reclamations = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getDemandes = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code,
      theme: 'DEMANDES'
    }
    WS.getData('rapports/getNotesByTheme', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.demandes = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getVeilleConcurentiel = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code,
      theme: 'VEILLE CONCURRENTIELLE'
    }
    WS.getData('rapports/getNotesByTheme', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.veille_concurenciel = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getInformationNotes = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code,
      theme: 'INFORMATION'
    }
    WS.getData('rapports/getNotesByTheme', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.informations = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.NvClients = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code
    }
    WS.getData('rapports/NvClients', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.nv_clients = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getTourneeByIntervalUsers = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code
    }
    WS.getData('rapports/getTourneeByIntervalUsers', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.users_rapports = response.data;
          //console.log(vm.users_rapports);
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.getTourneeByIntervalUsersDate = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code
    }
    WS.getData('rapports/getTourneeByIntervalUsersDate', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.users_rapports_date = response.data;

          vm.t_total_visite_planifiee = 0;
          vm.t_total_client_visite_intourne = 0;
          vm.t_cls_programme_not_visite = 0;
          vm.t_total_client_visite_outtourne = 0;
          vm.t_total_client_visite = 0;
          vm.t_total_visites_distinct = 0;

          _.each(vm.users_rapports_date, element => {
            vm.t_total_visite_planifiee += element.total_visite_planifiee;
            vm.t_total_client_visite_intourne += element.total_client_visite_intourne;
            vm.t_cls_programme_not_visite += element.cls_programme_not_visite;
            vm.t_total_client_visite_outtourne += element.total_client_visite_outtourne;
            vm.t_total_client_visite += element.total_client_visite;
            vm.t_total_visites_distinct += element.total_visites_distinct;

          })
          vm.t_percent = vm.t_total_visite_planifiee ? vm.t_total_client_visite_intourne * 100 / vm.t_total_visite_planifiee : 0;
          //console.log(vm.users_rapports_date);
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  /**
   * rapport 83.2
   * @param {date} date_debut
   * @param {date} date_debut
   * @param {string} user_code
   */
  vm.getListClientsByIntervalUsersDate = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code,
      type: vm.filterdate.type
    }
    WS.getData('rapports/getListClientsByIntervalUsersDate', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.list_clients_tournees = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  /**
   * rapport 47.1
   * @param {date} date_debut
   * @param {date} date_debut
   * @param {string} user_code
   */
  vm.stateDemandeChargement = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code,
      type: vm.filterdate.type
    }
    WS.getData('rapports/stateDemandeChargement', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.dc_prods = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  /**
   *
   * @param {*} users_rapports_date
   */

  vm.recalculateTotaleTournees = (users_rapports_date) => {
    vm.t_total_visite_planifiee = 0;
    vm.t_total_client_visite_intourne = 0;
    vm.t_cls_programme_not_visite = 0;
    vm.t_total_client_visite_outtourne = 0;
    vm.t_total_client_visite = 0;
    vm.t_total_visites_distinct = 0;

    _.each(users_rapports_date, element => {
      vm.t_total_visite_planifiee += element.commentaire != '1' ? element.total_visite_planifiee : 0;
      vm.t_total_client_visite_intourne += element.commentaire != '1' ? element.total_client_visite_intourne : 0;
      vm.t_cls_programme_not_visite += element.commentaire != '1' ? element.cls_programme_not_visite : 0;
      vm.t_total_client_visite_outtourne += element.commentaire != '1' ? element.total_client_visite_outtourne : 0;
      vm.t_total_client_visite += element.commentaire != '1' ? element.total_client_visite : 0;
      vm.t_total_visites_distinct += element.commentaire != '1' ? element.total_visites_distinct : 0;
    })
    vm.t_percent = vm.t_total_visite_planifiee ? vm.t_total_client_visite_intourne * 100 / vm.t_total_visite_planifiee : 0;

  }


  vm.exportTourneeByIntervalUsers = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      user_code: vm.filterdate.user_code
    }
    WS.getData('rapports/exportTourneeByIntervalUsers', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.visites_per_user = response.data;
          console.log(vm.visites_per_user);

          var filename = 'reporting-visites-' + vm.filterdate.date_debut + '_' + vm.filterdate.date_fin + '_' + vm.filterdate.user_code;

          var file = {
            sheetid: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            headers: true,
            caption: {
              title: 'Rapport-Tournee-' + moment().format("DD-MM-YYYY HH:mm:ss"),
              style: 'font-size: 50px; color:blue;'
            },
            column: {
              style: 'font-size:20px;background:#d1d1d1;'
            },
            columns: [

              { columnid: 'code', title: 'Code' },
              { columnid: 'user', title: 'User' },
              { columnid: 'depot', title: 'Dépot' },
              { columnid: 'total_visite_planifiee', title: 'PROGRAMME' },
              { columnid: 'total_client_visite_intourne', title: 'PROGRAMME VISITE' },
              { columnid: 'cls_programme_not_visite', title: 'PROGRAMME NON VISITE' },
              { columnid: 'total_client_visite_outtourne', title: 'VISITE HORS TOURNEE' },
              { columnid: 'total_client_visite', title: 'TOTAL VISITES' },
              // { columnid: 'total_client_visite', title: 'REUSSITE TOURNEE' },
              { columnid: 'total_visite_non_livre', title: 'VISITES NON LIVRES' },
              { columnid: 'total_bls', title: 'NB BONS DE LIVRAISON' },
              { columnid: 'total_avoirs', title: 'NB AVOIRS' },
              { columnid: 'total_commandes', title: 'NB COMMANDES' },
              { columnid: 'nvClients', title: 'NOBRE DE CREATION CLIENTS' },
              { columnid: 'cls_sans_tournee', title: 'NB CLIENTS SANS TOURNEE' },



            ],
            row: {
              style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
              }
            }
          };
          alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }



  /**
   * Rapport 39
   */

  vm.getActivites = function () {
    WS.get('activites', vm.access)
      .then(function (response) {
        if (response.status == 200) {
          vm.activites = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.getTypeClients = function () {
    WS.get('type_clients')
      .then(function (response) {
        if (response.status == 200) {
          vm.type_clients = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }
  vm.getSpecialites = function () {
    WS.get('specialites')
      .then(function (response) {
        if (response.status == 200) {
          vm.specialites = response.data;
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }




  vm.getTourneeByRegion = () => {
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      region_code: vm.filterdate.region_code
    }
    WS.getData('rapports/getTourneeByRegion', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.users_rapports = response.data;
          vm.tournees_zone = response.data.tournees;
          vm.visites_zone = response.data.visites;
          console.log(vm.tournees_zone);
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }




  vm.changeSelectedUsers = () => {
    vm.selected_commercials = [];
    vm.commercial
    var ths = `<th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>`;
    _.each(vm.filterdate.user_codes, element => {
      _.each(vm.commercial, commercial => {
        if (element == commercial.code) {
          vm.selected_commercials.push(commercial);
          ths += `
                        <th class="text-center" title="visite planifier" style="border-left: 2px solid #000">P</th>
                        <th class="text-center" title="total visites">V</th>
                        <th class="text-center" title="visite planifier">VP</th>
                        <th class="text-center" title="planifier non visites">R</th>
                        <th class="text-center" title="visite hors tournee">H</th>
                        <th class="text-center" title="Non planifié non visité">NN</th>
                        <th class="text-center" title="taux de couverture (V / nbr de clients)">TC</th>
                        <th class="text-center" title="respect tournee (VP/P)">TR</th>
                    `;
        }
      })
    })
    ths += `
            <th class="text-center" title="visite planifier" style="border-left: 2px solid #000">P</th>
            <th class="text-center" title="total visites">V</th>
            <th class="text-center" title="visite planifier">VP</th>
            <th class="text-center" title="planifier non visites">R</th>
            <th class="text-center" title="visite hors tournee">H</th>
            <th class="text-center" title="Non planifié non visité">NN</th>
            <th class="text-center" title="taux de couverture (V / nbr de clients)">TC</th>
            <th class="text-center" title="respect tournee (VP/P)" style="border-right: 2px solid #000">TR</th>
        `;
    vm.ths = vm.trustHtml(ths);
  }

  vm.trustHtml = function (html) {
    return $sce.trustAsHtml(html);
  };

  vm.getTourneeByRegionUser = () => {
    vm.loading_request = true;
    var data = {
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      region_code: vm.filterdate.region_code,
      user_codes: vm.filterdate.user_codes,
      activite_codes: vm.filterdate.activite_codes,
      specialites: vm.filterdate.specialites,
      typeclients_codes: vm.filterdate.typeclients_codes
    }
    WS.post('rapports/getTourneeByRegionUser', data)
      .then(function (response) {
        if (response.status == 200) {
          console.log(response.data);
          vm.tournee_activites = response.data.tournees;

          vm.total_tournees = response.data.total_tournees;

          vm.total_visites = response.data.total_visites;

          vm.visites_in_tournees = response.data.visites_in_tournees;

          vm.visites_out_tournees = response.data.visites_out_tournees;

          vm.programme_non_visite = response.data.programme_non_visite;

          vm.non_visite_non_planifie = response.data.non_visite_non_planifie;

          vm.nbr_cls = response.data.nbr_cls;

          //total tournees :
          /**
           * create all tbodys table
           */
          console.log('called method getAlltbodys');




          vm.getAlltbodys();


          $scope.$apply();

        } else { }
      })
      .then(null, function (error) {
        vm.loading_request = false;
        console.log(error);
      });
  }

  vm.convertToNumber = (number) => {
    if (number !== "undefined" && number !== undefined) {
      var number = Number(number);
      if (isNaN(number)) {
        return 0;
      } else {
        return Number(number);
      }
    } else {
      return 0;
    }

  }
  vm.getAlltbodys = () => {
    //console.log('called method getAlltbodys success 1');

    var tbodys = '';
    //if(vm.total_tournees.length>0){
    //console.log('called method getAlltbodys success 2');

    _.each(vm.tournee_activites, (zones, activite_code) => {
      tbodys += ``;
      _.each(zones, (zone, zone_code) => {
        tbodys += `<tr>
                    <td class="text-center" style="background: antiquewhite;">${activite_code}</td>
                    <td class="text-center" style="background: antiquewhite;">${zone_code}</td>
                    <td class="text-center" style="background: antiquewhite;">
                        <a ng-click="vm.getClientByZoneActivite('${zone_code}', '${activite_code}');"
                           data-toggle="modal" data-target=".rapport_40_modal">
                        ${vm.convertToNumber(vm.nbr_cls[activite_code + '_' + zone_code])}
                        </a>
                    </td>`;


        _.each(vm.selected_commercials, com => {
          var index_activite = activite_code + '_' + zone_code + '_' + com.code;
          tbodys += `
                                <td class="text-center" style="border-left: 2px solid #000">
                                    <a ng-click="vm.detailsTourneesPlanifie('${zone_code}', '${activite_code}', '${com.code}')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                        ${vm.convertToNumber(vm.total_tournees[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '${com.code}', 'all')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.total_visites[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '${com.code}', 'in_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                        ${vm.convertToNumber(vm.visites_in_tournees[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsPlanifieNonVisite('${zone_code}', '${activite_code}', '${com.code}')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.programme_non_visite[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '${com.code}', 'out_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.visites_out_tournees[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsNVisiteNPlanifie('${zone_code}', '${activite_code}', '${com.code}')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.non_visite_non_planifie[index_activite])}
                                    </a>
                                </td>
                                <td class="text-center" title="visites / nbr de clients">
                                        ${vm.convertToNumber(vm.total_visites[index_activite] * 100 / vm.convertToNumber(vm.nbr_cls[activite_code + '_' + zone_code])).toFixed(0)} %
                                </td>
                                <td class="text-center">
                                    ${vm.convertToNumber(vm.total_tournees[index_activite]) ? (vm.convertToNumber(vm.visites_in_tournees[index_activite]) * 100 / vm.convertToNumber(vm.total_tournees[index_activite])).toFixed(0) : 0} %
                                </td>`;
        });
        var total_activite_zone = activite_code + '_' + zone_code;
        tbodys += `
                                <td class="text-center"  style="border-left: 2px solid #000;">
                                    <a ng-click="vm.detailsTourneesPlanifie('${zone_code}', '${activite_code}', '')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.total_tournees[total_activite_zone] ? vm.convertToNumber(vm.total_tournees[total_activite_zone]) : 0}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '', 'all')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.total_visites[total_activite_zone])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '', 'in_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.visites_in_tournees[total_activite_zone])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsPlanifieNonVisite('${zone_code}', '${activite_code}', '')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.programme_non_visite[total_activite_zone])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsVisitesRap40('${zone_code}', '${activite_code}', '', 'out_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.visites_out_tournees[total_activite_zone])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a ng-click="vm.detailsNVisiteNPlanifie('${zone_code}', '${activite_code}', '')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.non_visite_non_planifie[total_activite_zone])}
                                    </a>
                                </td>
                                <td class="text-center">
                                    ${(vm.convertToNumber(vm.total_visites[total_activite_zone]) * 100 / vm.convertToNumber(vm.nbr_cls[total_activite_zone])).toFixed(0)} %
                                </td>
                                <td class="text-center" style="border-right: 2px solid #000;">
                                    ${vm.convertToNumber(vm.total_tournees[total_activite_zone]) ? (vm.convertToNumber(vm.visites_in_tournees[total_activite_zone]) * 100 / vm.convertToNumber(vm.total_tournees[total_activite_zone])).toFixed(0) : 0} %
                                </td>
                            </tr>`;
      })
      tbodys += `<tr  style="border-bottom: 2px solid #000;">
                    <td class="text-center" colspan="2" style="background: aquamarine;">Total</td>
                    <td class="text-center" style="background: aquamarine;"> ${vm.nbr_cls[activite_code]}  </td>`
      _.each(vm.selected_commercials, com => {
        var total_activite_commercial = activite_code + '_' + com.code;
        tbodys += `<td class="text-center"  style="border-left: 2px solid #000;background: aquamarine;">
                                    <a ng-click="vm.detailsTourneesPlanifie('', '${activite_code}', '${com.code}')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                        ${vm.convertToNumber(vm.total_tournees[total_activite_commercial])}
                                    </a>
                                </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '${com.code}', 'all')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                                    ${vm.convertToNumber(vm.total_visites[total_activite_commercial])}
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '${com.code}', 'in_tournee')"
                                        data-toggle="modal" data-target=".rapport_40_modal">
                                ${vm.convertToNumber(vm.visites_in_tournees[total_activite_commercial])}
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsPlanifieNonVisite('', '${activite_code}', '${com.code}')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                                ${vm.convertToNumber(vm.programme_non_visite[total_activite_commercial])}
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '${com.code}', 'out_tournee')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                                ${vm.convertToNumber(vm.visites_out_tournees[total_activite_commercial])}
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                <a ng-click="vm.detailsNVisiteNPlanifie('', '${activite_code}', '${com.code}')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                                ${vm.convertToNumber(vm.non_visite_non_planifie[total_activite_commercial])}
                                </a>
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                            ${(vm.convertToNumber(vm.total_visites[total_activite_commercial]) * 100 / vm.convertToNumber(vm.nbr_cls[activite_code])).toFixed(0)} %
                            </td>
                            <td class="text-center"style="background: aquamarine;">
                                ${vm.convertToNumber(vm.total_tournees[total_activite_commercial]) ? (vm.convertToNumber(vm.visites_in_tournees[total_activite_commercial]) * 100 / vm.convertToNumber(vm.total_tournees[total_activite_commercial])).toFixed(0) : 0} %
                            </td>`
      });
      tbodys += `
                        <td class="text-center" style="border-left: 2px solid #000;background: aquamarine;">
                            <a ng-click="vm.detailsTourneesPlanifie('', '${activite_code}', '')"
                                data-toggle="modal" data-target=".rapport_40_modal">
                            ${vm.convertToNumber(vm.total_tournees[activite_code])}
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '', 'all')"
                                data-toggle="modal" data-target=".rapport_40_modal">
                                ${vm.convertToNumber(vm.total_visites[activite_code])}
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '', 'out_tournee')"
                                data-toggle="modal" data-target=".rapport_40_modal">
                            ${vm.convertToNumber(vm.visites_in_tournees[activite_code])}
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            ${vm.convertToNumber(vm.programme_non_visite[activite_code])}
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            <a ng-click="vm.detailsVisitesRap40('', '${activite_code}', '', 'out_tournee')"
                                data-toggle="modal" data-target=".rapport_40_modal">
                            ${vm.convertToNumber(vm.visites_out_tournees[activite_code])}
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            <a ng-click="vm.detailsNVisiteNPlanifie('', '${activite_code}', '')"
                                    data-toggle="modal" data-target=".rapport_40_modal">
                            ${vm.convertToNumber(vm.non_visite_non_planifie[activite_code])}
                            </a>
                        </td>
                        <td class="text-center"style="background: aquamarine;">
                            ${(vm.convertToNumber(vm.total_visites[activite_code]) * 100 / vm.convertToNumber(vm.nbr_cls[activite_code])).toFixed(0)} %
                        </td>
                        <td class="text-center"style="border-right: 2px solid #000;background: aquamarine;">
                        ${vm.convertToNumber(vm.total_tournees[activite_code]) ? (vm.convertToNumber(vm.visites_in_tournees[activite_code]) * 100 / vm.convertToNumber(vm.total_tournees[activite_code])).toFixed(0) : 0} %
                        </td>
                    </tr>`;
    })
    //}

    console.log('fin');
    //console.log(tbodys);

    vm.tbodys = vm.trustHtml(tbodys);
    //vm.tbodys = $compile(vm.tbodys)($scope);
    //console.log(vm.tbodys);
    vm.loading_request = false;

  }



  vm.getClientByZoneActivite = (zone_code, activite_code) => {
    WS.post('rapports/getClientByZoneActivite', {
      zone_code, activite_code,
      typeclients_codes: vm.filterdate.typeclients_codes,
      specialites: vm.filterdate.specialites
    }).then((response) => {
      vm.clients = response.data.clients;
      vm.entetes = response.data.entetes;
      vm.credits_cls = response.data.credits;
      vm.center_cls = response.data.center;


      //maps
      $('#mapsContainer').empty();
      $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

      const latitude = vm.center_cls.latitude;
      const longitude = vm.center_cls.longitude;
      vm.zoomRouting = 11;
      var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
        foo: 'bar',
        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
      }).addTo(mymap);
      var redMarker = L.ExtraMarkers.icon({
        icon: 'fa-home',
        markerColor: 'blue',
        shape: 'circle', //'circle', 'square', 'star', or 'penta'
        prefix: 'fa'
      });

      _.each(vm.clients, element => {
        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
        var info = $compile(`
                                                 <div id="bodyContent" style="width=300px;">
                                                      <p class="text-center">
                                                        <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                            Modifier
                                                        </a>
                                                      </p>
                                                      <b>Client</b> : ${element.client}</a></br>
                                                      <b>Magasin</b> : ${element.magasin}</a></br>
                                                      <b>Code</b> : ${element.client_code}</a></br>
                                                      <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                      <b>Route</b> : ${element.routing_code}</br>
                                                      <b>Mobile</b> : ${element.mobile}</br>
                                                    </div>
                                                `)($scope);
        var popup = L.popup({
          maxWidth: 600,
          minWidth: 200
        }).setContent(info[0])
        marker.bindPopup(popup);
      })


      $scope.$apply();
    },
      (error) => {
        console.error(error);
      })
  }


  vm.detailsTourneesPlanifie = (zone_code, activite_code, user_code) => {
    WS.post('rapports/detailsTourneesPlanifie', {
      zone_code, activite_code, user_code,
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      typeclients_codes: vm.filterdate.typeclients_codes,
      specialites: vm.filterdate.specialites,

    }).then((response) => {
      vm.clients = response.data.clients;
      vm.center_cls = response.data.center;

      //maps
      $('#mapsContainer').empty();
      $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

      const latitude = vm.center_cls.latitude;
      const longitude = vm.center_cls.longitude;
      vm.zoomRouting = 11;
      var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
        foo: 'bar',
        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
      }).addTo(mymap);
      var redMarker = L.ExtraMarkers.icon({
        icon: 'fa-home',
        markerColor: 'blue',
        shape: 'circle', //'circle', 'square', 'star', or 'penta'
        prefix: 'fa'
      });

      _.each(vm.clients, element => {
        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
        var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                                <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                </p>
                                                <b>Client</b> : ${element.client}</a></br>
                                                <b>Magasin</b> : ${element.magasin}</a></br>
                                                <b>Code</b> : ${element.client_code}</a></br>
                                                <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                <b>Route</b> : ${element.routing_code}</br>
                                                <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
        var popup = L.popup({
          maxWidth: 600,
          minWidth: 200
        }).setContent(info[0])
        marker.bindPopup(popup);
      })


      $scope.$apply();
    },
      (error) => {
        console.error(error);
      })
  }

  vm.detailsVisitesRap40 = (zone_code, activite_code, user_code, type) => {
    WS.post('rapports/detailsVisitesRap40', {
      zone_code,
      activite_code,
      user_code,
      type,
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      typeclients_codes: vm.filterdate.typeclients_codes,
      specialites: vm.filterdate.specialites

    }).then((response) => {
      vm.clients = response.data.clients;
      vm.center_cls = response.data.center;

      //maps
      $('#mapsContainer').empty();
      $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

      const latitude = vm.center_cls.latitude;
      const longitude = vm.center_cls.longitude;
      vm.zoomRouting = 11;
      var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
        foo: 'bar',
        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
      }).addTo(mymap);
      var redMarker = L.ExtraMarkers.icon({
        icon: 'fa-home',
        markerColor: 'blue',
        shape: 'circle', //'circle', 'square', 'star', or 'penta'
        prefix: 'fa'
      });

      _.each(vm.clients, element => {
        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
        var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                                <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                </p>
                                                <b>Client</b> : ${element.client}</a></br>
                                                <b>Magasin</b> : ${element.magasin}</a></br>
                                                <b>Code</b> : ${element.client_code}</a></br>
                                                <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                <b>Route</b> : ${element.routing_code}</br>
                                                <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
        var popup = L.popup({
          maxWidth: 600,
          minWidth: 200
        }).setContent(info[0])
        marker.bindPopup(popup);
      })


      $scope.$apply();
    },
      (error) => {
        console.error(error);
      })
  }

  vm.detailsPlanifieNonVisite = (zone_code, activite_code, user_code) => {
    WS.post('rapports/detailsPlanifieNonVisite', {
      zone_code, activite_code, user_code,
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      typeclients_codes: vm.filterdate.typeclients_codes,
      specialites: vm.filterdate.specialites
    }).then((response) => {
      vm.clients = response.data.clients;
      vm.center_cls = response.data.center;

      //maps
      $('#mapsContainer').empty();
      $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

      const latitude = vm.center_cls.latitude;
      const longitude = vm.center_cls.longitude;
      vm.zoomRouting = 11;
      var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
        foo: 'bar',
        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
      }).addTo(mymap);
      var redMarker = L.ExtraMarkers.icon({
        icon: 'fa-home',
        markerColor: 'blue',
        shape: 'circle', //'circle', 'square', 'star', or 'penta'
        prefix: 'fa'
      });

      _.each(vm.clients, element => {
        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
        var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                                <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                </p>
                                                <b>Client</b> : ${element.client}</a></br>
                                                <b>Magasin</b> : ${element.magasin}</a></br>
                                                <b>Code</b> : ${element.client_code}</a></br>
                                                <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                <b>Route</b> : ${element.routing_code}</br>
                                                <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
        var popup = L.popup({
          maxWidth: 600,
          minWidth: 200
        }).setContent(info[0])
        marker.bindPopup(popup);
      })


      $scope.$apply();
    },
      (error) => {
        console.error(error);
      })
  }


  vm.detailsNVisiteNPlanifie = (zone_code, activite_code, user_code) => {
    WS.post('rapports/detailsNVisiteNPlanifie', {
      region_code: vm.filterdate.region_code,
      zone_code,
      activite_code,
      user_code,
      date_debut: vm.filterdate.date_debut,
      date_fin: vm.filterdate.date_fin,
      typeclients_codes: vm.filterdate.typeclients_codes,
      specialites: vm.filterdate.specialites

    }).then((response) => {
      vm.clients = response.data.clients;
      vm.center_cls = response.data.center;

      //maps
      $('#mapsContainer').empty();
      $('#mapsContainer').html('<div style="width: 100%; height: 75vh" id="mapsHistoryContainer"></div>');

      const latitude = vm.center_cls.latitude;
      const longitude = vm.center_cls.longitude;
      vm.zoomRouting = 11;
      var mymap = L.map('mapsHistoryContainer').setView([latitude, longitude], vm.zoomRouting);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
        foo: 'bar',
        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
      }).addTo(mymap);
      var redMarker = L.ExtraMarkers.icon({
        icon: 'fa-home',
        markerColor: 'blue',
        shape: 'circle', //'circle', 'square', 'star', or 'penta'
        prefix: 'fa'
      });

      _.each(vm.clients, element => {
        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
        var info = $compile(`
                                            <div id="bodyContent" style="width=300px;">
                                                <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                </p>
                                                <b>Client</b> : ${element.client}</a></br>
                                                <b>Magasin</b> : ${element.magasin}</a></br>
                                                <b>Code</b> : ${element.client_code}</a></br>
                                                <b>Code_a_barre</b> : ${element.code_a_barre}</br>
                                                <b>Route</b> : ${element.routing_code}</br>
                                                <b>Mobile</b> : ${element.mobile}</br>
                                            </div>
                                        `)($scope);
        var popup = L.popup({
          maxWidth: 600,
          minWidth: 200
        }).setContent(info[0])
        marker.bindPopup(popup);
      })


      $scope.$apply();
    },
      (error) => {
        console.error(error);
      })
  }

  vm.getSuiviEchantillon = function (date_debut, date_fin) {
    console.log(date_debut);
    WS.get('rapports/suiviEchantillon' + '/' + date_debut + '/' + date_fin).then(
      function (response) {
        var count = response.data.length;

        var filename = 'Rapport-Suivi Echantillons-de ' + date_debut + ' à ' + date_fin;

        var file = {
          sheetid: 'Rapport-Suivi Echantillons-' + moment().format("DD-MM-YYYY HH:mm:ss"),
          headers: true,
          caption: {
            title: 'Rapport-Suivi Echantillons-' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;'
          },
          column: {
            style: 'font-size:20px;background:#d1d1d1;'
          },
          columns: [

            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'client_code', title: 'Code prospect' },
            { columnid: 'prospect', title: 'Prospect' },
            { columnid: 'code', title: 'Code article' },
            { columnid: 'libelle', title: 'Produit' },
            { columnid: 'echantillon', title: 'echantillon ' }

          ],
          row: {
            style: function (sheet, row, rowidx) {
              return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
          }
        };

        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  vm.getHaversine = (latitude_client, longitude_client, latitude, longitude) => {
    const client = { latitude: parseFloat(latitude_client), longitude: parseFloat(longitude_client) }
    const visite = { latitude: parseFloat(latitude), longitude: parseFloat(longitude) }

    if (_.isNaN(client.latitude) || _.isNaN(client.latitude)) {
      return 'PCI'
    }
    else if (_.isNaN(visite.latitude) || _.isNaN(visite.latitude)) {
      return 'PVI'
    } else {
      var position = haversine(client, visite)
      if (position > 1000) {
        position = position / 1000
        position = position.toFixed(3) + ' Km';
      } else {
        position = position.toFixed(3) + ' m';

      }
      return position;
    }
    //return distance en métre
  }


  /**
   * double scroll table
   */
  $("document").ready(function () {
    $("#scroll1 div").width($(".table_scroll").width());
    $("#scroll1").on("scroll", function () {
      $("#scroll2").scrollLeft($(this).scrollLeft());
    });
    $("#scroll2").on("scroll", function () {
      $("#scroll1").scrollLeft($(this).scrollLeft());
    });
  });




  vm.getAssortimentsRap41 = () => {
    var data = {
      //client
      categoriecommercial_code: vm.categoriecommercial_code,
      activite_code: vm.activite_code,
      zone_code: vm.zone_code,
      region_code: vm.region_code,
      gouvernorat_code: vm.gouvernorat_code,
      delegation_code: vm.delegation_code,
      routing_code: vm.routing_code,
      user_code: vm.user_code,
      specialite: vm.specialite,
      type_client: vm.type_client,
      //produit
      gamme_code: vm.gamme_code,
      famille_code: vm.famille_code,
      sousfamille_code: vm.sousfamille_code,
      marque_code: vm.marque_code,
      //interval
      date_debut: vm.date_debut,
      date_fin: vm.date_fin
    };
    if (vm.date_debut && vm.date_fin) {

      WS.getData('rapports/getAssortimentsRap41', data)
        .then(function (response) {
          if (response.status == 200) {
            vm.list_prods_assortiment = response.data.produits.assortiment;
            vm.list_prods_prix = response.data.produits.prix;
            vm.list_prods_comparaison = response.data.produits.comparaison;
            vm.list_prods_stock = response.data.produits.stock;
            vm.list_clients = response.data.clients;
            vm.list_relevet_prix = response.data.relevet_prix;
            vm.qualite_affiche = response.data.qualite_affiche;
            // console.log(vm.list_prods)
            // console.log(vm.list_clients)

            vm.qualites = response.data.qualites;
            vm.qualites_details = response.data.qualites_details;
            vm.visibilites = response.data.visibilites;
            vm.visibilites_details = response.data.visibilites_details;
            // vm.list_gammes = _.groupBy(vm.list_prods, 'gamme');
            // console.log(vm.list_gammes)

            //vm.totalprods = Object.keys(vm.list_prods).length;
            // console.log(vm.totalprods)
            $scope.$apply();

            $("document").ready(function () {
              $("#scroll1_41 div").width($(".table_scroll_41").width());
              $("#scroll2_41").width($(".table_scroll_41").width());
              $("#scroll1_41").on("scroll", function () {
                $("#scroll2_41").scrollLeft($(this).scrollLeft());
              });
              $("#scroll2_41").on("scroll", function () {
                $("#scroll1_41").scrollLeft($(this).scrollLeft());
              });
            });
          } else { }
        })
        .then(null, function (error) {
          console.log(error);
        });
    } else {
      swal(
        'Oups',
        'Veuillez bien choisir date début et date fin',
        'warning'
      )
    }

  }



  /**
   * Rapport 42 Liste clients by users
   */


  vm.rapport_42_listClient = () => {
    WS.getData('rapports/rapport_42_listClient', vm.filterdate)
      .then(function (response) {
        vm.last7Month = response.data.last7Month;
        vm.lastDate = response.data.lastDate;
        vm.yearMonth = response.data.yearMonth;

        vm.creditMonth = response.data.creditMonth;
        vm.recouvrementMonth = response.data.recouvrementMonth;
        vm.creditAll = response.data.creditAll;
        vm.venteMois = response.data.venteMois;
        vm.vente7Month = response.data.vente7Month;
        vm.dernier_facture = response.data.dernier_facture;
        vm.dernier_recouvrement = response.data.dernier_recouvrement;

        vm.vente_mois = response.data.vente_mois;
        vm.credit_mois = response.data.credit_mois;
        vm.recouvrement_mois = response.data.recouvrement_mois;
        vm.correction_mois = response.data.correction_mois;
        vm.sommeMoyenneVente = response.data.sommeMoyenneVente;
        vm.sommeCreditMonth = response.data.sommeCreditMonth;
        vm.creditAllForCommercial = response.data.creditAllForCommercial;
        vm.nbr_recouvrement_mois = response.data.nbr_recouvrement_mois;


        vm.nbr_visites = response.data.nbr_visites;
        vm.nbr_bls = response.data.nbr_bls;


        $scope.$apply();

      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  $(document).ready(function () {

    $(".months_rapport42").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.filterdate.date = date;
        $scope.$apply();
        //console.log(vm.month);
      }
    });
  });



  vm.gratuitesAccessoires_R44 = () => {

    vm.th_libelles_types = [
      { code: 'cadeaux', libelle: 'Cadeaux' },
      { code: 'retour', libelle: 'Retour Client' },
      { code: 'vente', libelle: 'Vente' },
      { code: 'gratuite', libelle: 'Gratuite' },
      { code: 'dlcC', libelle: 'Dlc Client' },
      { code: 'commande', libelle: 'Commande' }
    ];

    vm.td_libelles_types = [
      { code: 'cadeaux', libelle: 'Cadeaux', type: 'quantite' },
      { code: 'cadeaux', libelle: 'Cadeaux', type: 'chiffre' },

      { code: 'retourC', libelle: 'Retour Client', type: 'quantite' },
      { code: 'retourC', libelle: 'Retour Client', type: 'chiffre' },
      { code: 'vente', libelle: 'Vente', type: 'quantite' },
      { code: 'vente', libelle: 'Vente', type: 'chiffre' },
      { code: 'gratuite', libelle: 'Gratuite', type: 'quantite' },
      { code: 'gratuite', libelle: 'Gratuite', type: 'chiffre' },
      { code: 'dlcC', libelle: 'Dlc Client', type: 'quantite' },
      { code: 'dlcC', libelle: 'Dlc Client', type: 'chiffre' },
      { code: 'commande', libelle: 'Commande', type: 'quantite' },
      { code: 'commande', libelle: 'Commande', type: 'chiffre' }
    ];

    WS.getData('rapports/gratuitesAccessoires_R44', vm.search44)
      .then(function (response) {
        console.log(response.data);
        vm.resultat_44 = response.data.collection;
        vm.req_44 = response.data.results;

        //console.log('vm.req_44', vm.req_44);
        var grpbytype = _.groupBy(vm.req_44, function (value) { return value.type; });

        vm.total_44 = _.mapObject(grpbytype, function (value, key) {
          return {
            //type : key,
            chiffre: _.reduce(value, function (memo, num) { return memo + num.chiffre; }, 0),
            quantite: _.reduce(value, function (memo, num) { return memo + num.quantite; }, 0)
          }
        });
        //console.log('total_44', vm.total_44);

        // vm.total_44 = _.groupBy(total_44, function(value){ return value.type; });

        // console.log('vm.total_44', vm.total_44);


        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  // rapport 27
  vm.list_client_cloture = {};
  vm.getreglementByCommercial = function (date_debut, date_fin, commercial = null, cloture = '0') {
    var url = 'rapports/getAllRegelementByCommercial/' + date_debut + "/" + date_fin + "/" + commercial + "/" + cloture;
    WS.getData(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.reglementByCommercial = response.data;
          _.each(vm.reglementByCommercial, (element, index) => {
            vm.list_client_cloture[element.code] = element.cloture;
          });
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.list_client_cloture = {};
  vm.rapport27_cloture_all = () => {
    if (vm.rap_27_cochetout == 1) {
      _.each(vm.reglementByCommercial, (element, index) => {
        vm.list_client_cloture[element.code] = 1;
      });
    } else {
      _.each(vm.reglementByCommercial, (element, index) => {
        vm.list_client_cloture[element.code] = 0;
      });
    }
  }


  vm.cloture_paiement = (commercial = null, date_debut, date_fin) => {
    console.log(vm.list_client_cloture)
    WS.post('rapports/cloture_paiement', { clients: vm.list_client_cloture, commercial, date_debut, date_fin })
      .then(function (response) {
        if (response.status == 200) {

          swal('Succés', 'Action effectuée avec succès');
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.getproduitsAssortiment = function (date_debut, date_fin) {
    vm.tr_causes = '';
    WS.get('assortiments/rapports' + '/' + date_debut + '/' + date_fin)
      .then(function (response) {
        if (response.status == 200) {
          vm.produits_assortiment = response.data.prod_cats;
          vm.categories_commercials = response.data.categories;
          vm.list_prods = response.data.produits;
          vm.causes = response.data.causes;

          vm.tr_causes = `<th class="text-center"></th><th class="text-center"></th>`;
          _.each(vm.categories_commercials, (element, index) => {
            _.each(vm.causes, (cause, indCause) => {
              vm.tr_causes += `<th class="">${cause.libelle}</th>`;
            })
          });

          vm.tr_causes = vm.trustHtml(vm.tr_causes);


          vm.tr_prods = ``;
          _.each(vm.list_prods, (prod, indProd) => {
            vm.tr_prods += `<tr>
                        <td class="text-center">${prod.code}</td>
                        <td class="text-center">${prod.produit}</td>`;

            _.each(vm.categories_commercials, (cat, index) => {
              _.each(vm.causes, (cause, indCause) => {
                var cause_code = cause.code.toLowerCase();
                var val = vm.produits_assortiment[prod.code + '_' + cat.code + '_' + cause_code] ? vm.produits_assortiment[prod.code + '_' + cat.code + '_' + cause_code].nbr_client : '';
                vm.tr_prods += `<td class="text-center" title="code : ${prod.code + '_' + cat.code + '_' + cause_code}" > ${val}</td>`;
              })
            });
            vm.tr_prods += '</tr>';

          })

          // vm.tr_prods += '</tr>';
          vm.tr_prods = vm.trustHtml(vm.tr_prods);
          $scope.$apply();
        } else { }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.liste_causes = function () {
    var url = 'rapports/liste_causes';
    WS.getData(url)
      .then(function (response) {
        if (response.status == 200) {
          vm.causes = response.data;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };



  vm.getTicketsRapport45 = () => {
    var url = 'rapports/getTicketsRapport45';
    WS.getData(url, vm.search45)
      .then(function (response) {
        if (response.status == 200) {
          vm.tickets = response.data;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }



  $("document").ready(function () {
    $("#scroll46-1 div").width($("#table_46").width());
    $("#scroll46-1").on("scroll", function () {
      $("#scroll46-2").scrollLeft($(this).scrollLeft());
    });
    $("#scroll46-2").on("scroll", function () {
      $("#scroll46-1").scrollLeft($(this).scrollLeft());
    });
  });
  vm.search46 = {};
  vm.search46.categoriecommercial_code = '';
  vm.search46.activite_code = '';
  vm.search46.specialite = '';
  vm.search46.gouvernorat_code = '';
  vm.search46.delegation_code = '';
  vm.search46.region_code = '';
  vm.search46.zone_code = '';
  vm.search46.routing_code = '';
  vm.search46.user_code = '';


  vm.submitRapport64 = () => {
    if (vm.search46.affichage_intervalle == "Semaine") {
      vm.get_weeksIntervalsR46();
    } else {
      vm.get_MonthsIntervalsR46();
    }
  }

  vm.get_weeksIntervalsR46 = () => {
    vm.loading_request = true;
    var url = 'rapports/getWeeksByMonthR46';
    WS.getData(url, vm.search46)
      .then(function (response) {
        vm.loading_request = false;
        if (response.status == 200) {

          vm.weeks = response.data.weeks;
          vm.categories = response.data.categories;
          vm.ventes = response.data.ventes;
          vm.lignes_ventes = response.data.lignes_ventes;
          //vm.rest_of_clients = response.data.rest_of_clients;
          vm.total_of_lignes = response.data.total_of_lignes;
          vm.total_of_lignes_categorie = response.data.total_of_lignes_categorie;

          vm.selected_code_categorie = response.data.code_categorie;

          vm.total_vente_categories = response.data.total_vente_categories;

          /**
           * th categories
           */
          var ths_cats = `<th class="text-center"></th>
                                    <th class="text-center"></th>
                                    <th class="text-center"></th>`;

          _.each(vm.weeks, (week, indeWeek) => {

            _.each(vm.categories, (cat, indexCat) => {
              var brd = indexCat == 0 ? 'brd-left' : '';
              var col_white = indexCat % 2 > 0 ? 'bg-aliceblue' : ''
              ths_cats += `<th class="text-center ${brd}  ${col_white}" colspan="3">${cat.libelle}</th> `;
            })
            ths_cats += `<th class="text-center brd-left" colspan="4">Total</th> `;

          })

          if (vm.selected_code_categorie) {
            ths_cats += ` <th class="text-center brd-left succ-yellow" colspan="5>Total Categorie</th>`;
          }
          ths_cats += ` <th class="text-center brd-left succ-blue" colspan="5>Total</th>`;

          vm.ths_cats = vm.trustHtml(ths_cats);


          /**
           * th title
           */
          var ths_title = `<th class="text-center">N°</th><th class="text-center">Code</th>
                                        <th class="text-center">Nom</th>`;

          _.each(vm.weeks, (week, indeWeek) => {

            _.each(vm.categories, (cat, indexCat) => {
              var brd = indexCat == 0 ? 'brd-left' : '';
              var col_white = indexCat % 2 > 0 ? 'bg-aliceblue' : ''

              ths_title += `
                            <th class="text-center ${brd} ${col_white}">Quantite</th>
                            <th class="text-center  ${col_white}">Chiffre</th>
                            <th class="text-center  ${col_white}">Poids</th>
                            `;
            })

            ths_title += `
                            <th class="text-center brd-left succ-green">Quantite</th>
                            <th class="text-center succ-green">Chiffre</th>
                            <th class="text-center succ-green">Poids</th>
                            <th class="text-center succ-green">Visite</th>
                            `;
          });

          if (vm.selected_code_categorie) {
            ths_title += ` <th class="text-center succ-yellow brd-left">Quantite</th>
                        <th class="text-center succ-yellow">Chiffre</th>
                        <th class="text-center succ-yellow">Poids</th>
                        <th class="text-center succ-yellow">Nbr Vente</th>
                        <th class="text-center succ-yellow">Respect</th>`;
          }

          ths_title += ` <th class="text-center succ-blue brd-left">Quantite</th>
                        <th class="text-center succ-blue">Chiffre</th>
                        <th class="text-center succ-blue">Poids</th>
                        <th class="text-center succ-blue">Visites</th>
                        <th class="text-center succ-blue">Respect</th>`;


          vm.ths_title = vm.trustHtml(ths_title);


          /**
           * tr ventes
           */
          vm.tbody_ventes = ''

          vm.total_respect = 0;
          vm.total_respect_categorie = 0;

          vm.list_client_visited = {};
          var class_name_green = '';

          vm.compteur_vente = 0;
          var compteur_vente = 0;
          vm.nbr_vente_distinct_week = 0;
          _.each(vm.lignes_ventes, (ligne, index) => {

            vm.tbody_ventes += `<tr>
                        <td class="text-center">${index + 1}</td>
                        <td class="text-center">${ligne.code}</td>
                        <td class="text-center">${ligne.name}</td>`;
            var total_respect_lignes = 1;
            var total_respect_lignes_categorie = 1;

            vm.temp_compteur_vente = 0;

            _.each(vm.weeks, (week, indeWeek) => {

              _.each(vm.categories, (cat, indexCat) => {
                var brd = indexCat == 0 ? 'brd-left' : '';
                var col_white = indexCat % 2 == 0 ? 'bg-white' : ''
                var quantite = vm.ventes[week.week][ligne.code + '_' + cat.code] ? vm.ventes[week.week][ligne.code + '_' + cat.code].quantite : '';
                var chiffre = vm.ventes[week.week][ligne.code + '_' + cat.code] ? vm.ventes[week.week][ligne.code + '_' + cat.code].chiffre : '';
                var sum_poids = vm.ventes[week.week][ligne.code + '_' + cat.code] ? vm.ventes[week.week][ligne.code + '_' + cat.code].sum_poids : '';
                vm.tbody_ventes += `
                                <td class="text-center ${brd} ${col_white}">${quantite ? quantite : ''}</td>
                                <td class="text-center ${col_white}">${chiffre ? chiffre : ''}</td>
                                <td class="text-center ${col_white}">${sum_poids ? sum_poids : ''}</td>`;
              })

              //total for selected categorie
              if (vm.selected_code_categorie) {
                var nbr_visite = vm.ventes[week.week + '_' + vm.selected_code_categorie + '_total_categorie'][ligne.code] ?
                  vm.ventes[week.week + '_' + vm.selected_code_categorie + '_total_categorie'][ligne.code].nbr_visite :
                  '';
                var respect_categorie = nbr_visite > 0 ? 1 : 0;
                if (respect_categorie == 0) {
                  total_respect_lignes_categorie = 0;
                }

                //check temp compteur vente
                if (vm.temp_compteur_vente == 0) {
                  vm.temp_compteur_vente = respect_categorie > 0 ? 1 : 0;
                }
              }
              var quantite = vm.ventes[week.week + '_total'][ligne.code] ? vm.ventes[week.week + '_total'][ligne.code].quantite : '';
              var chiffre = vm.ventes[week.week + '_total'][ligne.code] ? vm.ventes[week.week + '_total'][ligne.code].chiffre : '';
              var sum_poids = vm.ventes[week.week + '_total'][ligne.code] ? vm.ventes[week.week + '_total'][ligne.code].sum_poids : '';
              var nbr_visite = vm.ventes[week.week + '_total'][ligne.code] ? vm.ventes[week.week + '_total'][ligne.code].nbr_visite : '';

              var respect = nbr_visite > 0 ? 1 : 0;
              if (respect == 0) {
                total_respect_lignes = 0;
              } else {
                // compteur_vente++;
              }
              if (nbr_visite) {
                if (nbr_visite > 0) {
                  class_name_green = 'succ-green';
                  vm.list_client_visited[ligne.code] = true;
                } else {
                  class_name_blue = '';
                }
              } else {
                class_name_green = '';
              }
              vm.tbody_ventes += `<td class="text-center brd-left  ${class_name_green}"> ${quantite ? quantite : ''} </td>
                                                <td class="text-center  ${class_name_green}"> ${chiffre ? chiffre : ''} </td>
                                                <td class="text-center  ${class_name_green}"> ${sum_poids ? sum_poids : ''}</td>
                                                <td class="text-center ${class_name_green}">${nbr_visite ? nbr_visite : ''} </td>`;
            });

            //
            if (vm.temp_compteur_vente) {
              vm.compteur_vente++
            }

            /**
             * total finale
             */

            //if selected categorie
            if (vm.selected_code_categorie) {
              var class_name_blue = total_respect_lignes_categorie ? 'succ-yellow' : ''
              var temp_obj = vm.total_of_lignes_categorie[ligne.code] ? vm.total_of_lignes_categorie[ligne.code] : { quantite: '', chiffre: '', sum_poids: '', nbr_visite: '' };

              if (temp_obj.nbr_visite) {
                vm.nbr_vente_distinct_week = temp_obj.nbr_visite > 0 ? vm.nbr_vente_distinct_week + 1 : vm.nbr_vente_distinct_week;
                //console.log('vm.nbr_vente_distinct_week', vm.nbr_vente_distinct_week, temp_obj.nbr_visite)
              }
              vm.tbody_ventes += `
                            <td class="text-center ${class_name_blue} brd-left">${temp_obj.quantite ? temp_obj.quantite : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.chiffre ? temp_obj.chiffre : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.sum_poids ? temp_obj.sum_poids : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.nbr_visite ? temp_obj.nbr_visite : ''}</td>
                            <td class="text-center ${class_name_blue}">${total_respect_lignes_categorie}</td>`;

              if (total_respect_lignes_categorie == 1) {
                vm.total_respect_categorie++;
              }
            }
            var class_name_blue = total_respect_lignes ? 'succ-blue' : ''
            var temp_obj = vm.total_of_lignes[ligne.code] ? vm.total_of_lignes[ligne.code] : { quantite: '', chiffre: '', sum_poids: '', nbr_visite: '' };
            vm.tbody_ventes += `
                            <td class="text-center ${class_name_blue} brd-left">${temp_obj.quantite ? temp_obj.quantite : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.chiffre ? temp_obj.chiffre : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.sum_poids ? temp_obj.sum_poids : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.nbr_visite ? temp_obj.nbr_visite : ''}</td>
                            <td class="text-center ${class_name_blue}">${total_respect_lignes}</td>
                        `;

            if (total_respect_lignes == 1) {
              vm.total_respect++;
            }


            vm.tbody_ventes += '</tr>';

          })
          // vm.compteur_vente = compteur_vente;

          vm.tbody_ventes = vm.trustHtml(vm.tbody_ventes);



          /**
           * tr rest of clients
           */
          vm.tbody_rest_of_clients = ''

          //  vm.total_respect = 0;
          // _.each(vm.rest_of_clients, (ligne,index) => {

          //     vm.tbody_rest_of_clients +=`<tr class="dang-red">
          //     <td class="text-center">${index+1}</td>
          //     <td class="text-center">${ligne.code}</td>
          //     <td class="text-center">${ligne.name}</td>`;
          //     var total_respect_lignes = 1;

          //     _.each(vm.weeks, (week, indeWeek) => {
          //         _.each(vm.categories, (cat, indexCat) => {
          //             var brd = indexCat == 0 ? 'brd-left' : '';
          //             var col_white = indexCat % 2 == 0  ? 'bg-white' : ''
          //             var quantite = vm.ventes[week.week] [ligne.code +'_'+cat.code] ? vm.ventes[week.week] [ligne.code +'_'+cat.code].quantite : '';
          //             var chiffre = vm.ventes[week.week] [ligne.code +'_'+cat.code] ? vm.ventes[week.week] [ligne.code +'_'+cat.code].chiffre : '';
          //             var sum_poids = vm.ventes[week.week] [ligne.code +'_'+cat.code] ? vm.ventes[week.week] [ligne.code +'_'+cat.code].sum_poids : '';
          //             vm.tbody_rest_of_clients += `
          //             <td class="text-center ${brd} ${col_white}">${quantite}</td>
          //             <td class="text-center ${col_white}">${chiffre}</td>
          //             <td class="text-center ${col_white}">${sum_poids}</td>`;
          //         })

          //         var quantite = vm.ventes[week.week+'_total'] [ligne.code] ? vm.ventes[week.week+'_total'] [ligne.code].quantite : '';
          //         var chiffre = vm.ventes[week.week+'_total'] [ligne.code] ? vm.ventes[week.week+'_total'] [ligne.code].chiffre : '';
          //         var sum_poids = vm.ventes[week.week+'_total'] [ligne.code] ? vm.ventes[week.week+'_total'] [ligne.code].sum_poids : '';
          //         var nbr_visite = vm.ventes[week.week+'_total'] [ligne.code] ? vm.ventes[week.week+'_total'] [ligne.code].nbr_visite : '';

          //         var respect = nbr_visite>0 ? 1 : 0;
          //         if(respect == 0){
          //             total_respect_lignes=0;
          //         }
          //         vm.tbody_rest_of_clients += `<td class="text-center brd-left  succ-green"> ${quantite} </td>
          //                             <td class="text-center  succ-green"> ${chiffre} </td>
          //                             <td class="text-center  succ-green"> ${sum_poids}</td>
          //                             <td class="text-center succ-green">${nbr_visite} </td>`;
          //     });

          //     // total finale
          //     vm.tbody_rest_of_clients +=`

          //         <td class="text-center ${class_name_blue} brd-left"></td>
          //         <td class="text-center succ-blue"></td>
          //         <td class="text-center succ-blue"></td>
          //         <td class="text-center succ-blue"></td>
          //         <td class="text-center succ-blue">0</td>
          //     `;

          //     // if(total_respect_lignes==1) {
          //     //     vm.total_respect++;
          //     // }

          //     vm.tbody_rest_of_clients += '</tr>';

          // })
          // vm.tbody_rest_of_clients = vm.trustHtml(vm.tbody_rest_of_clients);

          vm.count_list_client_visited = Object.keys(vm.list_client_visited).length
          $scope.$apply();
        }

        //console.log(' vm.list_client_visited',  vm.list_client_visited,   vm.count_list_client_visited )
        $("document").ready(function () {
          $("#scroll46-1 div").width($("#table_46").width());
          $("#scroll46-1").on("scroll", function () {
            $("#scroll46-2").scrollLeft($(this).scrollLeft());
          });
          $("#scroll46-2").on("scroll", function () {
            $("#scroll46-1").scrollLeft($(this).scrollLeft());
          });
        });

      })
      .then(null, function (error) {
        console.log(error);
        vm.loading_request = false;

      });
  }



  vm.get_MonthsIntervalsR46 = () => {
    vm.loading_request = true;
    var url = 'rapports/getMonthsByMonthR46';
    WS.getData(url, vm.search46)
      .then(function (response) {
        vm.loading_request = false;
        if (response.status == 200) {

          vm.weeks = response.data.weeks;
          vm.categories = response.data.categories;
          vm.ventes = response.data.ventes;
          vm.lignes_ventes = response.data.lignes_ventes;
          //vm.rest_of_clients = response.data.rest_of_clients;
          vm.total_of_lignes = response.data.total_of_lignes;
          vm.total_of_lignes_categorie = response.data.total_of_lignes_categorie;

          vm.selected_code_categorie = response.data.code_categorie;

          vm.total_vente_categories = response.data.total_vente_categories;

          /**
           * th categories
           */
          var ths_cats = `<th class="text-center"></th>
                                    <th class="text-center"></th>
                                    <th class="text-center"></th>`;

          _.each(vm.weeks, (week, indeWeek) => {

            _.each(vm.categories, (cat, indexCat) => {
              var brd = indexCat == 0 ? 'brd-left' : '';
              var col_white = indexCat % 2 > 0 ? 'bg-aliceblue' : ''
              ths_cats += `<th class="text-center ${brd}  ${col_white}" colspan="3">${cat.libelle}</th> `;
            })
            ths_cats += `<th class="text-center brd-left" colspan="4">Total</th> `;

          })

          if (vm.selected_code_categorie) {
            ths_cats += ` <th class="text-center brd-left succ-yellow" colspan="5>Total Categorie</th>`;
          }
          ths_cats += ` <th class="text-center brd-left succ-blue" colspan="5>Total</th>`;

          vm.ths_cats = vm.trustHtml(ths_cats);


          /**
           * th title
           */
          var ths_title = `<th class="text-center">N°</th><th class="text-center">Code</th>
                                        <th class="text-center">Nom</th>`;

          _.each(vm.weeks, (week, indeWeek) => {

            _.each(vm.categories, (cat, indexCat) => {
              var brd = indexCat == 0 ? 'brd-left' : '';
              var col_white = indexCat % 2 > 0 ? 'bg-aliceblue' : ''

              ths_title += `
                            <th class="text-center ${brd} ${col_white}">Quantite</th>
                            <th class="text-center  ${col_white}">Chiffre</th>
                            <th class="text-center  ${col_white}">Poids</th>
                            `;
            })

            ths_title += `
                            <th class="text-center brd-left succ-green">Quantite</th>
                            <th class="text-center succ-green">Chiffre</th>
                            <th class="text-center succ-green">Poids</th>
                            <th class="text-center succ-green">Visite</th>
                            `;
          });

          if (vm.selected_code_categorie) {
            ths_title += ` <th class="text-center succ-yellow brd-left">Quantite</th>
                        <th class="text-center succ-yellow">Chiffre</th>
                        <th class="text-center succ-yellow">Poids</th>
                        <th class="text-center succ-yellow">Nbr Vente</th>
                        <th class="text-center succ-yellow">Respect</th>`;
          }

          ths_title += ` <th class="text-center succ-blue brd-left">Quantite</th>
                        <th class="text-center succ-blue">Chiffre</th>
                        <th class="text-center succ-blue">Poids</th>
                        <th class="text-center succ-blue">Visites</th>
                        <th class="text-center succ-blue">Respect</th>`;


          vm.ths_title = vm.trustHtml(ths_title);


          /**
           * tr ventes
           */
          vm.tbody_ventes = ''

          vm.total_respect = 0;
          vm.total_respect_categorie = 0;

          vm.list_client_visited = {};
          var class_name_green = '';

          vm.compteur_vente = 0;
          var compteur_vente = 0;
          vm.nbr_vente_distinct_week = 0;
          _.each(vm.lignes_ventes, (ligne, index) => {

            vm.tbody_ventes += `<tr>
                        <td class="text-center">${index + 1}</td>
                        <td class="text-center">${ligne.code}</td>
                        <td class="text-center">${ligne.name}</td>`;
            var total_respect_lignes = 1;
            var total_respect_lignes_categorie = 1;

            vm.temp_compteur_vente = 0;

            _.each(vm.weeks, (week, indeWeek) => {

              _.each(vm.categories, (cat, indexCat) => {
                var brd = indexCat == 0 ? 'brd-left' : '';
                var col_white = indexCat % 2 == 0 ? 'bg-white' : ''
                var quantite = vm.ventes[week.week][ligne.code + '_' + cat.code] ? vm.ventes[week.week][ligne.code + '_' + cat.code].quantite : '';
                var chiffre = vm.ventes[week.week][ligne.code + '_' + cat.code] ? vm.ventes[week.week][ligne.code + '_' + cat.code].chiffre : '';
                var sum_poids = vm.ventes[week.week][ligne.code + '_' + cat.code] ? vm.ventes[week.week][ligne.code + '_' + cat.code].sum_poids : '';
                vm.tbody_ventes += `
                                <td class="text-center ${brd} ${col_white}">${quantite ? quantite : ''}</td>
                                <td class="text-center ${col_white}">${chiffre ? chiffre : ''}</td>
                                <td class="text-center ${col_white}">${sum_poids ? sum_poids : ''}</td>`;
              })

              //total for selected categorie
              if (vm.selected_code_categorie) {
                var nbr_visite = vm.ventes[week.week + '_' + vm.selected_code_categorie + '_total_categorie'][ligne.code] ?
                  vm.ventes[week.week + '_' + vm.selected_code_categorie + '_total_categorie'][ligne.code].nbr_visite :
                  '';
                var respect_categorie = nbr_visite > 0 ? 1 : 0;
                if (respect_categorie == 0) {
                  total_respect_lignes_categorie = 0;
                }

                //check temp compteur vente
                if (vm.temp_compteur_vente == 0) {
                  vm.temp_compteur_vente = respect_categorie > 0 ? 1 : 0;
                }
              }
              var quantite = vm.ventes[week.week + '_total'][ligne.code] ? vm.ventes[week.week + '_total'][ligne.code].quantite : '';
              var chiffre = vm.ventes[week.week + '_total'][ligne.code] ? vm.ventes[week.week + '_total'][ligne.code].chiffre : '';
              var sum_poids = vm.ventes[week.week + '_total'][ligne.code] ? vm.ventes[week.week + '_total'][ligne.code].sum_poids : '';
              var nbr_visite = vm.ventes[week.week + '_total'][ligne.code] ? vm.ventes[week.week + '_total'][ligne.code].nbr_visite : '';

              var respect = nbr_visite > 0 ? 1 : 0;
              if (respect == 0) {
                total_respect_lignes = 0;
              } else {
                // compteur_vente++;
              }
              if (nbr_visite) {
                if (nbr_visite > 0) {
                  class_name_green = 'succ-green';
                  vm.list_client_visited[ligne.code] = true;
                } else {
                  class_name_blue = '';
                }
              } else {
                class_name_green = '';
              }
              vm.tbody_ventes += `<td class="text-center brd-left  ${class_name_green}"> ${quantite ? quantite : ''} </td>
                                                <td class="text-center  ${class_name_green}"> ${chiffre ? chiffre : ''} </td>
                                                <td class="text-center  ${class_name_green}"> ${sum_poids ? sum_poids : ''}</td>
                                                <td class="text-center ${class_name_green}">${nbr_visite ? nbr_visite : ''} </td>`;
            });

            //
            if (vm.temp_compteur_vente) {
              vm.compteur_vente++
            }

            /**
             * total finale
             */

            //if selected categorie
            if (vm.selected_code_categorie) {
              var class_name_blue = total_respect_lignes_categorie ? 'succ-yellow' : ''
              var temp_obj = vm.total_of_lignes_categorie[ligne.code] ? vm.total_of_lignes_categorie[ligne.code] : { quantite: '', chiffre: '', sum_poids: '', nbr_visite: '' };

              if (temp_obj.nbr_visite) {
                vm.nbr_vente_distinct_week = temp_obj.nbr_visite > 0 ? vm.nbr_vente_distinct_week + 1 : vm.nbr_vente_distinct_week;
                //console.log('vm.nbr_vente_distinct_week', vm.nbr_vente_distinct_week, temp_obj.nbr_visite)
              }
              vm.tbody_ventes += `
                            <td class="text-center ${class_name_blue} brd-left">${temp_obj.quantite ? temp_obj.quantite : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.chiffre ? temp_obj.chiffre : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.sum_poids ? temp_obj.sum_poids : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.nbr_visite ? temp_obj.nbr_visite : ''}</td>
                            <td class="text-center ${class_name_blue}">${total_respect_lignes_categorie}</td>`;

              if (total_respect_lignes_categorie == 1) {
                vm.total_respect_categorie++;
              }
            }
            var class_name_blue = total_respect_lignes ? 'succ-blue' : ''
            var temp_obj = vm.total_of_lignes[ligne.code] ? vm.total_of_lignes[ligne.code] : { quantite: '', chiffre: '', sum_poids: '', nbr_visite: '' };
            vm.tbody_ventes += `
                            <td class="text-center ${class_name_blue} brd-left">${temp_obj.quantite ? temp_obj.quantite : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.chiffre ? temp_obj.chiffre : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.sum_poids ? temp_obj.sum_poids : ''}</td>
                            <td class="text-center ${class_name_blue}">${temp_obj.nbr_visite ? temp_obj.nbr_visite : ''}</td>
                            <td class="text-center ${class_name_blue}">${total_respect_lignes}</td>
                        `;

            if (total_respect_lignes == 1) {
              vm.total_respect++;
            }


            vm.tbody_ventes += '</tr>';

          })
          // vm.compteur_vente = compteur_vente;

          vm.tbody_ventes = vm.trustHtml(vm.tbody_ventes);



          /**
           * tr rest of clients
           */
          vm.tbody_rest_of_clients = ''

          //  vm.total_respect = 0;
          // _.each(vm.rest_of_clients, (ligne,index) => {

          //     vm.tbody_rest_of_clients +=`<tr class="dang-red">
          //     <td class="text-center">${index+1}</td>
          //     <td class="text-center">${ligne.code}</td>
          //     <td class="text-center">${ligne.name}</td>`;
          //     var total_respect_lignes = 1;

          //     _.each(vm.weeks, (week, indeWeek) => {
          //         _.each(vm.categories, (cat, indexCat) => {
          //             var brd = indexCat == 0 ? 'brd-left' : '';
          //             var col_white = indexCat % 2 == 0  ? 'bg-white' : ''
          //             var quantite = vm.ventes[week.week] [ligne.code +'_'+cat.code] ? vm.ventes[week.week] [ligne.code +'_'+cat.code].quantite : '';
          //             var chiffre = vm.ventes[week.week] [ligne.code +'_'+cat.code] ? vm.ventes[week.week] [ligne.code +'_'+cat.code].chiffre : '';
          //             var sum_poids = vm.ventes[week.week] [ligne.code +'_'+cat.code] ? vm.ventes[week.week] [ligne.code +'_'+cat.code].sum_poids : '';
          //             vm.tbody_rest_of_clients += `
          //             <td class="text-center ${brd} ${col_white}">${quantite}</td>
          //             <td class="text-center ${col_white}">${chiffre}</td>
          //             <td class="text-center ${col_white}">${sum_poids}</td>`;
          //         })

          //         var quantite = vm.ventes[week.week+'_total'] [ligne.code] ? vm.ventes[week.week+'_total'] [ligne.code].quantite : '';
          //         var chiffre = vm.ventes[week.week+'_total'] [ligne.code] ? vm.ventes[week.week+'_total'] [ligne.code].chiffre : '';
          //         var sum_poids = vm.ventes[week.week+'_total'] [ligne.code] ? vm.ventes[week.week+'_total'] [ligne.code].sum_poids : '';
          //         var nbr_visite = vm.ventes[week.week+'_total'] [ligne.code] ? vm.ventes[week.week+'_total'] [ligne.code].nbr_visite : '';

          //         var respect = nbr_visite>0 ? 1 : 0;
          //         if(respect == 0){
          //             total_respect_lignes=0;
          //         }
          //         vm.tbody_rest_of_clients += `<td class="text-center brd-left  succ-green"> ${quantite} </td>
          //                             <td class="text-center  succ-green"> ${chiffre} </td>
          //                             <td class="text-center  succ-green"> ${sum_poids}</td>
          //                             <td class="text-center succ-green">${nbr_visite} </td>`;
          //     });

          //     // total finale
          //     vm.tbody_rest_of_clients +=`

          //         <td class="text-center ${class_name_blue} brd-left"></td>
          //         <td class="text-center succ-blue"></td>
          //         <td class="text-center succ-blue"></td>
          //         <td class="text-center succ-blue"></td>
          //         <td class="text-center succ-blue">0</td>
          //     `;

          //     // if(total_respect_lignes==1) {
          //     //     vm.total_respect++;
          //     // }

          //     vm.tbody_rest_of_clients += '</tr>';

          // })
          // vm.tbody_rest_of_clients = vm.trustHtml(vm.tbody_rest_of_clients);

          vm.count_list_client_visited = Object.keys(vm.list_client_visited).length
          $scope.$apply();
        }

        //console.log(' vm.list_client_visited',  vm.list_client_visited,   vm.count_list_client_visited )
        $("document").ready(function () {
          $("#scroll46-1 div").width($("#table_46").width());
          $("#scroll46-1").on("scroll", function () {
            $("#scroll46-2").scrollLeft($(this).scrollLeft());
          });
          $("#scroll46-2").on("scroll", function () {
            $("#scroll46-1").scrollLeft($(this).scrollLeft());
          });
        });

      })
      .then(null, function (error) {
        console.log(error);
        vm.loading_request = false;

      });
  }


  vm.Rapport47Mvts = () => {
    var url = 'rapports/Rapport47Mvts';
    WS.getData(url, vm.search47)
      .then(function (response) {
        if (response.status == 200) {
          vm.r_mouvements = response.data.mouvements;
          vm.prev_r_mouvements = response.data.prev_mouvements;
          vm.prev_date_debut = response.data.prev_date_debut;
          vm.prev_date_fin = response.data.prev_date_fin;
          vm.prev_nbr_jours = response.data.prev_nbr_jours;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.Rapport47Entetes = () => {
    var url = 'rapports/Rapport47Entetes';
    WS.getData(url, vm.search47)
      .then(function (response) {
        if (response.status == 200) {
          vm.r_entetes = response.data.entetes;
          vm.prev_r_entetes = response.data.prev_entetes;
          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }



  /**
   * @desc rapport 50
   * @param {*} user_code string
   * @param {*} date_debut string
   * @param {*} date_fin string
   */
  vm.r_50_commercials = {};
  vm.r_50_gammes = {};
  vm.r_50_regions = {};


  vm.countObject = object => {
    console.log(object, _.size(object))
    return _.size(object)
  }
  vm.rapportVente50 = () => {
    vm.r_50_commercials = {};
    vm.r_50_gammes = {};
    vm.r_50_regions = {};

    vm.filter50.user_codes = vm.filter50.user_codes ? vm.filter50.user_codes : [];
    console.log(vm.filter50, vm.filter50.user_codes)
    WS.post('rapports/rapportVente50', vm.filter50).then(function (response) {
      if (response.status == 200) {
        vm.r_lignes = response.data.lignes;
        vm.r_visites = response.data.visites;
        vm.r_non_visites = response.data.non_visites;
        vm.date_six_month_ago = response.data.date_six_month_ago;

        console.log('vm.r_lignes', vm.r_lignes);
        console.log('vm.r_visites', vm.r_visites);



        _.each(vm.r_lignes, (product, index) => {

          //tableau vente par commercials
          if (vm.r_50_commercials[product.user_code]) {


            vm.r_50_commercials[product.user_code][product.type + '_' + product.user_code][product.code] = product.code;
            vm.r_50_commercials[product.user_code].nbr_ligne_bl += product.nbr_ligne_bl;


            //vm.r_50_commercials[product.user_code].nbr_cmd += product.nbr_cmd;
            vm.r_50_commercials[product.user_code].nbr_ligne_cmd += product.nbr_ligne_cmd;

            vm.r_50_commercials[product.user_code].quantite_bl += product.quantite_bl;
            vm.r_50_commercials[product.user_code].box_bl += product.box_bl;
            vm.r_50_commercials[product.user_code].poids_bl += product.poids_bl;
            vm.r_50_commercials[product.user_code].chiffre_bl += product.chiffre_bl;

            vm.r_50_commercials[product.user_code].quantite_cmd += product.quantite_cmd;
            vm.r_50_commercials[product.user_code].box_cmd += product.box_cmd;
            vm.r_50_commercials[product.user_code].poids_cmd += product.poids_cmd;
            vm.r_50_commercials[product.user_code].chiffre_cmd += product.chiffre_cmd;

          } else {
            vm.r_50_commercials[product.user_code] = {

              commercial: product.commercial,
              user_code: product.user_code,
              nbr_bl: product.nbr_bl,
              nbr_ligne_bl: product.nbr_ligne_bl,

              nbr_cmd: product.nbr_cmd,
              nbr_ligne_cmd: product.nbr_ligne_cmd,

              quantite_bl: product.quantite_bl,
              box_bl: product.box_bl,
              poids_bl: product.poids_bl,
              chiffre_bl: product.chiffre_bl,

              quantite_cmd: product.quantite_cmd,
              box_cmd: product.box_cmd,
              poids_cmd: product.poids_cmd,
              chiffre_cmd: product.chiffre_cmd,
              ['bl_' + product.user_code]: {},
              ['commande_' + product.user_code]: {},
              ['avoir_' + product.user_code]: {},
            }
          }


          // tableau vente par Gamme/famille/Sousfamille
          if (vm.r_50_gammes[product.gamme_code]) {
            //gamme
            vm.r_50_gammes[product.gamme_code].nbr_bl += product.nbr_bl;
            vm.r_50_gammes[product.gamme_code].nbr_ligne_bl += product.nbr_ligne_bl;
            vm.r_50_gammes[product.gamme_code].nbr_cmd += product.nbr_cmd;
            vm.r_50_gammes[product.gamme_code].nbr_ligne_cmd += product.nbr_ligne_cmd;

            vm.r_50_gammes[product.gamme_code].quantite_bl += product.quantite_bl;
            vm.r_50_gammes[product.gamme_code].box_bl += product.box_bl;
            vm.r_50_gammes[product.gamme_code].piece_bl += product.piece_bl;
            vm.r_50_gammes[product.gamme_code].poids_bl += product.poids_bl;
            vm.r_50_gammes[product.gamme_code].chiffre_bl += product.chiffre_bl;

            vm.r_50_gammes[product.gamme_code].quantite_cmd += product.quantite_cmd;
            vm.r_50_gammes[product.gamme_code].box_cmd += product.box_cmd;
            vm.r_50_gammes[product.gamme_code].piece_cmd += product.piece_cmd;
            vm.r_50_gammes[product.gamme_code].poids_cmd += product.poids_cmd;
            vm.r_50_gammes[product.gamme_code].chiffre_cmd += product.chiffre_cmd;

            //famille
            if (vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]) {
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].nbr_bl += product.nbr_bl;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].nbr_ligne_bl += product.nbr_ligne_bl;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].nbr_cmd += product.nbr_cmd;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].nbr_ligne_cmd += product.nbr_ligne_cmd;

              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].quantite_bl += product.quantite_bl;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].box_bl += product.box_bl;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].piece_bl += product.piece_bl;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].poids_bl += product.poids_bl;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].chiffre_bl += product.chiffre_bl;

              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].quantite_cmd += product.quantite_cmd;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].box_cmd += product.box_cmd;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].piece_cmd += product.piece_cmd;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].poids_cmd += product.poids_cmd;
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code].chiffre_cmd += product.chiffre_cmd;

              //Sousfamille
              if (vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code]) {
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].nbr_bl += product.nbr_bl;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].nbr_ligne_bl += product.nbr_ligne_bl;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].nbr_cmd += product.nbr_cmd;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].nbr_ligne_cmd += product.nbr_ligne_cmd;

                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].quantite_bl += product.quantite_bl;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].box_bl += product.box_bl;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].piece_bl += product.piece_bl;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].poids_bl += product.poids_bl;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].chiffre_bl += product.chiffre_bl;

                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].quantite_cmd += product.quantite_cmd;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].box_cmd += product.box_cmd;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].piece_cmd += product.piece_cmd;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].poids_cmd += product.poids_cmd;
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code].chiffre_cmd += product.chiffre_cmd;

              } else {
                vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code]['sousfamilles'][product.sousfamille_code] = {

                  sousfamille: product.sousfamille,
                  sousfamille_code: product.sousfamille_code,
                  nbr_bl: product.nbr_bl,
                  nbr_ligne_bl: product.nbr_ligne_bl,
                  nbr_cmd: product.nbr_cmd,
                  nbr_ligne_cmd: product.nbr_ligne_cmd,

                  quantite_bl: product.quantite_bl,
                  box_bl: product.box_bl,
                  piece_bl: product.piece_bl,
                  poids_bl: product.poids_bl,
                  chiffre_bl: product.chiffre_bl,

                  quantite_cmd: product.quantite_cmd,
                  piece_cmd: product.piece_cmd,
                  box_cmd: product.box_cmd,
                  poids_cmd: product.poids_cmd,
                  chiffre_cmd: product.chiffre_cmd
                }
              }

            } else {
              vm.r_50_gammes[product.gamme_code]['familles'][product.famille_code] = {

                famille: product.famille,
                famille_code: product.famille_code,
                nbr_bl: product.nbr_bl,
                nbr_ligne_bl: product.nbr_ligne_bl,
                nbr_cmd: product.nbr_cmd,
                nbr_ligne_cmd: product.nbr_ligne_cmd,

                quantite_bl: product.quantite_bl,
                piece_bl: product.piece_bl,
                box_bl: product.box_bl,
                poids_bl: product.poids_bl,
                chiffre_bl: product.chiffre_bl,

                quantite_cmd: product.quantite_cmd,
                piece_cmd: product.piece_cmd,
                box_cmd: product.box_cmd,
                poids_cmd: product.poids_cmd,
                chiffre_cmd: product.chiffre_cmd,
                sousfamilles: {}
              }
            }

          } else {
            vm.r_50_gammes[product.gamme_code] = {

              gamme: product.gamme,
              gamme_code: product.gamme_code,
              nbr_bl: product.nbr_bl,
              nbr_ligne_bl: product.nbr_ligne_bl,
              nbr_cmd: product.nbr_cmd,
              nbr_ligne_cmd: product.nbr_ligne_cmd,

              quantite_bl: product.quantite_bl,
              piece_bl: product.piece_bl,
              box_bl: product.box_bl,
              poids_bl: product.poids_bl,
              chiffre_bl: product.chiffre_bl,

              quantite_cmd: product.quantite_cmd,
              piece_cmd: product.piece_cmd,
              box_cmd: product.box_cmd,
              poids_cmd: product.poids_cmd,
              chiffre_cmd: product.chiffre_cmd,
              familles: {}
            }
          }


          // tableau vente par Regions/zones
          if (vm.r_50_regions[product.region_code]) {
            //regions
            vm.r_50_regions[product.region_code].nbr_bl += product.nbr_bl;
            vm.r_50_regions[product.region_code].nbr_ligne_bl += product.nbr_ligne_bl;
            vm.r_50_regions[product.region_code].nbr_cmd += product.nbr_cmd;
            vm.r_50_regions[product.region_code].nbr_ligne_cmd += product.nbr_ligne_cmd;

            vm.r_50_regions[product.region_code].quantite_bl += product.quantite_bl;
            vm.r_50_regions[product.region_code].piece_bl += product.piece_bl;
            vm.r_50_regions[product.region_code].box_bl += product.box_bl;
            vm.r_50_regions[product.region_code].poids_bl += product.poids_bl;
            vm.r_50_regions[product.region_code].chiffre_bl += product.chiffre_bl;

            vm.r_50_regions[product.region_code].quantite_cmd += product.quantite_cmd;
            vm.r_50_regions[product.region_code].piece_cmd += product.piece_cmd;
            vm.r_50_regions[product.region_code].box_cmd += product.box_cmd;
            vm.r_50_regions[product.region_code].poids_cmd += product.poids_cmd;
            vm.r_50_regions[product.region_code].chiffre_cmd += product.chiffre_cmd;

            //zones
            if (vm.r_50_regions[product.region_code]['zones'][product.zone_code]) {
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].nbr_bl += product.nbr_bl;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].nbr_ligne_bl += product.nbr_ligne_bl;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].nbr_cmd += product.nbr_cmd;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].nbr_ligne_cmd += product.nbr_ligne_cmd;

              vm.r_50_regions[product.region_code]['zones'][product.zone_code].quantite_bl += product.quantite_bl;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].piece_bl += product.piece_bl;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].box_bl += product.box_bl;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].poids_bl += product.poids_bl;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].chiffre_bl += product.chiffre_bl;

              vm.r_50_regions[product.region_code]['zones'][product.zone_code].quantite_cmd += product.quantite_cmd;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].piece_cmd += product.piece_cmd;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].box_cmd += product.box_cmd;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].poids_cmd += product.poids_cmd;
              vm.r_50_regions[product.region_code]['zones'][product.zone_code].chiffre_cmd += product.chiffre_cmd;



            } else {
              vm.r_50_regions[product.region_code]['zones'][product.zone_code] = {

                zone: product.zone,
                zone_code: product.zone_code,
                nbr_bl: product.nbr_bl,
                nbr_ligne_bl: product.nbr_ligne_bl,
                nbr_cmd: product.nbr_cmd,
                nbr_ligne_cmd: product.nbr_ligne_cmd,

                quantite_bl: product.quantite_bl,
                piece_bl: product.piece_bl,
                box_bl: product.box_bl,
                poids_bl: product.poids_bl,
                chiffre_bl: product.chiffre_bl,

                quantite_cmd: product.quantite_cmd,
                piece_cmd: product.piece_cmd,
                box_cmd: product.box_cmd,
                poids_cmd: product.poids_cmd,
                chiffre_cmd: product.chiffre_cmd
              }
            }

          } else {
            vm.r_50_regions[product.region_code] = {

              region: product.region,
              region_code: product.region_code,
              nbr_bl: product.nbr_bl,
              nbr_ligne_bl: product.nbr_ligne_bl,
              nbr_cmd: product.nbr_cmd,
              nbr_ligne_cmd: product.nbr_ligne_cmd,

              quantite_bl: product.quantite_bl,
              piece_bl: product.piece_bl,
              box_bl: product.box_bl,
              poids_bl: product.poids_bl,
              chiffre_bl: product.chiffre_bl,

              quantite_cmd: product.quantite_cmd,
              piece_cmd: product.piece_cmd,
              box_cmd: product.box_cmd,
              poids_cmd: product.poids_cmd,
              chiffre_cmd: product.chiffre_cmd,
              zones: {}
            }
          }

        })


        /**
         * tab gammes
         */
        var tbodys = '';
        _.each(vm.r_50_gammes, (gamme, indGamme) => {


          //familles
          _.each(gamme.familles, (famille, indFamille) => {

            //sousfamilles
            _.each(famille.sousfamilles, (sousfamille, indSousFamille) => {
              var chiffre_bl = sousfamille.chiffre_bl ? sousfamille.chiffre_bl.toFixed(3) : 0;
              var box_bl = sousfamille.box_bl ? sousfamille.box_bl.toFixed(3) : 0;
              var piece_bl = sousfamille.piece_bl ? sousfamille.piece_bl.toFixed(3) : 0;
              var poids_bl = sousfamille.poids_bl ? sousfamille.poids_bl.toFixed(3) : 0;

              var chiffre_cmd = sousfamille.chiffre_cmd ? sousfamille.chiffre_cmd.toFixed(3) : 0;
              var box_cmd = sousfamille.box_cmd ? sousfamille.box_cmd.toFixed(3) : 0;
              var piece_cmd = sousfamille.piece_cmd ? sousfamille.piece_cmd.toFixed(3) : 0;
              var poids_cmd = sousfamille.poids_cmd ? sousfamille.poids_cmd.toFixed(3) : 0;

              tbodys += `<tr>
                                        <td class="text-center">${gamme.gamme}</td>
                                        <td class="text-center">${famille.famille}</td>
                                        <td class="text-center">${sousfamille.sousfamille}</td>

                                        <td class="text-center">${chiffre_bl}</td>
                                        <td class="text-center">${sousfamille.quantite_bl}</td>
                                        <td class="text-center">${box_bl}</td>
                                        <td class="text-center">${piece_bl}</td>
                                        <td class="text-center">${poids_bl}</td>

                                        <td class="text-center">${sousfamille.nbr_bl}</td>
                                        <td class="text-center">${sousfamille.nbr_ligne_bl}</td>

                                        <td class="text-center">${chiffre_cmd}</td>
                                        <td class="text-center">${sousfamille.quantite_cmd}</td>
                                        <td class="text-center">${box_cmd}</td>
                                        <td class="text-center">${piece_cmd}</td>
                                        <td class="text-center">${poids_cmd}</td>

                                        <td class="text-center">${sousfamille.nbr_cmd}</td>
                                        <td class="text-center">${sousfamille.nbr_ligne_cmd}</td>
                                    </tr>
                                `;

            })
            // total des sousfamilles

            var chiffre_bl = famille.chiffre_bl ? famille.chiffre_bl.toFixed(3) : 0;
            var box_bl = famille.box_bl ? famille.box_bl.toFixed(3) : 0;
            var piece_bl = famille.piece_bl ? famille.piece_bl.toFixed(3) : 0;
            var poids_bl = famille.poids_bl ? famille.poids_bl.toFixed(3) : 0;

            var chiffre_cmd = famille.chiffre_cmd ? famille.chiffre_cmd.toFixed(3) : 0;
            var box_cmd = famille.box_cmd ? famille.box_cmd.toFixed(3) : 0;
            var piece_cmd = famille.piece_cmd ? famille.piece_cmd.toFixed(3) : 0;
            var poids_cmd = famille.poids_cmd ? famille.poids_cmd.toFixed(3) : 0;

            tbodys += `<tr>
                                            <td class="text-center">${gamme.gamme}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${famille.famille}</td>
                                            <td class="text-center"  style="background: #f3e5c6;"></td>

                                            <td class="text-center"  style="background: #f3e5c6;">${chiffre_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${famille.quantite_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${box_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${piece_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${poids_bl}</td>

                                            <td class="text-center"  style="background: #f3e5c6;">${famille.nbr_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${famille.nbr_ligne_bl}</td>

                                            <td class="text-center"  style="background: #f3e5c6;">${chiffre_cmd}</td>

                                            <td class="text-center"  style="background: #f3e5c6;">${poids_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${famille.quantite_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${box_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${piece_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${famille.nbr_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${famille.nbr_ligne_cmd}</td>
                                        </tr>
                                    `;
          })
          // total des familles

          var chiffre_bl = gamme.chiffre_bl ? gamme.chiffre_bl.toFixed(3) : 0;
          var box_bl = gamme.box_bl ? gamme.box_bl.toFixed(3) : 0;
          var piece_bl = gamme.piece_bl ? gamme.piece_bl.toFixed(3) : 0;
          var poids_bl = gamme.poids_bl ? gamme.poids_bl.toFixed(3) : 0;

          var chiffre_cmd = gamme.chiffre_cmd ? gamme.chiffre_cmd.toFixed(3) : 0;
          var box_cmd = gamme.box_cmd ? gamme.box_cmd.toFixed(3) : 0;
          var piece_cmd = gamme.piece_cmd ? gamme.piece_cmd.toFixed(3) : 0;
          var poids_cmd = gamme.poids_cmd ? gamme.poids_cmd.toFixed(3) : 0;

          tbodys += `<tr>
                                    <td class="text-center" style="background: #bbf1df;">${gamme.gamme}</td>
                                    <td class="text-center" style="background: #bbf1df;"></td>
                                    <td class="text-center" style="background: #bbf1df;"></td>

                                    <td class="text-center" style="background: #bbf1df;">${chiffre_bl}</td>
                                    <td class="text-center"  style="background: #bbf1df;">${gamme.quantite_bl}</td>
                                    <td class="text-center"  style="background: #bbf1df;">${box_bl}</td>
                                    <td class="text-center"  style="background: #bbf1df;">${piece_bl}</td>
                                    <td class="text-center" style="background: #bbf1df;">${poids_bl}</td>

                                    <td class="text-center" style="background: #bbf1df;">${gamme.nbr_bl}</td>
                                    <td class="text-center" style="background: #bbf1df;">${gamme.nbr_ligne_bl}</td>

                                    <td class="text-center" style="background: #bbf1df;">${chiffre_cmd}</td>
                                    <td class="text-center"  style="background: #bbf1df;">${gamme.quantite_cmd}</td>
                                    <td class="text-center"  style="background: #bbf1df;">${box_cmd}</td>
                                    <td class="text-center"  style="background: #bbf1df;">${piece_cmd}</td>
                                    <td class="text-center" style="background: #bbf1df;">${poids_cmd}</td>

                                    <td class="text-center" style="background: #bbf1df;">${gamme.nbr_cmd}</td>
                                    <td class="text-center" style="background: #bbf1df;">${gamme.nbr_ligne_cmd}</td>
                                </tr>
                            `;
        })

        vm.tbodys = vm.trustHtml(tbodys)


        /**
         * tab regions
         */
        var tbodys_regions = '';
        _.each(vm.r_50_regions, (region, indRegion) => {
          console.log('region : ', region)


          //zones
          _.each(region.zones, (zone, indZone) => {

            console.log('zone : ', zone)
            // lignes des zones

            var chiffre_bl = zone.chiffre_bl ? zone.chiffre_bl.toFixed(3) : 0;
            var box_bl = zone.box_bl ? zone.box_bl.toFixed(3) : 0;
            var piece_bl = zone.piece_bl ? zone.piece_bl.toFixed(3) : 0;
            var poids_bl = zone.poids_bl ? zone.poids_bl.toFixed(3) : 0;

            var chiffre_cmd = zone.chiffre_cmd ? zone.chiffre_cmd.toFixed(3) : 0;
            var box_cmd = zone.box_cmd ? zone.box_cmd.toFixed(3) : 0;
            var piece_cmd = zone.piece_cmd ? zone.piece_cmd.toFixed(3) : 0;
            var poids_cmd = zone.poids_cmd ? zone.poids_cmd.toFixed(3) : 0;

            tbodys_regions += `<tr>
                                            <td class="text-center">${region.region}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${zone.zone}</td>

                                            <td class="text-center"  style="background: #f3e5c6;">${chiffre_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${zone.quantite_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${box_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${piece_bl}</td>

                                            <td class="text-center"  style="background: #f3e5c6;">${poids_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${zone.nbr_bl}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${zone.nbr_ligne_bl}</td>

                                            <td class="text-center"  style="background: #f3e5c6;">${chiffre_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${zone.quantite_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${box_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${piece_cmd}</td>

                                            <td class="text-center"  style="background: #f3e5c6;">${poids_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${zone.nbr_cmd}</td>
                                            <td class="text-center"  style="background: #f3e5c6;">${zone.nbr_ligne_cmd}</td>
                                        </tr>
                                    `;
          })
          // total des zones

          var chiffre_bl = region.chiffre_bl ? region.chiffre_bl.toFixed(3) : 0;
          var box_bl = region.box_bl ? region.box_bl.toFixed(3) : 0;
          var piece_bl = region.piece_bl ? region.piece_bl.toFixed(3) : 0;
          var poids_bl = region.poids_bl ? region.poids_bl.toFixed(3) : 0;

          var chiffre_cmd = region.chiffre_cmd ? region.chiffre_cmd.toFixed(3) : 0;
          var box_cmd = region.box_cmd ? region.box_cmd.toFixed(3) : 0;
          var piece_cmd = region.piece_cmd ? region.piece_cmd.toFixed(3) : 0;
          var poids_cmd = region.poids_cmd ? region.poids_cmd.toFixed(3) : 0;

          tbodys_regions += `<tr>
                                    <td class="text-center" style="background: #bbf1df;">${region.region}</td>
                                    <td class="text-center" style="background: #bbf1df;"></td>

                                    <td class="text-center" style="background: #bbf1df;">${chiffre_bl}</td>
                                    <td class="text-center" style="background: #bbf1df;">${region.quantite_bl}</td>
                                    <td class="text-center" style="background: #bbf1df;">${box_bl}</td>
                                    <td class="text-center" style="background: #bbf1df;">${piece_bl}</td>

                                    <td class="text-center" style="background: #bbf1df;">${poids_bl}</td>
                                    <td class="text-center" style="background: #bbf1df;">${region.nbr_bl}</td>
                                    <td class="text-center" style="background: #bbf1df;">${region.nbr_ligne_bl}</td>

                                    <td class="text-center" style="background: #bbf1df;">${chiffre_cmd}</td>
                                    <td class="text-center" style="background: #bbf1df;">${region.quantite_cmd}</td>
                                    <td class="text-center" style="background: #bbf1df;">${box_cmd}</td>
                                    <td class="text-center" style="background: #bbf1df;">${piece_cmd}</td>
                                    <td class="text-center" style="background: #bbf1df;">${poids_cmd}</td>

                                    <td class="text-center" style="background: #bbf1df;">${region.nbr_cmd}</td>
                                    <td class="text-center" style="background: #bbf1df;">${region.nbr_ligne_cmd}</td>
                                </tr>
                            `;
        })

        vm.tbodys_regions = vm.trustHtml(tbodys_regions)

        console.log('vm.r_50_commercials', vm.r_50_commercials);
        console.log('vm.r_50_gammes', vm.r_50_gammes);
        console.log('vm.r_50_regions', vm.r_50_regions);
        $scope.$apply();
      }
    })
      .then(null, function (error) {
        console.log(error);
      });
  }

  vm.searchState50 = {}
  vm.BLgammeFamillePie = () => {

    $('.panel_bl_gamme_famille_pie').empty();
    $('.panel_bl_gamme_famille_pie').append(`<div id="bl_gamme_famille_pie" style="width: 100%;height: 500px;"></div>`);

    WS.post('rapports/VenteByGammeFmille', {
      date_debut: vm.searchState50.date_debut,
      date_fin: vm.searchState50.date_fin,
      user_codes: vm.searchState50.user_codes,
      type: 'bl'
    }).then(function (response) {
      if (response.status == 200) {


        am5.ready(function () {




          // Create root element
          // https://www.amcharts.com/docs/v5/getting-started/#Root_element
          var root = am5.Root.new("bl_gamme_famille_pie");

          // Set themes
          // https://www.amcharts.com/docs/v5/concepts/themes/
          root.setThemes([
            am5themes_Animated.new(root)
          ]);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
          // start and end angle must be set both for chart and series
          var chart = root.container.children.push(am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            radius: am5.percent(100)
          }));

          // Create series
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
          // start and end angle must be set both for chart and series
          var series0 = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "rs_sum_chiffre",
            categoryField: "year",
            alignLabels: false,
            radius: am5.percent(80),
            innerRadius: am5.percent(25)
          }));

          var bgColor = root.interfaceColors.get("background");

          series0.ticks.template.setAll({ forceHidden: true });

          series0.labels.template.setAll({
            radius: -10,
            text: "{category}",
            textType: "radial",
            centerX: am5.percent(100)
          });

          series0.slices.template.setAll({
            stroke: bgColor,
            strokeWidth: 2,
            fill: am5.color(0xaaaaaa),
            tooltipText:
              "{category}: {valuePercentTotal.formatNumber('0.00')}% ({value} bottles)"
          });
          series0.slices.template.states.create("hover", { scale: 0.95 });

          var series1 = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "rs_sum_chiffre",
            categoryField: "famille",
            alignLabels: true,
            innerRadius: am5.percent(80),
            radius: am5.percent(100)
          }));

          series1.slices.template.setAll({
            stroke: bgColor,
            strokeWidth: 2,
            templateField: "settings"
          });

          series1.labels.template.setAll({
            text: "{category}"
          });


          _.each(response.data, (element, index) => {
            _.each(response.data[index], (objct, indObj) => {
              console.log(objct, index)
              response.data[index][indObj].settings = { fill: objct.color ? objct.color : am5.color(0x1E3888) }
            })
          })

          console.log('response array', response.data)
          var data = response.data;
          // var data = {
          //     "1957": [
          //         { famille: "Belgium", rs_sum_chiffre: 11589623, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "France", rs_sum_chiffre: 67413000, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Germany", rs_sum_chiffre: 83190556, settings: { fill: am5.color(0x9C3848) } },
          //         { famille: "Italy", rs_sum_chiffre: 60359546, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Luxembourg", rs_sum_chiffre: 626108, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Netherlands", rs_sum_chiffre: 17479000, settings: { fill: am5.color(0xaaaaaa) } }
          //     ],
          //     "1973": [
          //         { famille: "Denmark", rs_sum_chiffre: 5806081, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Ireland", rs_sum_chiffre: 4948200, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "United Kingdom", rs_sum_chiffre: 66647112, settings: { fill: am5.color(0x47A8BD) } }
          //     ],
          //     "1981": [
          //         { famille: "Greece", rs_sum_chiffre: 10724599, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "1986": [
          //         { famille: "Portugal", rs_sum_chiffre: 10196707, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Spain", rs_sum_chiffre: 46722980, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "1995": [
          //         { famille: "Austria", rs_sum_chiffre: 8902600, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Finland", rs_sum_chiffre: 5523231, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Sweden", rs_sum_chiffre: 10379295, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "2004": [
          //         { famille: "Cyprus", rs_sum_chiffre: 1207359, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Czech Republic", rs_sum_chiffre: 10708981, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Estonia", rs_sum_chiffre: 1328976, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Hungary", rs_sum_chiffre: 9771000, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Latvia", rs_sum_chiffre: 1901548, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Lithuania", rs_sum_chiffre: 2790844, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Malta", rs_sum_chiffre: 514564, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Poland", rs_sum_chiffre: 37846755, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Slovakia", rs_sum_chiffre: 5459642, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Slovenia", rs_sum_chiffre: 2073894, settings: { fill: am5.color(0xaaaaaa) } }
          //     ],
          //     "2007": [
          //         { famille: "Bulgaria", rs_sum_chiffre: 6971487, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Romania", rs_sum_chiffre: 19286123, settings: { fill: am5.color(0x9C3848) } }
          //     ]
          // };

          // Generate series data
          var innerData = [];
          var outerData = [];
          am5.object.each(data, function (year, countries) {
            var rs_sum_chiffre = 0;
            am5.array.each(countries, function (famille) {
              rs_sum_chiffre += famille.rs_sum_chiffre;
              outerData.push(famille);
            });
            innerData.push({
              year: year,
              rs_sum_chiffre: rs_sum_chiffre
            });
          });


          // Set data
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
          series0.data.setAll(innerData);
          series1.data.setAll(outerData);

          // Play initial series animation
          // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
          series0.appear(1000, 100);
          series1.appear(1000, 100);

        }); // end am5.ready()
      }
    });

  }

  vm.CMDgammeFamillePie = () => {

    $('.panel_cmd_gamme_famille_pie').empty();
    $('.panel_cmd_gamme_famille_pie').append(`<div id="cmd_gamme_famille_pie" style="width: 100%;height: 500px;"></div>`);
    WS.post('rapports/VenteByGammeFmille', {
      date_debut: vm.searchState50.date_debut,
      date_fin: vm.searchState50.date_fin,
      user_codes: vm.searchState50.user_codes,
      type: 'commande'
    }).then(function (response) {
      if (response.status == 200) {


        am5.ready(function () {

          // Create root element
          // https://www.amcharts.com/docs/v5/getting-started/#Root_element
          var root = am5.Root.new("cmd_gamme_famille_pie");

          // Set themes
          // https://www.amcharts.com/docs/v5/concepts/themes/
          root.setThemes([
            am5themes_Animated.new(root)
          ]);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
          // start and end angle must be set both for chart and series
          var chart = root.container.children.push(am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            radius: am5.percent(100)
          }));

          // Create series
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
          // start and end angle must be set both for chart and series
          var series0 = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "rs_sum_chiffre",
            categoryField: "year",
            alignLabels: false,
            radius: am5.percent(80),
            innerRadius: am5.percent(25)
          }));

          var bgColor = root.interfaceColors.get("background");

          series0.ticks.template.setAll({ forceHidden: true });

          series0.labels.template.setAll({
            radius: -10,
            text: "{category}",
            textType: "radial",
            centerX: am5.percent(100)
          });

          series0.slices.template.setAll({
            stroke: bgColor,
            strokeWidth: 2,
            fill: am5.color(0xaaaaaa),
            tooltipText:
              "{category}: {valuePercentTotal.formatNumber('0.00')}% ({value} bottles)"
          });
          series0.slices.template.states.create("hover", { scale: 0.95 });

          var series1 = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "rs_sum_chiffre",
            categoryField: "famille",
            alignLabels: true,
            innerRadius: am5.percent(80),
            radius: am5.percent(100)
          }));

          series1.slices.template.setAll({
            stroke: bgColor,
            strokeWidth: 2,
            templateField: "settings"
          });

          series1.labels.template.setAll({
            text: "{category}"
          });

          _.each(response.data, (element, index) => {
            _.each(response.data[index], (objct, indObj) => {
              response.data[index][indObj].settings = { fill: objct.color ? objct.color : am5.color(0x1E3888) }
            })
          })

          var data = response.data;
          // var data = {
          //     "1957": [
          //         { famille: "Belgium", rs_sum_chiffre: 11589623, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "France", rs_sum_chiffre: 67413000, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Germany", rs_sum_chiffre: 83190556, settings: { fill: am5.color(0x9C3848) } },
          //         { famille: "Italy", rs_sum_chiffre: 60359546, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Luxembourg", rs_sum_chiffre: 626108, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Netherlands", rs_sum_chiffre: 17479000, settings: { fill: am5.color(0xaaaaaa) } }
          //     ],
          //     "1973": [
          //         { famille: "Denmark", rs_sum_chiffre: 5806081, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Ireland", rs_sum_chiffre: 4948200, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "United Kingdom", rs_sum_chiffre: 66647112, settings: { fill: am5.color(0x47A8BD) } }
          //     ],
          //     "1981": [
          //         { famille: "Greece", rs_sum_chiffre: 10724599, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "1986": [
          //         { famille: "Portugal", rs_sum_chiffre: 10196707, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Spain", rs_sum_chiffre: 46722980, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "1995": [
          //         { famille: "Austria", rs_sum_chiffre: 8902600, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Finland", rs_sum_chiffre: 5523231, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Sweden", rs_sum_chiffre: 10379295, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "2004": [
          //         { famille: "Cyprus", rs_sum_chiffre: 1207359, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Czech Republic", rs_sum_chiffre: 10708981, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Estonia", rs_sum_chiffre: 1328976, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Hungary", rs_sum_chiffre: 9771000, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Latvia", rs_sum_chiffre: 1901548, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Lithuania", rs_sum_chiffre: 2790844, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Malta", rs_sum_chiffre: 514564, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Poland", rs_sum_chiffre: 37846755, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Slovakia", rs_sum_chiffre: 5459642, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Slovenia", rs_sum_chiffre: 2073894, settings: { fill: am5.color(0xaaaaaa) } }
          //     ],
          //     "2007": [
          //         { famille: "Bulgaria", rs_sum_chiffre: 6971487, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Romania", rs_sum_chiffre: 19286123, settings: { fill: am5.color(0x9C3848) } }
          //     ]
          // };

          // Generate series data
          var innerData = [];
          var outerData = [];
          am5.object.each(data, function (year, countries) {
            var rs_sum_chiffre = 0;
            am5.array.each(countries, function (famille) {
              rs_sum_chiffre += famille.rs_sum_chiffre;
              outerData.push(famille);
            });
            innerData.push({
              year: year,
              rs_sum_chiffre: rs_sum_chiffre
            });
          });


          // Set data
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
          series0.data.setAll(innerData);
          series1.data.setAll(outerData);

          // Play initial series animation
          // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
          series0.appear(1000, 100);
          series1.appear(1000, 100);

        }); // end am5.ready()
      }
    });

  }



  vm.BLFamilleSousfamillePie = () => {

    $('.panel_bl_famille_sousfamille_pie').empty();
    $('.panel_bl_famille_sousfamille_pie').append(`<div id="bl_famille_sousfamille_pie" style="width: 100%;height: 500px;"></div>`);
    WS.post('rapports/VenteByFmilleSousFamille', {
      date_debut: vm.searchState50.date_debut,
      date_fin: vm.searchState50.date_fin,
      user_codes: vm.searchState50.user_codes,
      type: 'bl'
    }).then(function (response) {
      if (response.status == 200) {


        am5.ready(function () {




          // Create root element
          // https://www.amcharts.com/docs/v5/getting-started/#Root_element
          var root = am5.Root.new("bl_famille_sousfamille_pie");

          // Set themes
          // https://www.amcharts.com/docs/v5/concepts/themes/
          root.setThemes([
            am5themes_Animated.new(root)
          ]);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
          // start and end angle must be set both for chart and series
          var chart = root.container.children.push(am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            radius: am5.percent(100)
          }));

          // Create series
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
          // start and end angle must be set both for chart and series
          var series0 = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "rs_sum_chiffre",
            categoryField: "year",
            alignLabels: false,
            radius: am5.percent(80),
            innerRadius: am5.percent(25)
          }));

          var bgColor = root.interfaceColors.get("background");

          series0.ticks.template.setAll({ forceHidden: true });

          series0.labels.template.setAll({
            radius: -10,
            text: "{category}",
            textType: "radial",
            centerX: am5.percent(100)
          });

          series0.slices.template.setAll({
            stroke: bgColor,
            strokeWidth: 2,
            fill: am5.color(0xaaaaaa),
            tooltipText:
              "{category}: {valuePercentTotal.formatNumber('0.00')}% ({value} bottles)"
          });
          series0.slices.template.states.create("hover", { scale: 0.95 });

          var series1 = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "rs_sum_chiffre",
            categoryField: "famille",
            alignLabels: true,
            innerRadius: am5.percent(80),
            radius: am5.percent(100)
          }));

          series1.slices.template.setAll({
            stroke: bgColor,
            strokeWidth: 2,
            templateField: "settings"
          });

          series1.labels.template.setAll({
            text: "{category}"
          });


          _.each(response.data, (element, index) => {
            _.each(response.data[index], (objct, indObj) => {
              console.log(objct, index)
              response.data[index][indObj].settings = { fill: objct.color ? objct.color : am5.color(0x1E3888) }
            })
          })

          console.log('response array', response.data)
          var data = response.data;
          // var data = {
          //     "1957": [
          //         { famille: "Belgium", rs_sum_chiffre: 11589623, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "France", rs_sum_chiffre: 67413000, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Germany", rs_sum_chiffre: 83190556, settings: { fill: am5.color(0x9C3848) } },
          //         { famille: "Italy", rs_sum_chiffre: 60359546, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Luxembourg", rs_sum_chiffre: 626108, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Netherlands", rs_sum_chiffre: 17479000, settings: { fill: am5.color(0xaaaaaa) } }
          //     ],
          //     "1973": [
          //         { famille: "Denmark", rs_sum_chiffre: 5806081, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Ireland", rs_sum_chiffre: 4948200, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "United Kingdom", rs_sum_chiffre: 66647112, settings: { fill: am5.color(0x47A8BD) } }
          //     ],
          //     "1981": [
          //         { famille: "Greece", rs_sum_chiffre: 10724599, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "1986": [
          //         { famille: "Portugal", rs_sum_chiffre: 10196707, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Spain", rs_sum_chiffre: 46722980, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "1995": [
          //         { famille: "Austria", rs_sum_chiffre: 8902600, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Finland", rs_sum_chiffre: 5523231, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Sweden", rs_sum_chiffre: 10379295, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "2004": [
          //         { famille: "Cyprus", rs_sum_chiffre: 1207359, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Czech Republic", rs_sum_chiffre: 10708981, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Estonia", rs_sum_chiffre: 1328976, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Hungary", rs_sum_chiffre: 9771000, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Latvia", rs_sum_chiffre: 1901548, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Lithuania", rs_sum_chiffre: 2790844, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Malta", rs_sum_chiffre: 514564, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Poland", rs_sum_chiffre: 37846755, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Slovakia", rs_sum_chiffre: 5459642, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Slovenia", rs_sum_chiffre: 2073894, settings: { fill: am5.color(0xaaaaaa) } }
          //     ],
          //     "2007": [
          //         { famille: "Bulgaria", rs_sum_chiffre: 6971487, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Romania", rs_sum_chiffre: 19286123, settings: { fill: am5.color(0x9C3848) } }
          //     ]
          // };

          // Generate series data
          var innerData = [];
          var outerData = [];
          am5.object.each(data, function (year, countries) {
            var rs_sum_chiffre = 0;
            am5.array.each(countries, function (famille) {
              rs_sum_chiffre += famille.rs_sum_chiffre;
              outerData.push(famille);
            });
            innerData.push({
              year: year,
              rs_sum_chiffre: rs_sum_chiffre
            });
          });


          // Set data
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
          series0.data.setAll(innerData);
          series1.data.setAll(outerData);

          // Play initial series animation
          // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
          series0.appear(1000, 100);
          series1.appear(1000, 100);

        }); // end am5.ready()
      }
    });

  }

  vm.CMDFamilleSousfamillePie = () => {

    $('.panel_cmd_famille_sousfamille_pie').empty();
    $('.panel_cmd_famille_sousfamille_pie').append(`<div id="cmd_famille_sousfamille_pie" style="width: 100%;height: 500px;"></div>`);

    WS.post('rapports/VenteByFmilleSousFamille', {
      date_debut: vm.searchState50.date_debut,
      date_fin: vm.searchState50.date_fin,
      user_codes: vm.searchState50.user_codes,
      type: 'commande'
    }).then(function (response) {
      if (response.status == 200) {


        am5.ready(function () {

          // Create root element
          // https://www.amcharts.com/docs/v5/getting-started/#Root_element
          var root = am5.Root.new("cmd_famille_sousfamille_pie");

          // Set themes
          // https://www.amcharts.com/docs/v5/concepts/themes/
          root.setThemes([
            am5themes_Animated.new(root)
          ]);

          // Create chart
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
          // start and end angle must be set both for chart and series
          var chart = root.container.children.push(am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            radius: am5.percent(100)
          }));

          // Create series
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
          // start and end angle must be set both for chart and series
          var series0 = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "rs_sum_chiffre",
            categoryField: "year",
            alignLabels: false,
            radius: am5.percent(80),
            innerRadius: am5.percent(25)
          }));

          var bgColor = root.interfaceColors.get("background");

          series0.ticks.template.setAll({ forceHidden: true });

          series0.labels.template.setAll({
            radius: -10,
            text: "{category}",
            textType: "radial",
            centerX: am5.percent(100)
          });

          series0.slices.template.setAll({
            stroke: bgColor,
            strokeWidth: 2,
            fill: am5.color(0xaaaaaa),
            tooltipText:
              "{category}: {valuePercentTotal.formatNumber('0.00')}% ({value} bottles)"
          });
          series0.slices.template.states.create("hover", { scale: 0.95 });

          var series1 = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "rs_sum_chiffre",
            categoryField: "famille",
            alignLabels: true,
            innerRadius: am5.percent(80),
            radius: am5.percent(100)
          }));

          series1.slices.template.setAll({
            stroke: bgColor,
            strokeWidth: 2,
            templateField: "settings"
          });

          series1.labels.template.setAll({
            text: "{category}"
          });

          _.each(response.data, (element, index) => {
            _.each(response.data[index], (objct, indObj) => {
              response.data[index][indObj].settings = { fill: objct.color ? objct.color : am5.color(0x1E3888) }
            })
          })

          var data = response.data;
          // var data = {
          //     "1957": [
          //         { famille: "Belgium", rs_sum_chiffre: 11589623, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "France", rs_sum_chiffre: 67413000, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Germany", rs_sum_chiffre: 83190556, settings: { fill: am5.color(0x9C3848) } },
          //         { famille: "Italy", rs_sum_chiffre: 60359546, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Luxembourg", rs_sum_chiffre: 626108, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Netherlands", rs_sum_chiffre: 17479000, settings: { fill: am5.color(0xaaaaaa) } }
          //     ],
          //     "1973": [
          //         { famille: "Denmark", rs_sum_chiffre: 5806081, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Ireland", rs_sum_chiffre: 4948200, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "United Kingdom", rs_sum_chiffre: 66647112, settings: { fill: am5.color(0x47A8BD) } }
          //     ],
          //     "1981": [
          //         { famille: "Greece", rs_sum_chiffre: 10724599, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "1986": [
          //         { famille: "Portugal", rs_sum_chiffre: 10196707, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Spain", rs_sum_chiffre: 46722980, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "1995": [
          //         { famille: "Austria", rs_sum_chiffre: 8902600, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Finland", rs_sum_chiffre: 5523231, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Sweden", rs_sum_chiffre: 10379295, settings: { fill: am5.color(0x1E3888) } }
          //     ],
          //     "2004": [
          //         { famille: "Cyprus", rs_sum_chiffre: 1207359, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Czech Republic", rs_sum_chiffre: 10708981, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Estonia", rs_sum_chiffre: 1328976, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Hungary", rs_sum_chiffre: 9771000, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Latvia", rs_sum_chiffre: 1901548, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Lithuania", rs_sum_chiffre: 2790844, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Malta", rs_sum_chiffre: 514564, settings: { fill: am5.color(0xaaaaaa) } },
          //         { famille: "Poland", rs_sum_chiffre: 37846755, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Slovakia", rs_sum_chiffre: 5459642, settings: { fill: am5.color(0x47A8BD) } },
          //         { famille: "Slovenia", rs_sum_chiffre: 2073894, settings: { fill: am5.color(0xaaaaaa) } }
          //     ],
          //     "2007": [
          //         { famille: "Bulgaria", rs_sum_chiffre: 6971487, settings: { fill: am5.color(0x1E3888) } },
          //         { famille: "Romania", rs_sum_chiffre: 19286123, settings: { fill: am5.color(0x9C3848) } }
          //     ]
          // };

          // Generate series data
          var innerData = [];
          var outerData = [];
          am5.object.each(data, function (year, countries) {
            var rs_sum_chiffre = 0;
            am5.array.each(countries, function (famille) {
              rs_sum_chiffre += famille.rs_sum_chiffre;
              outerData.push(famille);
            });
            innerData.push({
              year: year,
              rs_sum_chiffre: rs_sum_chiffre
            });
          });


          // Set data
          // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
          series0.data.setAll(innerData);
          series1.data.setAll(outerData);

          // Play initial series animation
          // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
          series0.appear(1000, 100);
          series1.appear(1000, 100);

        }); // end am5.ready()
      }
    });

  }


  vm.pieFamilleSousfamilles = () => {

    $('.pie_am5_01').empty();
    $('.pie_am5_01').append(`<div id="pie_achat_famille_sousfamille" style="width: 100%;height: 500px;"></div>`);
    WS.post("rapports/pieFamilleSousfamilles", {
      date_debut: vm.searchState50.date_debut,
      date_fin: vm.searchState50.date_fin,
      user_codes: vm.searchState50.user_codes,
      type: vm.searchState50.type ? vm.searchState50.type : 'bl'
    })
      .then(function (response) {
        vm.lists_prods = response.data;
        vm.pie_familles = [];
        vm.pie_gammes = [];
        vm.sousfamille_codes = [];



        if (vm.lists_prods.length > 0) {
          vm.drawPie1();
          vm.drawPie2();
        }
        $scope.$apply();



      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.drawPie1 = () => {
    _.each(vm.lists_prods, element => {

      if (!_.isObject(vm.sousfamille_codes[element.sousfamille_code])) {
        vm.sousfamille_codes[element.sousfamille_code] = {};
      }

      //vm.sousfamille_codes[element.sousfamille_code].category_code = element.sousfamille_code;
      vm.sousfamille_codes[element.sousfamille_code].famille_code = element.famille_code;
      vm.sousfamille_codes[element.sousfamille_code].category = element.sousfamille;
      vm.sousfamille_codes[element.sousfamille_code].value = 0;

      //Famille
      if (!_.isObject(vm.pie_familles[element.famille_code])) {
        vm.pie_familles[element.famille_code] = {};
      }
      vm.pie_familles[element.famille_code].category_code = element.famille_code;
      vm.pie_familles[element.famille_code].category = element.famille;
      //Quantite
      vm.pie_familles[element.famille_code].value =
        vm.pie_familles[element.famille_code].value ? vm.pie_familles[element.famille_code].value + Number(element.quantite) : Number(element.quantite);

      //Chiffre
      // vm.pie_familles[element.famille_code].chiffre =
      //     vm.pie_familles[element.famille_code].chiffre ? vm.pie_familles[element.famille_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      //Sous familles
      if (!_.isObject(vm.pie_familles[element.famille_code].subData)) {
        vm.pie_familles[element.famille_code].subData = {};
      }

      if (!_.isObject(vm.pie_familles[element.famille_code].subData[element.sousfamille_code])) {
        vm.pie_familles[element.famille_code].subData[element.sousfamille_code] = {};
      }

      vm.pie_familles[element.famille_code].subData[element.sousfamille_code].category_code = element.sousfamille_code;
      vm.pie_familles[element.famille_code].subData[element.sousfamille_code].category = element.sousfamille;

      // vm.pie_familles[element.famille_code].subData[element.sousfamille_code].chiffre =
      //     vm.pie_familles[element.famille_code].subData[element.sousfamille_code].chiffre ? vm.pie_familles[element.famille_code].subData[element.sousfamille_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      vm.pie_familles[element.famille_code].subData[element.sousfamille_code].value =
        vm.pie_familles[element.famille_code].subData[element.sousfamille_code].value ? vm.pie_familles[element.famille_code].subData[element.sousfamille_code].value + Number(element.quantite) : Number(element.quantite);
    });
    //console.log('vm.pie_familles', vm.pie_familles);
    vm.pie_familles = Object.values(vm.pie_familles);
    _.each(vm.pie_familles, (element, index) => {
      vm.pie_familles[index].subData = Object.values(element.subData);
    })
    //console.log('flat 1', vm.pie_familles);

    vm.sousfamille_codes = Object.values(vm.sousfamille_codes);
    //console.log('flat 2', vm.sousfamille_codes);



    $(document).ready(function () {
      am5.ready(function () {

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new("pie_achat_famille_sousfamille");

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);

        var container = root.container.children.push(
          am5.Container.new(root, {
            width: am5.p100,
            height: am5.p100,
            layout: root.horizontalLayout
          })
        );

        // Create main chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var chart = container.children.push(
          am5percent.PieChart.new(root, {
            tooltip: am5.Tooltip.new(root, {})
          })
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var series = chart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category",
            alignLabels: false
          })
        );

        series.labels.template.setAll({
          textType: "circular",
          radius: 4
        });
        series.ticks.template.set("visible", false);
        series.slices.template.set("toggleKey", "none");

        // add events
        series.slices.template.events.on("click", function (e) {
          console.log(e.target);
          selectSlice(e.target, true);
        });

        // Create sub chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var subChart = container.children.push(
          am5percent.PieChart.new(root, {
            radius: am5.percent(50),
            tooltip: am5.Tooltip.new(root, {})
          })
        );

        // Create sub series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var subSeries = subChart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category"
          })
        );

        subSeries.data.setAll(vm.sousfamille_codes);

        // console.log('subSeries ======>', subSeries)
        subSeries.slices.template.set("toggleKey", "none");
        //console.log('subSeries ======> 2', subSeries)

        var selectedSlice;

        series.on("startAngle", function () {
          updateLines();
        });

        container.events.on("boundschanged", function () {
          root.events.on("frameended", function () {
            updateLines();
          })
        })

        function updateLines() {
          if (selectedSlice) {
            var startAngle = selectedSlice.get("startAngle");
            var arc = selectedSlice.get("arc");
            var radius = selectedSlice.get("radius");

            var x00 = radius * am5.math.cos(startAngle);
            var y00 = radius * am5.math.sin(startAngle);

            var x10 = radius * am5.math.cos(startAngle + arc);
            var y10 = radius * am5.math.sin(startAngle + arc);

            var subRadius = subSeries.slices.getIndex(0).get("radius");
            var x01 = 0;
            var y01 = -subRadius;

            var x11 = 0;
            var y11 = subRadius;

            var point00 = series.toGlobal({ x: x00, y: y00 });
            var point10 = series.toGlobal({ x: x10, y: y10 });

            var point01 = subSeries.toGlobal({ x: x01, y: y01 });
            var point11 = subSeries.toGlobal({ x: x11, y: y11 });

            line0.set("points", [point00, point01]);
            line1.set("points", [point10, point11]);
          }
        }

        // lines
        var line0 = container.children.push(
          am5.Line.new(root, {
            position: "absolute",
            stroke: root.interfaceColors.get("text"),
            strokeDasharray: [2, 2]
          })
        );
        var line1 = container.children.push(
          am5.Line.new(root, {
            position: "absolute",
            stroke: root.interfaceColors.get("text"),
            strokeDasharray: [2, 2]
          })
        );

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
        series.data.setAll(vm.pie_familles);

        function selectSlice(slice, clickedelement = false) {

          selectedSlice = slice;
          var dataItem = slice.dataItem;
          var dataContext = dataItem.dataContext;

          // if(clickedelement==true){
          //     console.log('clickedelm = true',dataItem.dataContext )
          // }

          //update sousfamilles
          var datas = vm.sousfamille_codes.filter(function (obj) {
            return dataContext.category_code == obj.famille_code;
          })
          subSeries.data.setAll(datas);
          if (dataContext) {
            var i = 0;
            subSeries.data.each(function (dataObject) {
              //console.log('dataObject =================> ', dataObject);
              if (_.isArray(dataContext.subData)) {
                if (dataContext.subData[i]) {
                  if (dataContext.category_code == dataObject.famille_code) {
                    //console.log('dataContext.subData[i]', dataContext.subData[i])
                    subSeries.data.setIndex(i, dataContext.subData[i]);
                  }

                }

              }

              i++;
              console.log(i)
            });
          }

          var middleAngle = slice.get("startAngle") + slice.get("arc") / 2;
          var firstAngle = series.dataItems[0].get("slice").get("startAngle");

          series.animate({
            key: "startAngle",
            to: firstAngle - middleAngle,
            duration: 1000,
            easing: am5.ease.out(am5.ease.cubic)
          });
          series.animate({
            key: "endAngle",
            to: firstAngle - middleAngle + 360,
            duration: 1000,
            easing: am5.ease.out(am5.ease.cubic)
          });
        }

        container.appear(1000, 10);

        series.events.on("datavalidated", function () {
          selectSlice(series.slices.getIndex(0));
        });
        //exporting data
        var exporting = am5plugins_exporting.Exporting.new(root, {
          dataSource: vm.pie_familles,
          menu: am5plugins_exporting.ExportingMenu.new(root, {})
        });
      }); // end am5.ready()
    })
  }


  vm.drawPie2 = () => {
    $('.pie_am5_02').empty();
    $('.pie_am5_02').append(`<div id="pie_achat_gamme_famille_sousfamille" style="width: 100%;height: 500px;"></div>`);

    _.each(vm.lists_prods, element => {

      //GAMME
      if (!_.isObject(vm.pie_gammes[element.gamme_code])) {
        vm.pie_gammes[element.gamme_code] = {};
      }
      vm.pie_gammes[element.gamme_code].category_code = element.gamme_code;
      vm.pie_gammes[element.gamme_code].category = element.gamme;
      //Quantite
      // vm.pie_gammes[element.gamme_code].value =
      //     vm.pie_gammes[element.gamme_code].value ? vm.pie_gammes[element.gamme_code].value + Number(element.quantite) : Number(element.quantite);

      // //Chiffre
      // vm.pie_gammes[element.gamme_code].chiffre =
      //     vm.pie_gammes[element.gamme_code].chiffre ? vm.pie_gammes[element.gamme_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      //Famille
      if (!_.isObject(vm.pie_gammes[element.gamme_code].children)) {
        vm.pie_gammes[element.gamme_code].children = {};
      }
      if (!_.isObject(vm.pie_gammes[element.gamme_code].children[element.famille_code])) {
        vm.pie_gammes[element.gamme_code].children[element.famille_code] = {};
      }
      vm.pie_gammes[element.gamme_code].children[element.famille_code].category_code = element.famille_code;
      vm.pie_gammes[element.gamme_code].children[element.famille_code].category = element.famille;
      //Quantite
      // vm.pie_gammes[element.gamme_code].children[element.famille_code].value =
      //     vm.pie_gammes[element.gamme_code].children[element.famille_code].value ? vm.pie_gammes[element.gamme_code].children[element.famille_code].value + Number(element.quantite) : Number(element.quantite);

      // //Chiffre
      // vm.pie_gammes[element.gamme_code].children[element.famille_code].chiffre =
      //     vm.pie_gammes[element.gamme_code].children[element.famille_code].chiffre ? vm.pie_gammes[element.gamme_code].children[element.famille_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      //Sous familles
      if (!_.isObject(vm.pie_gammes[element.gamme_code].children[element.famille_code])) {
        vm.pie_gammes[element.gamme_code].children[element.famille_code] = {};
      }

      if (!_.isObject(vm.pie_gammes[element.gamme_code].children[element.famille_code].children)) {
        vm.pie_gammes[element.gamme_code].children[element.famille_code].children = {};
      }

      if (!_.isObject(vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code])) {
        vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code] = {};
      }

      vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].category_code = element.sousfamille_code;
      vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].category = element.sousfamille;

      vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].chiffre =
        vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].chiffre ? vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].chiffre + Number(element.chiffre) : Number(element.chiffre);

      vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].value =
        vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].value ? vm.pie_gammes[element.gamme_code].children[element.famille_code].children[element.sousfamille_code].value + Number(element.quantite) : Number(element.quantite);
    });


    vm.pie_gammes = Object.values(vm.pie_gammes);
    _.each(vm.pie_gammes, (element, index) => {
      vm.pie_gammes[index].children = Object.values(element.children);
      _.each(vm.pie_gammes[index].children, (sf, indsf) => {
        vm.pie_gammes[index].children[indsf].children = Object.values(sf.children)
      })
    })
    console.log('vm.group_gammes', vm.pie_gammes)
    $(document).ready(function () {
      am5.ready(function () {

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new("pie_achat_gamme_famille_sousfamille");


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
          am5themes_Animated.new(root)
        ]);


        // Create wrapper container
        var container = root.container.children.push(am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout
        }));


        // Create series
        // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
        var series = container.children.push(am5hierarchy.Sunburst.new(root, {
          singleBranchOnly: true,
          downDepth: 10,
          initialDepth: 10,
          topDepth: 1,
          innerRadius: am5.percent(30),
          valueField: "chiffre",
          categoryField: "category",
          childDataField: "children"
        }));

        series.data.setAll([{
          name: "root",
          children: vm.pie_gammes
        }]);

        series.selectDataItem(series.dataItems[0]);

        // Make stuff animate on load
        series.appear(1000, 100);
        //exporting data
        var exporting = am5plugins_exporting.Exporting.new(root, {
          dataSource: vm.pie_gammes,
          menu: am5plugins_exporting.ExportingMenu.new(root, {})
        });
      }); // end am5.ready()
    })
  }



  /**
   * rapport 51
   */
  vm.changeType = () => {
    vm.bldate.gamme_code = '';
    vm.bldate.famille_code = '';
    vm.bldate.sousfamille_code = '';
    vm.bldate.marque_code = '';
    vm.bldate.produit_code = '';
  };

  vm.exportHistoriqueExcel = function (bldate, withavoir = null, custom = false) {

    bldate.client_code = vm.search44.client_code ? vm.search44.client_code : null;
    if (bldate == undefined || bldate == {}) {
      swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir les deux champs date'), 'error');
      return;
    } else if (bldate.date_debut == undefined) {
      swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date debut'), 'error');
      return;
    } else if (bldate.date_fin == undefined) {
      swal($translate.instant('Error'), $translate.instant('Veuillez bien remplir le champ date fin'), 'error');
      return;
    } else {
      var url = 'rapports/Rapport51';

      WS.getData(url, bldate)
        .then(function (response) {

          vm.res_vente = response.data.vente;
          vm.clients = response.data.clients;
          vm.categories = response.data.categorieUnique;

          $scope.$apply();
        })
        .then(null, function (error) {
          console.log(error);
        });
    }
  };



  vm.Rapport_52 = function (bldate) {
    vm.loading_request = true;
    const data = {
      date_debut: bldate.date_debut,
      date_fin: bldate.date_fin,
      commercial_code: bldate.commercial_code
    }
    WS.getData('rapports/Rapport_52', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.bls_merbiss = response.data;
          vm.loading_request = false;

          $scope.$apply();
        } else { }

      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });
  };






  vm.Rapport_47_2 = () => {
    vm.loading_request = true;

    const data = {
      date_debut: vm.date_debut,
      date_fin: vm.date_fin,
      commercial_code: vm.commercial_code,
      gamme_code: vm.gamme_code,
      famille_code: vm.famille_code,
      sousfamille_code: vm.sousfamille_code,
      marque_code: vm.marque_code,
      produit_code: vm.produit_code,
      type_ligne: vm.type_ligne
    }
    vm.valeur_vente = vm.valeur_vente ? vm.valeur_vente : 'quantite';

    WS.getData('rapports/Rapport_47_2', data)
      .then(function (response) {

        vm.rs_mouvements = response.data.mouvements;
        vm.rs_dates = response.data.dates;
        vm.rs_lignes = response.data.lignes;

        vm.rs_mouvements_by_date = response.data.mouvements_by_date;
        vm.rs_mouvements_by_code = response.data.mouvements_by_code;
        vm.rs_mouvements_total = response.data.mouvements_total;

        /*
        * first line th
        */
        var ths_first = `<th class="text-center brd-left brd-top" colspan="2">Date</th>
                <th class="text-center brd-left brd-top use_value" colspan="3">Total</th>`;
        _.each(vm.rs_dates, date => {

          ths_first += `<th class="text-center brd-left brd-right brd-top" colspan="3">${date}</th>`
        })
        vm.ths_first = vm.trustHtml(ths_first);

        /*
         * second line th
         */
        var ths_second = `<th class="text-center brd-left">Code</th><th class="text-center">Nom</th>
                <th class="text-center brd-left use_value">Vente Réelle</th>
                <th class="text-center use_value">Vente Nette</th>
                <th class="text-center use_value brd-right">Taux DLC</th>`;
        _.each(vm.rs_dates, date => {

          ths_second += `
                    <th class="text-center brd-left ">Vente Réelle</th>
                    <th class="text-center">Vente Nette</th>
                    <th class="text-center brd-right">Taux DLC</th>
                    `
        })
        vm.ths_second = vm.trustHtml(ths_second);


        /*
         * third line th for total
         */
        // calculate total_of all
        var vente_reel = 0;
        var vente_nette = 0;
        var vente_nette_qte = 0;
        var taux_dlc = 0;

        var sum_chargement = 0;
        var sum_retour = 0;
        var sum_dlc = 0;
        var sum_casse = 0;
        var sum_gratuite = 0;

        var sum_chargement_qte = 0;
        var sum_retour_qte = 0;
        var sum_dlc_qte = 0;
        var sum_casse_qte = 0;
        var sum_gratuite_qte = 0;
        sum_chargement = vm.rs_mouvements_total[`sum_${vm.valeur_vente}_chargement`];
        sum_retour = vm.rs_mouvements_total[`sum_${vm.valeur_vente}_retour`];
        sum_dlc = vm.rs_mouvements_total[`sum_${vm.valeur_vente}_dlc`];
        sum_casse = vm.rs_mouvements_total[`sum_${vm.valeur_vente}_casse`];
        sum_gratuite = vm.rs_mouvements_total[`sum_${vm.valeur_vente}_gratuite`];

        //quantite
        sum_chargement_qte = vm.rs_mouvements_total[`sum_chiffre_total_chargement`];
        sum_retour_qte = vm.rs_mouvements_total[`sum_chiffre_total_retour`];
        sum_dlc_qte = vm.rs_mouvements_total[`sum_chiffre_total_dlc`];
        sum_casse_qte = vm.rs_mouvements_total[`sum_chiffre_total_casse`];
        sum_gratuite_qte = vm.rs_mouvements_total[`sum_chiffre_total_gratuite`];

        vente_reel = sum_chargement - sum_retour;

        vente_nette = sum_chargement - sum_retour - sum_dlc - sum_casse - sum_gratuite;

        vente_nette_qte = sum_chargement_qte - sum_retour_qte - sum_dlc_qte - sum_casse_qte - sum_gratuite_qte;

        taux_dlc = vente_nette_qte != 0 ? sum_dlc_qte * 100 / vente_nette_qte : 0;

        taux_dlc = taux_dlc.toFixed(2);

        if (vm.valeur_vente == 'chiffre' || vm.valeur_vente == 'poids') {
          vente_reel = vente_reel.toFixed(3)
          vente_nette = vente_nette.toFixed(3)
        }

        var ths_third = `<th class="text-center brd-left use_value" colspan="2"><b>Total</b></th>
                <th class="text-center brd-left use_value "
                                            title="
                                            Chargement: ${sum_chargement} \n
                                            - Retour: ${sum_retour} \n
                                            - Dlc: ${sum_dlc} \n
                                            - Casse: ${sum_casse} \n
                                            - Gratuite: ${sum_gratuite} \n
                                            "
                                            >${vente_reel}</th>
                <th class="text-center use_value"
                                            title="
                                            Chargement: ${sum_chargement} \n
                                            - Retour: ${sum_retour} \n
                                            - Dlc: ${sum_dlc} \n
                                            - Casse: ${sum_casse} \n
                                            - Gratuite: ${sum_gratuite} \n
                                            "
                                            > ${vente_nette} </th>
                    <th class="text-center use_value brd-right"
                                        title="
                                            Chiffre Total DLC * 100 / Chiffre Vente Nette (PLUF+LAIT) : \n
                                                ${sum_dlc_qte} * 100 / ${vente_nette_qte} = ${(sum_dlc_qte * 100 / vente_nette_qte).toFixed(2)} %
                                                "
                                        >${taux_dlc} %</th>`;
        _.each(vm.rs_dates, date => {

          /*
           * CALCULATE TOTAL BY DATE
           */
          vente_reel = 0;
          vente_nette = 0;
          vente_nette_qte = 0;
          taux_dlc = 0;

          sum_chargement = 0;
          sum_retour = 0;
          sum_dlc = 0;
          sum_casse = 0;
          sum_gratuite = 0;

          sum_chargement_qte = 0;
          sum_retour_qte = 0;
          sum_dlc_qte = 0;
          sum_casse_qte = 0;
          sum_gratuite_qte = 0;

          if (vm.rs_mouvements_by_date[date]) {

            sum_chargement = vm.rs_mouvements_by_date[date][`sum_${vm.valeur_vente}_chargement`];
            sum_retour = vm.rs_mouvements_by_date[date][`sum_${vm.valeur_vente}_retour`];
            sum_dlc = vm.rs_mouvements_by_date[date][`sum_${vm.valeur_vente}_dlc`];
            sum_casse = vm.rs_mouvements_by_date[date][`sum_${vm.valeur_vente}_casse`];
            sum_gratuite = vm.rs_mouvements_by_date[date][`sum_${vm.valeur_vente}_gratuite`];

            //quantite
            sum_chargement_qte = vm.rs_mouvements_by_date[date][`sum_chiffre_total_chargement`];
            sum_retour_qte = vm.rs_mouvements_by_date[date][`sum_chiffre_total_retour`];
            sum_dlc_qte = vm.rs_mouvements_by_date[date][`sum_chiffre_total_dlc`];
            sum_casse_qte = vm.rs_mouvements_by_date[date][`sum_chiffre_total_casse`];
            sum_gratuite_qte = vm.rs_mouvements_by_date[date][`sum_chiffre_total_gratuite`];

            vente_reel = sum_chargement - sum_retour;

            vente_nette = sum_chargement - sum_retour - sum_dlc - sum_casse - sum_gratuite;

            vente_nette_qte = sum_chargement_qte - sum_retour_qte - sum_dlc_qte - sum_casse_qte - sum_gratuite_qte;

            taux_dlc = vente_nette_qte != 0 ? sum_dlc_qte * 100 / vente_nette_qte : 0;

            taux_dlc = taux_dlc.toFixed(2);

            if (vm.valeur_vente == 'chiffre' || vm.valeur_vente == 'poids') {
              vente_reel = vente_reel.toFixed(3)
              vente_nette = vente_nette.toFixed(3)
            }

          }
          ths_third += `
                    <th class="text-center brd-left use_value "
                                            title="
                                            Chargement: ${sum_chargement} \n
                                            - Retour: ${sum_retour} \n
                                            - Dlc: ${sum_dlc} \n
                                            - Casse: ${sum_casse} \n
                                            - Gratuite: ${sum_gratuite} \n
                                            "
                                            >${vente_reel}</th>
                <th class="text-center use_value"
                                            title="
                                            Chargement: ${sum_chargement} \n
                                            - Retour: ${sum_retour} \n
                                            - Dlc: ${sum_dlc} \n
                                            - Casse: ${sum_casse} \n
                                            - Gratuite: ${sum_gratuite} \n
                                            "
                                            > ${vente_nette} </th>
                    <th class="text-center use_value brd-right"
                                        title="
                                            Chiffre Total DLC * 100 / Chiffre Vente Nette (PLUF+LAIT) : \n
                                                ${sum_dlc_qte} * 100 / ${vente_nette_qte} = ${(sum_dlc_qte * 100 / vente_nette_qte).toFixed(2)} %
                                                "
                                        >${taux_dlc} %</th>`;
        })
        vm.ths_third = vm.trustHtml(ths_third);

        /*
         * tbody trs
         */
        var trs = ``;
        _.each(vm.rs_lignes, ligne => {


          /*
          * calculer  TOTAL OF ALL DAYS BY CODE
          */
          vente_reel = 0;
          vente_nette = 0;
          vente_nette_qte = 0;
          taux_dlc = 0;

          sum_chargement = 0;
          sum_retour = 0;
          sum_dlc = 0;
          sum_casse = 0;
          sum_gratuite = 0;

          sum_chargement_qte = 0;
          sum_retour_qte = 0;
          sum_dlc_qte = 0;
          sum_casse_qte = 0;
          sum_gratuite_qte = 0;

          if (vm.rs_mouvements_by_code[ligne.code]) {

            sum_chargement = vm.rs_mouvements_by_code[ligne.code][`sum_${vm.valeur_vente}_chargement`];
            sum_retour = vm.rs_mouvements_by_code[ligne.code][`sum_${vm.valeur_vente}_retour`];
            sum_dlc = vm.rs_mouvements_by_code[ligne.code][`sum_${vm.valeur_vente}_dlc`];
            sum_casse = vm.rs_mouvements_by_code[ligne.code][`sum_${vm.valeur_vente}_casse`];
            sum_gratuite = vm.rs_mouvements_by_code[ligne.code][`sum_${vm.valeur_vente}_gratuite`];

            //quantite
            sum_chargement_qte = vm.rs_mouvements_by_code[ligne.code][`sum_chiffre_total_chargement`];
            sum_retour_qte = vm.rs_mouvements_by_code[ligne.code][`sum_chiffre_total_retour`];
            sum_dlc_qte = vm.rs_mouvements_by_code[ligne.code][`sum_chiffre_total_dlc`];
            sum_casse_qte = vm.rs_mouvements_by_code[ligne.code][`sum_chiffre_total_casse`];
            sum_gratuite_qte = vm.rs_mouvements_by_code[ligne.code][`sum_chiffre_total_gratuite`];

            vente_reel = sum_chargement - sum_retour;

            vente_nette = sum_chargement - sum_retour - sum_dlc - sum_casse - sum_gratuite;

            vente_nette_qte = sum_chargement_qte - sum_retour_qte - sum_dlc_qte - sum_casse_qte - sum_gratuite_qte;

            taux_dlc = vente_nette_qte != 0 ? sum_dlc_qte * 100 / vente_nette_qte : 0;

            taux_dlc = taux_dlc.toFixed(2);

            if (vm.valeur_vente == 'chiffre' || vm.valeur_vente == 'poids') {
              vente_reel = vente_reel.toFixed(3)
              vente_nette = vente_nette.toFixed(3)
            }

          }

          trs += `<tr>`
          trs += `
                    <td class="text-center brd-left">${ligne.code}</td>
                    <td class="text-center">${ligne.name}</td>
                    <td class="text-center brd-left use_value "
                                            title="
                                            Chargement: ${sum_chargement} \n
                                            - Retour: ${sum_retour} \n
                                            - Dlc: ${sum_dlc} \n
                                            - Casse: ${sum_casse} \n
                                            - Gratuite: ${sum_gratuite} \n
                                            "
                                            >${vente_reel}</td>
                <td class="text-center use_value"
                                            title="
                                            Chargement: ${sum_chargement} \n
                                            - Retour: ${sum_retour} \n
                                            - Dlc: ${sum_dlc} \n
                                            - Casse: ${sum_casse} \n
                                            - Gratuite: ${sum_gratuite} \n
                                            "
                                            > ${vente_nette} </td>
                    <td class="text-center use_value brd-right"
                                        title="
                                            Chiffre Total DLC * 100 / Chiffre Vente Nette (PLUF+LAIT) : \n
                                                ${sum_dlc_qte} * 100 / ${vente_nette_qte} = ${(sum_dlc_qte * 100 / vente_nette_qte).toFixed(2)} %
                                                "
                                        >${taux_dlc} %</td>`;

          _.each(vm.rs_dates, date => {

            vente_reel = 0;
            vente_nette = 0;
            vente_nette_qte = 0;
            taux_dlc = 0;

            sum_chargement = 0;
            sum_retour = 0;
            sum_dlc = 0;
            sum_casse = 0;
            sum_gratuite = 0;

            sum_chargement_qte = 0;
            sum_retour_qte = 0;
            sum_dlc_qte = 0;
            sum_casse_qte = 0;
            sum_gratuite_qte = 0;

            if (vm.rs_mouvements[date + '-' + ligne.code]) {

              sum_chargement = vm.rs_mouvements[date + '-' + ligne.code][`sum_${vm.valeur_vente}_chargement`];
              sum_retour = vm.rs_mouvements[date + '-' + ligne.code][`sum_${vm.valeur_vente}_retour`];
              sum_dlc = vm.rs_mouvements[date + '-' + ligne.code][`sum_${vm.valeur_vente}_dlc`];
              sum_casse = vm.rs_mouvements[date + '-' + ligne.code][`sum_${vm.valeur_vente}_casse`];
              sum_gratuite = vm.rs_mouvements[date + '-' + ligne.code][`sum_${vm.valeur_vente}_gratuite`];

              //quantite
              sum_chargement_qte = vm.rs_mouvements[date + '-' + ligne.code][`sum_chiffre_total_chargement`];
              sum_retour_qte = vm.rs_mouvements[date + '-' + ligne.code][`sum_chiffre_total_retour`];
              sum_dlc_qte = vm.rs_mouvements[date + '-' + ligne.code][`sum_chiffre_total_dlc`];
              sum_casse_qte = vm.rs_mouvements[date + '-' + ligne.code][`sum_chiffre_total_casse`];
              sum_gratuite_qte = vm.rs_mouvements[date + '-' + ligne.code][`sum_chiffre_total_gratuite`];

              vente_reel = sum_chargement - sum_retour;

              vente_nette = sum_chargement - sum_retour - sum_dlc - sum_casse - sum_gratuite;

              vente_nette_qte = sum_chargement_qte - sum_retour_qte - sum_dlc_qte - sum_casse_qte - sum_gratuite_qte;

              taux_dlc = vente_nette_qte != 0 ? sum_dlc_qte * 100 / vente_nette_qte : 0;

              taux_dlc = taux_dlc.toFixed(2);

              if (vm.valeur_vente == 'chiffre' || vm.valeur_vente == 'poids') {
                vente_reel = vente_reel.toFixed(3)
                vente_nette = vente_nette.toFixed(3)
              }

            }

            trs += `
                                <td class="text-center brd-left"
                                    title="
                                    Chargement: ${sum_chargement} \n
                                    - Retour: ${sum_retour} \n
                                    ">${vente_reel}</td>

                                <td class="text-center"
                                    title="
                                        Chargement: ${sum_chargement} \n
                                        - Retour: ${sum_retour} \n
                                        - Dlc: ${sum_dlc} \n
                                        - Casse: ${sum_casse} \n
                                        - Gratuite: ${sum_gratuite} \n
                                        "
                                    >${vente_nette}</td>

                                <td class="text-center brd-right "
                                        title="
                                       Chiffre Total DLC * 100 / Chiffre Vente Nette (PLUF+LAIT) : \n
                                        ${sum_dlc_qte} * 100 / ${vente_nette_qte} = ${(sum_dlc_qte * 100 / vente_nette_qte).toFixed(2)} %
                                        "
                                        >${taux_dlc} %</td>
                            `;
          })
          trs += `</tr>`
        })
        vm.trs = vm.trustHtml(trs);

        vm.loading_request = false;

        $scope.$apply();
      })
      .then(null, function (error) {
        vm.loading_request = false;
        console.log(error);
        $scope.$apply();

      });
  };

  vm.exportTableHtmlR47_2 = function (tableId, title = '') {



    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;
    title += vm.commercial_code + '-' + vm.valeur_vente + '-' + vm.type_ligne + '__' + vm.date_debut + '__' + vm.date_fin;

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);


  };


  vm.exportTableHtmlR53 = function (tableId, title = '', bldate = null) {



    var uri = 'data:application/vnd.ms-excel;base64,',
      template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
      format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

    var table = $(tableId),
      ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
      href = uri + base64(format(template, ctx));

    //
    var download_link = document.createElement('a');
    download_link.href = href;
    if (bldate.date) {
      title += bldate.date + '-' + bldate.categorie + '__' + bldate.categorie_codes;
    } else {
      title += bldate.mois ? bldate.mois + '-' : '';
      title += bldate.date_debut ? bldate.date_debut + '__' : '';
      title += bldate.date_fin ? bldate.date_fin : '';
      title += bldate.categorie_codes ? bldate.categorie_codes : '';


    }

    download_link.download = title + ".xls";

    document.body.appendChild(download_link);
    download_link.click();
    document.body.removeChild(download_link);


  };

  vm.initMonth = () => {
    $(".datepicker-dashb").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.month = date;
        $scope.$apply();
        //console.log(vm.month);
      }
    });
  }

  vm.initMonth3 = () => {
    $(".datepicker-dashb-3").datepicker({
      autoClose: true,
      onSelect: function (date) {
        vm.month3 = date;
        $scope.$apply();
        //console.log(vm.month);
      }
    });
  }
  vm.rapport_53 = function (bldate) {
    vm.loading_request = true;
    const data = {
      date: bldate.date,
      categorie: bldate.categorie,
      categorie_codes: bldate.categorie_codes
    }
    console.log(data)
    WS.post('rapports/rapport_53', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.rs_raport_53 = response.data;
          vm.pointages = vm.rs_raport_53.pointages;
          vm.entetes = vm.rs_raport_53.entetes;
          vm.clients = vm.rs_raport_53.clients;
          vm.clients_visites = vm.rs_raport_53.clients_visites;
          vm.paiements = vm.rs_raport_53.paiements;
          vm.vente_familles = vm.rs_raport_53.vente_familles;
          vm.vente_familles_commercials = vm.rs_raport_53.vente_familles_commercials;

          vm.ths = `
                    <th class="text-center">${$translate.instant('Code')}</th>
                    <th class="text-center">${$translate.instant('Commercial')}</th>
                    <th class="text-center">${$translate.instant('Début')}</th>
                    <th class="text-center">${$translate.instant('Fin')}</th>
                    <th class="text-center">${$translate.instant('Heures travaillées')}</th>
                    <th class="text-center">${$translate.instant('Nombre de visites')}</th>
                    <th class="text-center">${$translate.instant('Nombre de bls')}</th>
                    <th class="text-center">${$translate.instant('Nombre de commandes')}</th>
                    <th class="text-center">${$translate.instant('Nouveau clients')}</th>
                    <th class="text-center">${$translate.instant('Nouveau clients Actif')}</th>
                    <th class="text-center">${$translate.instant('Recette')}</th>
                    <th class="text-center">${$translate.instant('Recouvrement')}</th>
                    <th class="text-center">${$translate.instant('Ventes')}</th>
                    <th class="text-center">${$translate.instant('Commandes')}</th>
                    `;

          if (vm.vente_familles) {
            _.each(vm.vente_familles, (categorie, index) => {
              vm.ths += `<th class="text-center"> ${$translate.instant('Carton')} ${$translate.instant('Vente')} ${categorie.libelle}</th>`
            })
          }

          vm.ths = vm.trustHtml(vm.ths);

          vm.trs = ``;
          _.each(vm.selectusers, (user, indexUser) => {
            var color_visite = 'info-c-black';
            if (vm.pointages[user.code]) {
              if (vm.pointages[user.code].nbr_visite == 0) {
                color_visite = 'info-c-red';
              } else if (vm.pointages[user.code].nbr_visite > 0 && vm.pointages[user.code].nbr_visite <= 20) {
                color_visite = 'info-c-orange';
              } else if (vm.pointages[user.code].nbr_visite > 20) {
                color_visite = 'info-c-black';
              }
            }
            vm.trs += `
                        <tr>
                        <td class="text-center">${user.code}</td>
                        <td class="text-center">${user.user}</td>
                        <td class="text-center">${vm.pointages[user.code] ? vm.pointages[user.code].heure_debut : ''}</td>
                        <td class="text-center">${vm.pointages[user.code] ? vm.pointages[user.code].heure_fin : ''}</td>
                        <td class="text-center">${vm.pointages[user.code] ? vm.pointages[user.code].nbr_heures : ''}</td>


                        <td class="text-center ${color_visite}">${vm.pointages[user.code] ? vm.pointages[user.code].nbr_visite : ''}</td>


                        <td class="text-center">${vm.entetes[user.code] ? vm.entetes[user.code].nbr_bl : ''}</td>
                        <td class="text-center">${vm.entetes[user.code] ? vm.entetes[user.code].nbr_commande : ''}</td>
                        <td class="text-center">${vm.clients[user.code] ? vm.clients[user.code].nbr_client : ''}</td>
                        <td class="text-center">${vm.clients_visites[user.code] ? vm.clients_visites[user.code].nbr_client : ''}</td>
                        <td class="text-center"
                         title="
                         ${$translate.instant('espece')} : ${vm.paiements[user.code] ? vm.paiements[user.code].espece : ''}
                         ${$translate.instant('cheque')} : ${vm.paiements[user.code] ? vm.paiements[user.code].cheque : ''}
                         ${$translate.instant('traite')} : ${vm.paiements[user.code] ? vm.paiements[user.code].traite : ''}
                         ${$translate.instant('virement')} : ${vm.paiements[user.code] ? vm.paiements[user.code].virement : ''}
                         ${$translate.instant('tickets')} : ${vm.paiements[user.code] ? vm.paiements[user.code].tickets : ''}
                         ">
                         ${vm.paiements[user.code] ? vm.paiements[user.code].recette : ''}
                        </td>
                        <td class="text-center">${vm.paiements[user.code] ? vm.paiements[user.code].recouvrement : ''}</td>
                        <td class="text-center">${vm.entetes[user.code] ? vm.entetes[user.code].chiffre_bl : ''}</td>
                        <td class="text-center">${vm.entetes[user.code] ? vm.entetes[user.code].chiffre_commande : ''}</td>`;

            if (vm.vente_familles) {
              _.each(vm.vente_familles, (categorie, index) => {
                vm.trs += `<td class="text-center" style="background: antiquewhite;">
                                            ${vm.vente_familles_commercials[index + '_' + user.code] ? vm.vente_familles_commercials[index + '_' + user.code].chiffre_bl : ''}
                                        </td>`
              })
            }
            vm.trs += '</tr>';
          })

          vm.trs = vm.trustHtml(vm.trs);

          vm.loading_request = false;
          $scope.$apply();
        } else { }

      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });
  };


  vm.total_r2 = {}
  vm.total_r2.chiffre_bl = 0;
  vm.total_r2.recette = 0;
  vm.total_r2.recouvrement = 0;
  vm.total_r2.nbr_work = 0;
  vm.total_r2.chiffre_bl_days = 0;
  vm.total_r2.nbr_visite = 0;
  vm.total_r2.nbr_visite_days = 0;
  vm.total_r2.recette_percent = 0;
  vm.total_r2_famille = {};
  vm.rapport_53_2 = function (bldate) {

    vm.total_r2 = {}
    vm.total_r2.heurs = 0;
    vm.total_r2.chiffre_bl = 0;
    vm.total_r2.recette = 0;
    vm.total_r2.recouvrement = 0;
    vm.total_r2.nbr_work = 0;
    vm.total_r2.chiffre_bl_days = 0;
    vm.total_r2.nbr_visite = 0;
    vm.total_r2.nbr_visite_days = 0;
    vm.total_r2.recette_percent = 0;
    vm.total_r2_famille = {};


    vm.loading_request = true;
    const data = {
      month: vm.month,
      categorie: bldate.categorie,
      categorie_codes: bldate.categorie_codes
    }
    console.log(data)
    WS.post('rapports/rapport_53_2', data)
      .then(function (response) {
        if (response.status == 200) {
          vm.rs_raport_53_2 = response.data;
          vm.entetes = vm.rs_raport_53_2.entetes;
          vm.visites = vm.rs_raport_53_2.visites;
          vm.total_heurs = vm.rs_raport_53_2.total_heurs;

          vm.nbr_days_month = vm.rs_raport_53_2.nbr_days_month;
          vm.paiements = vm.rs_raport_53_2.paiements;
          vm.vente_familles = vm.rs_raport_53_2.vente_familles;
          vm.vente_familles_commercials = vm.rs_raport_53_2.vente_familles_commercials;

          vm.ths_r2 = `
                    <th class="text-center">${$translate.instant('Code')}</th>
                    <th class="text-center">${$translate.instant('Commercial')}</th>
                    <th class="text-center">${$translate.instant('Vente')}</th>
                    <th class="text-center">${$translate.instant('Recette')}</th>
                    <th class="text-center">${$translate.instant('Recette %')}</th>
                    <th class="text-center">${$translate.instant('Recouvrement')}</th>
                    <th class="text-center">${$translate.instant('Nombre de jours')}</th>
                    <th class="text-center">${$translate.instant('Nombre de jours Travaillés')}</th>
                    <th class="text-center">${$translate.instant('Nombre d\'heures Travaillés')}</th>
                    <th class="text-center">${$translate.instant('Moyenne Vente')}</th>
                    <th class="text-center">${$translate.instant('Nombre de Visites')}</th>
                    <th class="text-center">${$translate.instant('Moyenne Visites')}</th>

                    <th class="text-center">${$translate.instant('Objectif Recette %')}</th>
                    <th class="text-center">${$translate.instant('Manque à gagner. Recette.')}</th>
                    `;

          if (vm.vente_familles) {
            _.each(vm.vente_familles, (categorie, index) => {
              vm.ths_r2 += `
                            <th class="text-center"> ${$translate.instant('Objectif')} ${$translate.instant('Vente')} ${categorie.libelle}</th>
                            <th class="text-center"> ${$translate.instant('Vente')} ${categorie.libelle}</th>
                            <th class="text-center"> ${$translate.instant('%')} ${$translate.instant('Vente')} ${categorie.libelle}</th>
                            `
            })
          }

          vm.ths_r2 = vm.trustHtml(vm.ths_r2);

          vm.trs_r2 = ``;
          _.each(vm.selectusers, (user, indexUser) => {

            var recette_percent = 0;
            if (vm.entetes[user.code]) {
              if (vm.entetes[user.code].chiffre_bl) {
                recette_percent = vm.paiements[user.code].recette * 100 / vm.entetes[user.code].chiffre_bl;
                recette_percent = recette_percent.toFixed(2);
              }
            }

            vm.trs_r2 += `
                        <tr>
                        <td class="text-center">${user.code}</td>
                        <td class="text-center">${user.user}</td>
                        <td class="text-center">${vm.entetes[user.code] ? vm.entetes[user.code].chiffre_bl : ''}</td>
                        <td class="text-center"
                        title="
                        ${$translate.instant('espece')} : ${vm.paiements[user.code] ? vm.paiements[user.code].espece : ''}
                        ${$translate.instant('cheque')} : ${vm.paiements[user.code] ? vm.paiements[user.code].cheque : ''}
                        ${$translate.instant('traite')} : ${vm.paiements[user.code] ? vm.paiements[user.code].traite : ''}
                        ${$translate.instant('virement')} : ${vm.paiements[user.code] ? vm.paiements[user.code].virement : ''}
                        ${$translate.instant('tickets')} : ${vm.paiements[user.code] ? vm.paiements[user.code].tickets : ''}
                        ">
                        ${vm.paiements[user.code] ? vm.paiements[user.code].recette : ''}
                       </td>
                       <td class="text-center"> ${recette_percent}% </td>

                        <td class="text-center"> ${vm.paiements[user.code] ? vm.paiements[user.code].recouvrement : ''} </td>

                       <td class="text-center"> ${vm.nbr_days_month} </td>
                        <td class="text-center">${vm.visites[user.code] ? vm.visites[user.code].nbr_work : ''}</td>
                        <td class="text-center">${vm.total_heurs[user.code] ? vm.total_heurs[user.code] : 0}</td>
                       <td class="text-center">${vm.entetes[user.code] ? (vm.entetes[user.code].chiffre_bl / vm.nbr_days_month).toFixed(3) : ''}</td>

                        <td class="text-center">${vm.visites[user.code] ? vm.visites[user.code].nbr_visite : ''}</td>
                        <td class="text-center">${vm.visites[user.code] ? (vm.visites[user.code].nbr_visite / vm.nbr_days_month).toFixed(0) : ''}</td>

                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        `;

            if (vm.vente_familles) {
              _.each(vm.vente_familles, (categorie, index) => {
                vm.trs_r2 += `
                                <td class="text-center" style="background: antiquewhite;"></td>
                                <td class="text-center" style="background: antiquewhite;">
                                    ${vm.vente_familles_commercials[index + '_' + user.code] ? vm.vente_familles_commercials[index + '_' + user.code].chiffre_bl : ''}
                                </td>
                                <td class="text-center" style="background: antiquewhite;"></td>
                                `

                //calcule total par famille :
                if (vm.total_r2_famille[index]) {
                  vm.total_r2_famille[index].chiffre_bl += vm.vente_familles_commercials[index + '_' + user.code] ? vm.vente_familles_commercials[index + '_' + user.code].chiffre_bl : 0
                } else {
                  vm.total_r2_famille[index] = {}
                  vm.total_r2_famille[index].chiffre_bl = vm.vente_familles_commercials[index + '_' + user.code] ? vm.vente_familles_commercials[index + '_' + user.code].chiffre_bl : 0
                }
              })
            }
            vm.trs_r2 += '</tr>';

            vm.total_r2.heurs += vm.total_heurs[user.code] ? vm.total_heurs[user.code] : 0;
            vm.total_r2.chiffre_bl += vm.entetes[user.code] ? vm.entetes[user.code].chiffre_bl : 0;
            vm.total_r2.recette += vm.paiements[user.code] ? vm.paiements[user.code].recette : 0;
            vm.total_r2.recouvrement += vm.paiements[user.code] ? vm.paiements[user.code].recouvrement : 0;
            vm.total_r2.nbr_work += vm.visites[user.code] ? vm.visites[user.code].nbr_work : 0;
            vm.total_r2.chiffre_bl_days += vm.entetes[user.code] ? (vm.entetes[user.code].chiffre_bl / vm.nbr_days_month) : 0;
            vm.total_r2.nbr_visite += vm.visites[user.code] ? vm.visites[user.code].nbr_visite : 0;
            vm.total_r2.nbr_visite_days += vm.visites[user.code] ? (vm.visites[user.code].nbr_visite / vm.nbr_days_month) : 0;

            console.log('nombre de visite de user', user.code, ':', vm.visites[user.code] ? vm.visites[user.code].nbr_visite : 0)
            console.log('totale visites', vm.total_r2.nbr_visite)
          })



          /**
           * total
           */

          vm.total_r2.recette_percent = vm.total_r2.recette * 100 / vm.total_r2.chiffre_bl;
          vm.total_r2.recette_percent = vm.total_r2.recette_percent ? vm.total_r2.recette_percent.toFixed(2) : 0;
          vm.trs_r2 += `
                        <tr>
                        <td class="text-center" colspan="2"><b>${$translate.instant('Total')}</b> </td>
                        <td class="text-center"><b>${vm.total_r2.chiffre_bl.toFixed(3)}</b></td>
                        <td class="text-center"><b>${vm.total_r2.recette.toFixed(3)}</b></td>
                       <td class="text-center"><b> ${vm.total_r2.recette_percent}% </b></td>
                        <td class="text-center"><b> ${vm.total_r2.recouvrement.toFixed(3)} </b></td>
                       <td class="text-center"><b> ${vm.nbr_days_month} </b></td>
                        <td class="text-center"><b>${vm.total_r2.nbr_work}</b></td>
                        <td class="text-center"><b>${vm.total_r2.heurs}</b></td>
                       <td class="text-center"><b>${vm.total_r2.chiffre_bl_days.toFixed(3)}</b></td>

                        <td class="text-center"><b>${vm.total_r2.nbr_visite}</b></td>
                        <td class="text-center"><b>${vm.total_r2.nbr_visite_days.toFixed(0)}</b></td>

                        <td class="text-center"><b></b></td>
                        <td class="text-center"><b></b></td>
                        `;

          if (vm.vente_familles) {
            _.each(vm.vente_familles, (categorie, index) => {
              vm.trs_r2 += `
                                <td class="text-center" style="background: antiquewhite;"></td>
                                <td class="text-center" style="background: antiquewhite;">
                                    <b>${vm.total_r2_famille[index] ? vm.total_r2_famille[index].chiffre_bl.toFixed(3) : 0}</b>
                                </td>
                                <td class="text-center" style="background: antiquewhite;"></td>
                                `
            })
          }
          vm.trs_r2 += '</tr>';

          vm.trs_r2 = vm.trustHtml(vm.trs_r2);

          vm.loading_request = false;
          $scope.$apply();
        } else { }

      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });
  };

  vm.findAllDaysOnMonth = (month) => {
    console.log(month)
  }


  function getFormattedDays(month) {
    const days = [];
    const startDate = moment(month, 'YYYY-MM');
    const endDate = startDate.clone().endOf('month');

    for (let date = startDate.clone(); date.isBefore(endDate) || date.isSame(endDate, 'day'); date.add(1, 'day')) {
      days.push({
        day_name: date.format('D-MMM YYYY').toLowerCase(),
        day: date.format('YYYY-MM-DD').toLowerCase(),
      });
    }

    return days;
  }



  function formatObject_53_3(type, data) {
    //type can be quantite/chiffre/poids/box

    // Convert data object to array for easier processing
    const entries = Object.values(data);

    // Sort entries by date
    entries.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Initialize cumulative totals
    const cumulativeTotals = {};

    // Calculate cumulative totals by date
    entries.forEach(entry => {
      const { date, libelle, quantite, chiffre, box, poids } = entry;

      // Initialize date if not present
      if (!cumulativeTotals[date]) {
        cumulativeTotals[date] = { date };
      }

      // Initialize product if not present
      if (!cumulativeTotals[date][libelle]) {
        cumulativeTotals[date][libelle] = 0;
      }

      // Add current quantity to cumulative total
      cumulativeTotals[date][libelle] += type == 'quantite' ? quantite : 0;
      cumulativeTotals[date][libelle] += type == 'box' ? box : 0;
      cumulativeTotals[date][libelle] += type == 'chiffre' ? chiffre : 0;
      cumulativeTotals[date][libelle] += type == 'poids' ? poids : 0;

      // Update all previous dates' cumulative totals
      Object.keys(cumulativeTotals).forEach(prevDate => {
        if (new Date(prevDate) < new Date(date)) {
          if (!cumulativeTotals[prevDate][libelle]) {
            cumulativeTotals[prevDate][libelle] = 0;
          }
          cumulativeTotals[prevDate][libelle] += type == 'quantite' ? quantite : 0;
          cumulativeTotals[prevDate][libelle] += type == 'box' ? box : 0;
          cumulativeTotals[prevDate][libelle] += type == 'chiffre' ? chiffre : 0;
          cumulativeTotals[prevDate][libelle] += type == 'poids' ? poids : 0;
        }
      });
    });

    // Convert cumulative totals to an array
    const cumulativeTotalsArray = Object.values(cumulativeTotals);

    // Log result
    console.log('cumulativeTotalsArray', cumulativeTotalsArray);
    return cumulativeTotalsArray
  }
  vm.graph_53_1 = (type, my_data, categories) => {
    //params type = quantite/chiffre/box/poids

    var final_data = formatObject_53_3(type, my_data)
    //format_data
    am5.ready(function () {

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      var root = am5.Root.new("chart_rapport_53_1");

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout,
          pinchZoomX: true
        })
      );



      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true
      });
      xRenderer.grid.template.set("location", 0.5);
      xRenderer.labels.template.setAll({
        location: 0.5,
        multiLocation: 0.5
      });

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "date",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
          snapTooltip: true
        })
      );

      xAxis.data.setAll(final_data);

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(root, {
            inversed: true
          })
        })
      );

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        alwaysShow: true,
        xAxis: xAxis,
        positionX: 1
      }));

      cursor.lineY.set("visible", false);
      cursor.lineX.set("focusable", true);

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      function createSeries(name, field) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: "date",
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
            })
          })
        );


        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 5,
              fill: series.get("fill")
            })
          });
        });

        // create hover state for series and for mainContainer, so that when series is hovered,
        // the state would be passed down to the strokes which are in mainContainer.
        series.set("setStateOnChildren", true);
        series.states.create("hover", {});

        series.mainContainer.set("setStateOnChildren", true);
        series.mainContainer.states.create("hover", {});

        series.strokes.template.states.create("hover", {
          strokeWidth: 4
        });

        series.data.setAll(final_data);
        series.appear(1000);
      }

      _.each(categories, (element, index) => {
        createSeries(element.libelle, element.libelle);
      })


      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal",
        marginBottom: 20
      }));

      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      // Make series change state when legend item is hovered
      legend.itemContainers.template.states.create("hover", {});

      legend.itemContainers.template.events.on("pointerover", function (e) {
        e.target.dataItem.dataContext.hover();
      });
      legend.itemContainers.template.events.on("pointerout", function (e) {
        e.target.dataItem.dataContext.unhover();
      });

      legend.data.setAll(chart.series.values);

      chart.plotContainer.events.on("pointerout", function () {
        cursor.set("positionX", 1)
      })

      chart.plotContainer.events.on("pointerover", function () {
        cursor.set("positionX", undefined)
      })

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);

    }); // end am5.ready()
  }
  vm.rapport_53_3 = function (bldate) {
    vm.loading_request = true;

    // start get all days :
    vm.formattedDays = getFormattedDays(vm.month3);

    console.log("vm.formattedDays", vm.formattedDays)

    const data = {
      month: vm.month3,
      categorie: bldate.categorie,
      type: bldate.type
    }

    //



    WS.post('rapports/rapport_53_3', data)
      .then(function (response) {
        vm.list_categories = response.data.categories;
        vm.list_values = response.data.entetes;
        vm.loading_request = false;
        vm.graph_53_1(bldate.type, vm.list_values, vm.list_categories)
        $scope.$apply();

      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });


    WS.post('rapports/rapport_53_3_1', data)
      .then(function (response) {
        vm.list_categories_c = response.data.categories;
        vm.list_values_c = response.data.entetes;
        vm.loading_request = false;

        $scope.$apply();

      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });
  };

  $scope.openItinerary = function (latitude, longitude) {
    if (latitude && longitude) {
      // Construct the Google Maps URL
      var mapsUrl = 'https://www.google.com/maps/dir/?api=1&destination=' + latitude + ',' + longitude;
      // Open the URL in a new tab
      $window.open(mapsUrl, '_blank');
    } else {
      alert("Les coordonnées ne sont pas disponibles.");
    }
  };


  vm.Rapport54 = () => {
    var url = 'rapports/Rapport54';
    WS.getData(url, vm.search54)
      .then(function (response) {
        if (response.status == 200) {
          vm.clients = response.data.clients;
          vm.ventes = response.data.ventes;
          vm.lastbls = response.data.lastbls;
          vm.clients_out_gps = response.data.clients_out_gps;


          /**
        * maps openstreetmaps
        */
          $('#maps_rapport_54').empty();
          $('#maps_rapport_54').append('<div style="width: 100%; height: 560px;" id="maps_rapport_54_panel"></div>');

          var mymap = new L.Map('maps_rapport_54_panel', {
            center: new L.LatLng(vm.clients[0].latitude, vm.clients[0].longitude),
            zoom: 8,
            fullscreenControl: true,
            fullscreenControlOptions: {
              position: 'topright'
            }
          });
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
          }).addTo(mymap);


          /**
           * Clients
           */

          _.each(vm.clients, (element, index) => {
            // position visite
            var MarkerClientVisiteIcon = L.ExtraMarkers.icon({
              icon: '',
              markerColor: 'red',
              shape: 'penta', //'circle', 'square', 'star', or 'penta'
              prefix: 'fa',
              innerHTML: `<span style="color: white;font-size: 27px;" >C</span>`
            });
            //console.log(['Client', element.code, element.latitude, element.longitude])

            var positionsVentes = L.marker([element.latitude, element.longitude], { icon: MarkerClientVisiteIcon }).addTo(mymap);
            // positionsVentes.bindTooltip(element.code, { permanent: true, direction: 'top' });

            var infoClientVisite = $compile(`
                                                    <div id="bodyContent" style="width=400px;">
                                                        <div class="text-center">
                                                            <a ui-sref="app.clients.historiques({code : '${element.code}'})" target="_blank">
                                                            Historique
                                                            </a>
                                                        </div>
                                                        <div><b>Code</b> : ${element.code}</div>
                                                        <div><b>Client</b> : ${element.name}</div>
                                                        <div><b>Magasin</b> : ${element.magasin}</div>
                                                        <div><b>Gouvernorat</b> : ${element.gouvernorat}</div>
                                                        <div><b>Delegation</b> : ${element.delegation}</div>
                                                        <div><b>Derniere visite</b> : ${element.last_visite}</div>
                                                        <div><b>Dernier Bl</b> : ${element.last_bl}</div>
                                                        <div class="text-center">
                                                                <button ng-click="openItinerary(${element.latitude}, ${element.longitude})">
                                                                    Itinéraire
                                                                </button>
                                                        </div>
                                                    </div>
                                                    `)($scope);
            var popupClientVisite = L.popup({
              maxWidth: 600,
              minWidth: 300
            }).setContent(infoClientVisite[0])
            positionsVentes.bindPopup(popupClientVisite);
          });

          /**
           * Ventes
           */
          _.each(vm.ventes, (element, index) => {
            // position visite
            var MarkerClientVisiteIcon = L.ExtraMarkers.icon({
              icon: '',
              markerColor: 'blue',
              shape: 'square', //'circle', 'square', 'star', or 'penta'
              prefix: 'fa',
              innerHTML: `<span style="color: white;font-size: 27px;" ></span>`
            });


            console.log(['visite', element.code, vm.lastbls[element.code].latitude, vm.lastbls[element.code].longitude])
            if (
              vm.lastbls[element.code]
            ) {
              var positionsVentes = L.marker([vm.lastbls[element.code].latitude, vm.lastbls[element.code].longitude], { icon: MarkerClientVisiteIcon }).addTo(mymap);
              // positionsVentes.bindTooltip(element.code, { permanent: true, direction: 'top' });

              var infoClientVisite = $compile(`
                                                            <div id="bodyContent" style="width=400px;">
                                                                <div class="text-center">
                                                                <a ui-sref="app.clients.historiques({code : '${element.code}'})" target="_blank">
                                                                    Historique
                                                                </a>
                                                                </div>
                                                                <div><b>Code</b> : ${element.code}</div>
                                                                <div><b>Client</b> : ${element.name}</div>
                                                                <div><b>Magasin</b> : ${element.magasin}</div>

                                                                <div><b>Date</b> : ${vm.lastbls[element.code] ? vm.lastbls[element.code].date : ''}</div>
                                                                <div><b>Entete</b> : ${vm.lastbls[element.code] ? vm.lastbls[element.code].entetecommercial_code : ''}</div>
                                                                <div><b>Quantite</b> : ${vm.lastbls[element.code] ? vm.lastbls[element.code].bl_quantite : ''}</div>
                                                                <div><b>Poids</b> : ${vm.lastbls[element.code] ? vm.lastbls[element.code].bl_poids : ''}</div>
                                                                <div><b>Chiffre</b> : ${vm.lastbls[element.code] ? vm.lastbls[element.code].bl_chiffre : ''}</div>

                                                                <div><b>Total Chiffre</b> : ${element.chiffre}</div>
                                                                <div><b>Total Quantite</b> : ${element.quantite}</div>
                                                        </div>
                                                        `)($scope);
              var popupClientVisite = L.popup({
                maxWidth: 600,
                minWidth: 300
              }).setContent(infoClientVisite[0])
              positionsVentes.bindPopup(popupClientVisite);

            }
          });


          setTimeout(function () {
            mymap.invalidateSize();
          }, 80);

          $scope.$apply();
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  }


  vm.ventes_total_1 = [];
  vm.ventes_total_2 = [];
  vm.ventes_total_1_indexed = {};
  vm.ventes_total_2_indexed = {};
  vm.rapport_55_maps = () => {


    console.log('container-Client-55')
    console.log(vm.ventes_total_1)
    console.log(vm.ventes_total_2)

    console.log('container-Client-55')

    var center_lat = 0;
    var center_lng = 0;
    vm.zoomRouting = 12;
    if (vm.ventes_total_2.length > 0) {
      center_lat = vm.ventes_total_2[0].latitude;
      center_lng = vm.ventes_total_2[0].longitude;
    } else if (vm.ventes_total_1.length > 0) {
      center_lat = vm.ventes_total_1[0].latitude;
      center_lng = vm.ventes_total_1[0].longitude;
    }
    vm.selectmapsclients = angular.copy(vm.inactifs);
    vm.centerroutes = angular.copy(center_lat + ', ' + center_lng);
    vm.zoomRouting = 10;
    $('#container-Client-55').empty();
    $('#container-Client-55').html('<div style="width: 100%; height: 600px" id="mapContainerClients-55"></div>');

    //var mymap = L.map('mapContainerClients-55').setView([center_lat, center_lng], vm.zoomRouting);
    var mymap = new L.Map('mapContainerClients-55', {
      center: new L.LatLng(center_lat, center_lng),
      zoom: 10,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: 'topright'
      }
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
      foo: 'bar',
      attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
    })
      .addTo(mymap);


    vm.ventes_total_1.forEach(function (element, index) {
      // Creates a red marker with the coffee icon
      // var redMarker = L.ExtraMarkers.icon({
      //     icon: 'fa-home',
      //     markerColor: 'blue',
      //     shape: 'circle', //'circle', 'square', 'star', or 'penta'
      //     prefix: 'fa'
      // });

      var redMarker = L.divIcon({
        html: '<div style="background-color: red; width: 20px; height: 20px; border-radius: 50%; border: 2px solid black;"></div>',
        className: 'custom-div-icon', // You can define custom styles here if needed
        iconSize: [20, 20] // This defines the size of the custom marker
      });


      var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
      var code_client = element.code.toString();
      var info = $compile(`
                         <div id="bodyContent" style="width=300px;">
                              <p class="text-center">
                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                    Modifier
                                </a>
                                <b> | </b>
                                <a ui-sref="app.clients.historiques({code : ${code_client}})" target="_blank">
                                  Historique
                                </a>
                              </p>
                              <b>Client</b> : ${element.name}</a></br>
                              <b>Magasin</b> : ${element.magasin}</a></br>
                              <b>Code</b> : ${element.code}</a></br>
                              <b>Adresse</b> : ${element.adresse_facturation}</br>
                              <b>Route</b> : ${element.routing_code}</br>
                              <b>Mobile</b> : ${element.mobile}</br>
                              <b>Periode 1</b>
                            </div>
                        `)($scope);
      var popup = L.popup({
        maxWidth: 600,
        minWidth: 200
      }).setContent(info[0])
      marker.bindPopup(popup);

    });


    vm.ventes_total_2.forEach(function (element, index) {
      // Creates a red marker with the coffee icon
      // var redMarker = L.ExtraMarkers.icon({
      //     icon: 'fa-home',
      //     markerColor: 'red',
      //     shape: 'square', //'circle', 'square', 'star', or 'penta'
      //     prefix: 'fa'
      // });

      var redMarker = L.divIcon({
        html: '<div style="background-color: blue; width: 20px; height: 20px; border-radius: 50%; border: 2px solid black;"></div>',
        className: 'custom-div-icon', // You can define custom styles here if needed
        iconSize: [20, 20] // This defines the size of the custom marker
      });

      var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);

      var code_client = element.code.toString();

      var info = $compile(`
                         <div id="bodyContent" style="width=300px;">
                              <p class="text-center">
                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                    Modifier
                                </a>
                                <b> | </b>
                                <a ui-sref="app.clients.historiques({code : ${code_client}})" target="_blank">
                                  Historique
                                </a>
                              </p>
                              <b>Client</b> : ${element.name}</a></br>
                              <b>Magasin</b> : ${element.magasin}</a></br>
                              <b>Code</b> : ${element.code}</a></br>
                              <b>Adresse</b> : ${element.adresse_facturation}</br>
                              <b>Route</b> : ${element.routing_code}</br>
                              <b>Mobile</b> : ${element.mobile}</br>
                              <b>Periode 2</b>
                            </div>
                        `)($scope);
      var popup = L.popup({
        maxWidth: 600,
        minWidth: 200
      }).setContent(info[0])
      marker.bindPopup(popup);

    });
  }

  vm.rapport_55_f_1 = () => {

    vm.loading_request = true;


    WS.post('rapports/Rapport55', vm.search55f1)
      .then(function (response) {
        vm.list_marques_1 = response.data.marques;
        vm.ventes_total_1 = response.data.ventes_total;

        vm.ventes_total_1_indexed = _.indexBy(vm.ventes_total_1, 'code');

        vm.ventes_par_marque_1 = response.data.ventes_par_marque;
        vm.visites_1 = response.data.visites;
        vm.nbr_ventes_1 = response.data.nbr_ventes;

        vm.loading_request = false;


        $scope.$apply();

        vm.rapport_55_maps();

      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });
  }



  vm.rapport_55_f_2 = () => {
    vm.loading_request = true;

    WS.post('rapports/Rapport55', vm.search55f2)
      .then(function (response) {
        vm.list_marques_2 = response.data.marques;
        vm.ventes_total_2 = response.data.ventes_total;
        vm.ventes_total_2_indexed = _.indexBy(vm.ventes_total_2, 'code');


        vm.ventes_par_marque_2 = response.data.ventes_par_marque;
        vm.visites_2 = response.data.visites;
        vm.nbr_ventes_2 = response.data.nbr_ventes;
        vm.loading_request = false;
        $scope.$apply();

        vm.rapport_55_maps();

      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });
  }


  vm.allsocietes = {};
  vm.selectSoussocietes = function () {
    WS.get('soussocietes/selectSoussocietes').then(
      function (response) {
        vm.soussocietes = response.data;
        _.each(vm.soussocietes, element => {
          vm.allsocietes[element.code] = element.nom;
        })

        if (vm.soussocietes.length == 1) {
          vm.soussociete_code = vm.soussocietes[0].code;
          vm.search56.soussociete_codes = [vm.soussocietes[0].code]
        }

        $scope.$apply();
      },
      function (error) {
        console.log(error);
      });
  };

  vm.filterUserBydistributeur = () => {

    if (vm.search56.soussociete_codes.length == 0) {
      vm.selectusersfiltred = vm.selectusers;
    }

    if (vm.search56.soussociete_codes.length > 0) {
      vm.selectusersfiltred = vm.selectusers.filter(obj =>
        vm.search56.soussociete_codes.includes(obj.soussociete_code)
      );;
    }

  }



  vm.Rapport56 = () => {
    vm.loading_request = true;

    var data = {
      soussociete_codes: vm.search56.soussociete_codes,
      date_debut: vm.search56.date_debut,
      date_fin: vm.search56.date_fin,
    }
    WS.post('rapports/Rapport56', data)
      .then(function (response) {
        vm.r56ventes = response.data.ventes;
        vm.r56commandes = response.data.commandes;
        vm.r56credits = response.data.credits;
        vm.r56recouvrements = response.data.recouvrements;
        vm.r56pointages = response.data.pointages;
        vm.r56nvClients = response.data.nvClients;

        /**
         * TOTAL ventes
         */
        vm.total56ventes = {};
        vm.total56ventes.chiffre = 0;
        vm.total56ventes.quantite = 0;
        vm.total56ventes.box = 0;
        vm.total56ventes.poids = 0;
        vm.total56ventes.nbr_bls = 0;
        console.log(vm.total56ventes.nbr_bls)
        _.each(vm.r56ventes, (element, index) => {
          vm.total56ventes.chiffre += element.chiffre;
          vm.total56ventes.quantite += element.quantite;
          vm.total56ventes.box += element.box;
          vm.total56ventes.poids += element.poids;
          vm.total56ventes.nbr_bls += element.nbr_bls;
          console.log('+', element.nbr_bls, '=', vm.total56ventes.nbr_bls)
        })


        /**
         * TOTAL commandes
         */
        vm.total56commandes = {};
        vm.total56commandes.chiffre = 0;
        vm.total56commandes.quantite = 0;
        vm.total56commandes.box = 0;
        vm.total56commandes.poids = 0;
        vm.total56commandes.nbr_cmds = 0;
        _.each(vm.r56commandes, (element, index) => {
          vm.total56commandes.chiffre += element.chiffre;
          vm.total56commandes.quantite += element.quantite;
          vm.total56commandes.box += element.box;
          vm.total56commandes.poids += element.poids;
          vm.total56commandes.nbr_cmds += element.nbr_cmds;
        })

        /**
         * TOTAL credit
         */
        vm.total56credit = {};
        vm.total56credit.solde = 0;
        _.each(vm.r56credit, (element, index) => {
          vm.total56credit.solde += element.solde;

        })

        /**
        * TOTAL recouvrements
        */
        vm.total56recouvrements = {};
        vm.total56recouvrements.solde = 0;
        _.each(vm.r56recouvrements, (element, index) => {
          vm.total56recouvrements.solde += element.solde;

        })
        /**
        * TOTAL visites
        */
        vm.total56visites = {};
        vm.total56visites.nbr_visite = 0;
        _.each(vm.r56pointages, (element, index) => {
          vm.total56visites.nbr_visite += element.nbr_visite;

        })

        /**
        * TOTAL nvclients
        */
        vm.total56nvclients = {};
        vm.total56nvclients.nbrclient = 0;
        _.each(vm.r56nvClients, (element, index) => {
          vm.total56nvclients.nbrclient += element.nbrclient;

        })

        vm.loading_request = false;
        $scope.$apply();


      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });
  }



  vm.Rapport57 = () => {
    vm.loading_request = true;

    var data = {
      type: vm.search57.type,
      user_code: vm.search57.user_code,
      date_debut: vm.search57.date_debut,
      date_fin: vm.search57.date_fin,
    }
    WS.getData('rapports/Rapport57', data)
      .then(function (response) {

        vm.list_clients = response.data.clients;
        vm.list_visites_cls_code = response.data.visites;
        vm.allclients = response.data.allclients;

        vm.nbrVisitesMois = response.data.nbrVisitesMois;
        vm.nbrVisitesMoisKKount = Object.keys(vm.nbrVisitesMois).length;

        vm.first_date_of_month = response.data.first_date_of_month;
        vm.first_value_of_visite = response.data.first_value_of_visite;
        vm.loading_request = false;
        $scope.$apply();


      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });
  }



  /**
   * Rapport 58
   */
  vm.findDataRapport58 = () => {
    vm.loading_request = true;

    WS.getData('rapports/findDataRapport58', vm.filter58)
      .then(function (response) {


        vm.lignes = response.data.lignes;
        vm.colonnes = response.data.colonnes;
        vm.clients = response.data.clients;
        vm.results = response.data.results;
        vm.groupes = Object.keys(vm.colonnes);


        _.each(vm.groupes, group => {

          vm['tbodys_' + group] = ''


          _.each(vm.clients, client => {
            _.each(vm.lignes[group], (ligne) => {
              vm['tbodys_' + group] += `

                  <tr>
                      <td class="text-center">${client.client_code}</td>
                      <td class="text-center">${client.client}</td>
                      <td class="text-center">${client.user_code} ${client.user}</td>
                      <td class="text-center">${ligne.libelle}</td>`;

              _.each(vm.colonnes[group], colonne => {

                var index = client.client_code + '_' + client.user_code + '_' + colonne.code + '_' + ligne.code;

                vm['tbodys_' + group] += `<td class="text-center" ${vm.results[index] ? 'style="background: aquamarine"' : ''}>
                                                  ${vm.results[index] ? vm.results[index].reponse : 0}
                                                </td>`
              })
              vm['tbodys_' + group] += `</tr>`;
            })
          })

          vm['tbodys_' + group] = vm.trustHtml(vm['tbodys_' + group])
        })

        vm.loading_request = false;

        $scope.$apply();


      })
      .then(null, function (error) {
        vm.loading_request = false;

        console.log(error);
        $scope.$apply();
      });
  }
}
export default {
  name: 'rapportController',
  fn: rapportController
};


